'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _clients = require('./clients.pug');

var _clients2 = _interopRequireDefault(_clients);

var _clients3 = require('./clients.controller');

var _clients4 = _interopRequireDefault(_clients3);

require('./clients.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _clients2.default)({}),
    controller: _clients4.default,
    name: 'nlc-clients',
    bindings: {
        controller: '=',
        short: '@',
        onSelect: '&'
    },
    bindToController: true
};