'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.default = function (dateFilter) {
    'ngInject';

    return function (input) {
        var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-d';
        return dateFilter(input, format);
    };
};