'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _nlInputContainer = require('./directives/nl-input-container.directive');

var _nlInputContainer2 = _interopRequireDefault(_nlInputContainer);

var _nlValidationMessages = require('./directives/nl-validation-messages.directive');

var _nlValidationMessages2 = _interopRequireDefault(_nlValidationMessages);

var _nlForm = require('./directives/nl-form.directive');

var _nlForm2 = _interopRequireDefault(_nlForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.modules.form', []).directive('nlInputContainer', /*@ngInject*/function () {
    return new _nlInputContainer2.default();
}).directive('nlValidationMessages', /*@ngInject*/function ($rootScope, $translate) {
    return new _nlValidationMessages2.default($rootScope, $translate);
}).directive('nlForm', /*@ngInject*/function ($q, TranslateToastService, ToastService) {
    return new _nlForm2.default($q, TranslateToastService, ToastService);
});