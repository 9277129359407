'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _nlData = require('../controllers/nl-data.controller');

var _nlData2 = _interopRequireDefault(_nlData);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function _class() {
    _classCallCheck(this, _class);

    Object.assign(this, {
        restrict: 'AE',
        scope: {
            request: '=nlData',
            onRefresh: '&'
        },
        bindToController: true,
        controller: _nlData2.default
    });
};

exports.default = _class;