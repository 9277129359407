'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _orderItem = require('./order-item.pug');

var _orderItem2 = _interopRequireDefault(_orderItem);

var _orderItem3 = require('./order-item.controller');

var _orderItem4 = _interopRequireDefault(_orderItem3);

require('./order-item.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _orderItem2.default)({}),
    controller: _orderItem4.default,
    name: 'nlc-order-items-page'
};