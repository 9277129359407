'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extraDialog = require('./extra.dialog.pug');

var _extraDialog2 = _interopRequireDefault(_extraDialog);

var _extraDialog3 = require('./extra.dialog.controller');

var _extraDialog4 = _interopRequireDefault(_extraDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _extraDialog2.default)({}),
    controller: _extraDialog4.default,
    name: 'nlc-calc-constructor-extra-dialog'
};