'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _source = require('./source.pug');

var _source2 = _interopRequireDefault(_source);

var _source3 = require('./source.controller');

var _source4 = _interopRequireDefault(_source3);

require('./source.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _source2.default)({}),
    controller: _source4.default,
    name: 'nlc-sources-page'
};