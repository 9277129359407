'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _parameters = require('./parameters.pug');

var _parameters2 = _interopRequireDefault(_parameters);

var _parameters3 = require('./parameters.controller');

var _parameters4 = _interopRequireDefault(_parameters3);

require('./parameters.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _parameters2.default)({}),
    controller: _parameters4.default,
    name: 'nlc-parameters',
    bindings: {
        controller: '='
    },
    bindToController: true
};