'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _nextDialog = require('./next.dialog.pug');

var _nextDialog2 = _interopRequireDefault(_nextDialog);

var _nextDialog3 = require('./next.dialog.controller');

var _nextDialog4 = _interopRequireDefault(_nextDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _nextDialog2.default)({}),
    controller: _nextDialog4.default,
    name: 'nlc-status-next-dialog',
    size: 'xs'
};