'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _view = require('./view.pug');

var _view2 = _interopRequireDefault(_view);

var _view3 = require('./view.controller');

var _view4 = _interopRequireDefault(_view3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _view2.default)({}),
    controller: _view4.default,
    name: 'nlc-order-item-view',
    bindings: {
        item: '='
    },
    bindToController: true
};