'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _store = require('../../legal-entities/dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

var _update = require('../../legal-entities/dialog/update/update.dialog');

var _update2 = _interopRequireDefault(_update);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($state, $scope, TranslateToastService, ToastService, OrderService, OrderPolicy, ClientService, ClientLegalEntityService, CacheValueService, CacheStatusService, CacheUserService, TranslateDialogService, CacheDeliveryTypeService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            toast: ToastService,
            ttoast: TranslateToastService,
            dialog: TranslateDialogService,
            CacheDeliveryTypeService: CacheDeliveryTypeService,
            OrderService: OrderService,
            ClientService: ClientService,
            CacheValueService: CacheValueService,
            CacheStatusService: CacheStatusService,
            CacheUserService: CacheUserService,
            ClientLegalEntityService: ClientLegalEntityService,
            policy: OrderPolicy,
            $state: $state,
            $scope: $scope
        });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            this.request = Object.assign({
                transaction: {},
                items: []
            }, this.request || { payment_status: 'unpaid' });
            this.is_bid = this.request.type == 'bid';
            this.request.transaction.paid_at = new Date();
        }
    }, {
        key: 'addItem',
        value: function addItem() {
            var _this = this;

            this.request.items.push({});
            setTimeout(function () {
                return _this.activeTab = _this.request.items.length + 1;
            }, 100);
        }
    }, {
        key: 'removeItem',
        value: function removeItem(item) {
            this.request.items = this.request.items.filter(function (searchItem) {
                return searchItem !== item;
            });
            this.onItemCalc();
            this.activeTab = this.request.items.length != 0 ? this.request.items.length + 1 : 60;
        }
    }, {
        key: 'onItemCalc',
        value: function onItemCalc() {
            if (this.request.items.length == 0) {
                this.request.price = 0;
                // this.request.delivery.price = 0;
            } else {
                this.request.price = this.request.items.map(function (_ref) {
                    var total_price = _ref.total_price;
                    return total_price;
                }).reduce(function (a, b) {
                    return a + b;
                });
            }
            this.request.delivery.items = this.request.items;
            this.orderDeliveryForm.onItemsChange();
        }
    }, {
        key: 'onDeliveryCalc',
        value: function onDeliveryCalc() {
            var itemsPrice = this.request.items.map(function (_ref2) {
                var total_price = _ref2.total_price;
                return total_price;
            }).reduce(function (a, b) {
                return a + b;
            });
            this.request.price = itemsPrice + (this.request.delivery.payer === 'client' ? this.request.delivery.total_price : 0);
        }
    }, {
        key: 'addLegalEntity',
        value: function addLegalEntity(legalEntityCtrl) {
            var _this2 = this;

            return this.dialog.open(_store2.default, { client_id: this.client.id }).then(function (legalEntity) {
                try {
                    _lodash2.default.set(_this2, 'request.invoice.client_legal_entity_id', legalEntity.id);
                    legalEntityCtrl.refresh();
                    Object.assign(_this2.legalEntity, legalEntity);
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }, {
        key: 'editLegalEntity',
        value: function editLegalEntity(legalEntity, legalEntityCtrl) {
            var _this3 = this;

            return this.dialog.open(_update2.default, { client_id: this.client.id, legalEntity: legalEntity }).then(function (legalEntity) {
                try {
                    _lodash2.default.set(_this3, 'request.invoice.client_legal_entity_id', legalEntity.id);
                    legalEntityCtrl.refresh();
                    Object.assign(_this3.legalEntity, legalEntity);
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }, {
        key: 'cancel',
        value: function cancel() {
            return this.$state.go('app.order');
        }
    }, {
        key: 'submit',
        value: function submit() {
            var _this4 = this;

            var createNewAfter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            this.request.type = this.is_bid ? 'bid' : 'order';
            this.promise = (_angular2.default.isDefined(this.request.id) ? this.OrderService.update(this.request.id, this.request) : this.OrderService.store(this.request)).then(function (order) {
                _this4.ttoast.success('COMPONENTS.ORDERS.TOASTS.SUCCESS.TITLE', 'COMPONENTS.ORDERS.TOASTS.SUCCESS.MESSAGE');
                _this4.$state.go(createNewAfter ? 'app.order-create' : 'app.order');
            });
        }
    }, {
        key: 'submitClient',
        value: function submitClient() {
            var _this5 = this;

            this.client.sale_id = this.request.sale_id;
            this.client.manager_id = this.request.manager_id;

            this.clientPromise = (_angular2.default.isDefined(this.client.id) ? this.ClientService.update(this.client.id, this.client) : this.ClientService.store(this.client)).then(function (client) {
                Object.assign(_this5.client, client);
                _this5.request.client_id = client.id;
                _this5.ttoast.success('COMPONENTS.CLIENTS.TOASTS.SUCCESS.TITLE', 'COMPONENTS.CLIENTS.TOASTS.SUCCESS.MESSAGE');
            });
        }
    }]);

    return _class;
}();

exports.default = _class;