'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _blocks = require('./blocks.pug');

var _blocks2 = _interopRequireDefault(_blocks);

var _blocks3 = require('./blocks.controller');

var _blocks4 = _interopRequireDefault(_blocks3);

require('./blocks.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _blocks2.default)({}),
    controller: _blocks4.default,
    name: 'nlc-content-blocks',
    bindings: {
        controller: '='
    },
    bindToController: true
};