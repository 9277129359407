'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _reports = require('./reports.pug');

var _reports2 = _interopRequireDefault(_reports);

var _reports3 = require('./reports.controller');

var _reports4 = _interopRequireDefault(_reports3);

require('./reports.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _reports2.default)({}),
    controller: _reports4.default,
    name: 'nlc-reports',
    bindings: {
        controller: '='
    },
    bindToControler: true
};