'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _store = require('./dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

var _update = require('./dialog/update/update.dialog');

var _update2 = _interopRequireDefault(_update);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class(TranslateDialogService, DataArrayValueService, DataArrayValuePolicy, DataArrayValueChoice, CacheValueService) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, DataArrayValueService));

        Object.assign(_this, { dialog: TranslateDialogService, policy: DataArrayValuePolicy, choice: DataArrayValueChoice, CacheValueService: CacheValueService });
        return _this;
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            this.controller = this;
            this.request = Object.assign(this.request, {
                data_array_id: this.dArray.id
            });
            this.columns = _lodash2.default.sortBy(this.dArray.schema, ['index', 'name'], ['asc', 'asc']);

            this.promise = Promise.all([].concat(_toConsumableArray(this.dArray.dictionaries.map(function (dictionary) {
                var valueField = _this2.getDictionaryValueField(dictionary);
                var field = _this2.getDictionaryField(dictionary);
                dictionary.values = {};
                return _this2.CacheValueService.data({ dictionary_id: dictionary.id, limit: 9999 }).then(function (data) {
                    return data.forEach(function (row) {
                        dictionary.values[_lodash2.default.get(row, valueField)] = _lodash2.default.get(row, field);
                    });
                });
            })), [_get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this)]));

            _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this);
        }
    }, {
        key: 'onIndex',
        value: function onIndex(_ref) {
            var _this3 = this;

            var id = _ref.id,
                index = _ref.index,
                data_array_id = _ref.data_array_id;

            return this.service.update(id, { index: index, data_array_id: data_array_id }).then(function () {
                return _this3.refresh();
            });
        }
    }, {
        key: 'getValue',
        value: function getValue(dictionary, row) {
            var id = row.inputs[dictionary.code];
            return dictionary.values && dictionary.values[id] ? dictionary.values[id] : id;
        }
    }, {
        key: 'getDictionaryValueField',
        value: function getDictionaryValueField(dictionary) {
            return dictionary.pivot.code != 'id' ? 'data.' + dictionary.pivot.code : 'id';
        }
    }, {
        key: 'getDictionaryField',
        value: function getDictionaryField(dictionary) {
            return dictionary.pivot.code != 'id' ? 'data.' + dictionary.pivot.code : 'name';
        }
    }, {
        key: 'store',
        value: function store() {
            var _this4 = this;

            return this.dialog.open(_store2.default, { data_array: this.dArray }).then(function () {
                return _this4.refresh();
            });
        }
    }, {
        key: 'update',
        value: function update(value) {
            var _this5 = this;

            return this.dialog.open(_update2.default, { value: value, data_array: this.dArray }).then(function () {
                return _this5.refresh();
            });
        }
    }, {
        key: 'destroy',
        value: function destroy(value) {
            var _this6 = this;

            return this.dialog.confirm('COMPONENTS.DATA_ARRAY_VALUES.GRID.ACTIONS.DESTROY.TITLE', 'COMPONENTS.DATA_ARRAY_VALUES.GRID.ACTIONS.DESTROY.MESSAGE', {}, value /*interpolation params*/).then(function () {
                return _this6.service.destroy(value.id, value);
            }).then(function () {
                return _this6.refresh();
            });
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;