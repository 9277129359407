'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _reset = require('./reset.state');

var _reset2 = _interopRequireDefault(_reset);

var _reset3 = require('./reset.component');

var _reset4 = _interopRequireDefault(_reset3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('password.reset', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_reset2.default.name, _reset2.default);
}).component(_reset4.default.name.camelCase(), _reset4.default);