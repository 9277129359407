'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dataArrayValue = require('./data.array.value.component');

var _dataArrayValue2 = _interopRequireDefault(_dataArrayValue);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.data-array-value',
    url: '/data-arrays/:id/values/',
    template: '<' + _dataArrayValue2.default.name + ' d-array=\'dataArray\'/>',
    controller: /*@ngInject*/function controller($scope, dataArray) {
        return $scope.dataArray = dataArray;
    },
    title: 'DATA_ARRAY_VALUE.TITLE',
    resolve: {
        dataArray: /*@ngInject*/function dataArray($stateParams, DataArrayService) {
            return DataArrayService.get($stateParams.id, { withs: ['dictionaries'] });
        }
    },
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'DATA_ARRAY_VALUE.BREADCRUMB' // angular-breadcrumb's configuration
    }
};