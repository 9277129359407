'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _login = require('./login.component');

var _login2 = _interopRequireDefault(_login);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'authentication.login',
    url: '/login/',
    template: '<' + _login2.default.name + '/>',
    title: 'LOGIN.TITLE'
};