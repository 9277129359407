'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _menus = require('./menus.pug');

var _menus2 = _interopRequireDefault(_menus);

var _menus3 = require('./menus.controller');

var _menus4 = _interopRequireDefault(_menus3);

require('./menus.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _menus2.default)({}),
    controller: _menus4.default,
    name: 'nlc-content-menus',
    bindings: {
        controller: '='
    },
    bindToController: true
};