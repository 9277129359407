'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _nlData = require('./directives/nl-data.directive');

var _nlData2 = _interopRequireDefault(_nlData);

var _nlDataOrderBy = require('./directives/nl-data-order-by.directive');

var _nlDataOrderBy2 = _interopRequireDefault(_nlDataOrderBy);

var _nlDataFilter = require('./directives/nl-data-filter.directive');

var _nlDataFilter2 = _interopRequireDefault(_nlDataFilter);

var _nlDataProgress = require('./directives/nl-data-progress.directive');

var _nlDataProgress2 = _interopRequireDefault(_nlDataProgress);

require('./data.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.modules.data', []).directive('nlData', /*@ngInject*/function () {
    return new _nlData2.default();
}).directive('nlDataOrderBy', /*@ngInject*/function ($compile) {
    return new _nlDataOrderBy2.default($compile);
}).directive('nlDataFilter', /*@ngInject*/function () {
    return new _nlDataFilter2.default();
}).directive('nlDataProgress', /*@ngInject*/function () {
    return new _nlDataProgress2.default();
});