'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeahead = require('./typeahead.pug');

var _typeahead2 = _interopRequireDefault(_typeahead);

var _typeahead3 = require('./typeahead.controller');

var _typeahead4 = _interopRequireDefault(_typeahead3);

require('./typeahead.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _typeahead2.default)({}),
    controller: _typeahead4.default,
    name: 'nlc-typeahead',
    transclude: true,
    bindings: {
        service: '=',
        placeholder: '@',
        field: '@',
        valueField: '<',
        termField: '@',
        request: '=?',
        selected: '='
    },
    require: {
        ngModel: '^ngModel'
    },
    bindToController: true
};