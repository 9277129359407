'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _value = require('./value.state');

var _value2 = _interopRequireDefault(_value);

var _value3 = require('./value.component');

var _value4 = _interopRequireDefault(_value3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.value', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_value2.default.name, _value2.default);
}).component(_value4.default.name.camelCase(), _value4.default);