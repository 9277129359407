'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _update = require('./update.state');

var _update2 = _interopRequireDefault(_update);

var _typeahead = require('angular-ui-bootstrap/src/typeahead');

var _typeahead2 = _interopRequireDefault(_typeahead);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('app.pages.update', [_typeahead2.default]).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_update2.default.name, _update2.default);
});