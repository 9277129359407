'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _priceDialog = require('./price.dialog.pug');

var _priceDialog2 = _interopRequireDefault(_priceDialog);

var _priceDialog3 = require('./price.dialog.controller');

var _priceDialog4 = _interopRequireDefault(_priceDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _priceDialog2.default)({}),
    controller: _priceDialog4.default,
    name: 'nlc-order-item-price-dialog'
};