'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _store = require('./dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

var _update = require('./dialog/update/update.dialog');

var _update2 = _interopRequireDefault(_update);

var _transfer = require('./dialog/transfer/transfer.dialog');

var _transfer2 = _interopRequireDefault(_transfer);

var _store3 = require('../legal-entities/dialog/store/store.dialog');

var _store4 = _interopRequireDefault(_store3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class(TranslateDialogService, ClientService, CacheValueService, CacheUserService, ClientPolicy, ClientChoice) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, ClientService));

        Object.assign(_this.request, {
            orders: {
                type: 'order'
            },
            withs: ['user', 'status', 'sale']
        });
        Object.assign(_this, { dialog: TranslateDialogService, policy: ClientPolicy, CacheUserService: CacheUserService, CacheValueService: CacheValueService, choice: ClientChoice });
        return _this;
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            this.controller = this;
            _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this);
        }
    }, {
        key: 'legalEntity',
        value: function legalEntity(client) {
            var _this2 = this;

            return this.dialog.open(_store4.default, { client_id: client.id }).then(function () {
                return _this2.refresh();
            });
        }
    }, {
        key: 'export',
        value: function _export() {
            var ext = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'xlsx';

            return this.service.export(this.request, ext);
        }
    }, {
        key: 'view',
        value: function view(client) {
            client.show = !client.show;
        }
    }, {
        key: 'store',
        value: function store() {
            var _this3 = this;

            return this.dialog.open(_store2.default).then(function () {
                return _this3.refresh();
            });
        }
    }, {
        key: 'update',
        value: function update(client) {
            var _this4 = this;

            return this.dialog.open(_update2.default, { client: client }).then(function () {
                return _this4.refresh();
            });
        }
    }, {
        key: 'destroy',
        value: function destroy(client) {
            var _this5 = this;

            return this.dialog.confirm('COMPONENTS.CLIENTS.GRID.ACTIONS.DESTROY.TITLE', 'COMPONENTS.CLIENTS.GRID.ACTIONS.DESTROY.MESSAGE', {}, client).then(function () {
                return _this5.service.destroy(client.id, client);
            }).then(function () {
                return _this5.refresh();
            });
        }
    }, {
        key: 'share',
        value: function share(client) {
            var _this6 = this;

            return this.dialog.confirm('COMPONENTS.CLIENTS.GRID.ACTIONS.SHARE.TITLE', 'COMPONENTS.CLIENTS.GRID.ACTIONS.SHARE.MESSAGE', {}, client).then(function () {
                return _this6.service.share(client.id, client);
            }).then(function (updated) {
                return Object.assign(client, updated);
            });
        }
    }, {
        key: 'transfer',
        value: function transfer(client) {
            var _this7 = this;

            return this.dialog.confirm('COMPONENTS.CLIENTS.GRID.ACTIONS.TRANSFER.TITLE', 'COMPONENTS.CLIENTS.GRID.ACTIONS.TRANSFER.MESSAGE', {}, client).then(function () {
                return _this7.dialog.open(_transfer2.default);
            }).then(function (to) {
                return _this7.service.transfer(client.id, { types: ['bid', 'order'], to_client_id: to.id });
            }).then(function () {
                return _this7.refresh();
            });
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;