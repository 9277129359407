'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(IdentityService) {
        _classCallCheck(this, _class);

        Object.assign(this, { IdentityService: IdentityService });
    }

    _createClass(_class, [{
        key: 'type',
        value: function type(_ref) {
            var _type = _ref.type;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            switch (_type) {
                case 'bid':
                    {
                        return prefix + 'info';
                    }
                default:
                    {
                        return prefix + 'success';
                    }
            }
        }
    }, {
        key: 'payment_status',
        value: function payment_status(_ref2) {
            var _payment_status = _ref2.payment_status,
                created_at = _ref2.created_at;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            switch (_payment_status) {
                case 'unpaid':
                    {
                        var days = (0, _moment2.default)(created_at).diff(new Date(), 'days');
                        if (days >= 3) {
                            return prefix + 'danger';
                        }
                        return prefix + 'default';
                    }
                case 'credit':
                    {
                        return prefix + 'warning';
                    }
                case 'paid':
                    {
                        return prefix + 'success';
                    }
                case 'partial':
                    {
                        var _days = (0, _moment2.default)(created_at).diff(new Date(), 'days');
                        if (_days >= 3) {
                            return prefix + 'danger';
                        }
                        return prefix + 'warning';
                    }
                default:
                    {
                        return prefix + 'default';
                    }
            }
        }
    }, {
        key: 'wish_readiness_date',
        value: function wish_readiness_date(_ref3) {
            var _wish_readiness_date = _ref3.wish_readiness_date,
                status = _ref3.status;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            if (status.is_final || _wish_readiness_date == null) {
                return '';
            }
            var days = (0, _moment2.default)(_wish_readiness_date).diff(new Date(), 'days');
            if (days <= 0) {
                return prefix + 'danger';
            }
            if (days <= 2) {
                return prefix + 'warning';
            }
            if (days <= 3) {
                return prefix + 'info';
            }
            return prefix + 'default';
        }
    }, {
        key: 'shared',
        value: function shared(_ref4) {
            var sale_id = _ref4.sale_id,
                user_id = _ref4.user_id;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            var user = this.IdentityService.user;
            if (user.sale_id == sale_id || user.id == user_id) {
                return prefix + 'danger';
            } else {
                return prefix + 'warning';
            }
        }
    }]);

    return _class;
}();

exports.default = _class;