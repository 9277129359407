'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _saferEval = require('safer-eval');

var _saferEval2 = _interopRequireDefault(_saferEval);

var _group = require('./group.utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $scope: $scope });
    }

    _createClass(_class, [{
        key: 'getSmartSides',
        value: function getSmartSides(_ref) {
            var sides = _ref.sides;

            try {
                return (0, _saferEval2.default)(sides || '1', this.actualAssocData);
            } catch (e) {
                return 1;
            }
        }
    }, {
        key: 'registerComponent',
        value: function registerComponent(input) {
            var service = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            var conditional = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
            var defaultSubtype = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'number';
            var defaultValue = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

            var base = input.base;
            var meta = this.group.components[base] || {};

            //if hidden, means moved up
            if (meta.hidden && this.parent) {
                return this.parent.registerComponent(input, service, conditional);
            }
            var type = (0, _group.detectType)(this.dictionaries, base) || input.type;
            var subtype = (0, _group.detectSubtype)(this.dictionaries, base, type, defaultSubtype);
            //check if added already
            var component = this.components.filter(function (component) {
                return component.base == base;
            }).shift();
            //if component not found, then init it
            if (!component) {
                var additionalValues = meta.flexible ? [{ id: -1, index: -1, own: true, name: 'Свои значения' }] : [];
                component = _extends({
                    //system,
                    //invisible
                    //flexible
                    //inheritable
                    //inherit
                    //index
                    //filterable
                    subtype: subtype,
                    codes: []
                }, meta, {
                    inheritable: meta.inheritable && this.level != 1,
                    //link to the request to make easier manage it
                    request: this.request[this.id].data,
                    base: base,
                    type: type,
                    //take from dictionaries or take jsut base code
                    name: (this.dictionaries[base] || { name: base }).name,
                    //dictionary, actual meta data
                    dictionary: this.dictionaries[base] || {},
                    conditions: {},
                    settings: {
                        //service id available values
                        values: {},
                        //associated services
                        services: [],
                        //defaults
                        defaults: {},
                        codes: {},
                        default: input.default
                    },
                    allValues: [].concat(additionalValues, _toConsumableArray((this.dictionaries[base] || { values: [] }).values)),
                    conditional: conditional,
                    enabled: !(meta.inheritable && this.level != 1) && !meta.invisible,
                    visible: true,
                    //bind to watcher
                    inheritWatcher: undefined
                    //sort components
                });this.components = _lodash2.default.sortBy([].concat(_toConsumableArray(this.components), [component]), ['index', 'name'], ['asc', 'asc']);
            }
            //fix conditional property if later added conditional
            if (conditional && !component.conditional) {
                component.conditional = true;
            }

            if (service) {
                component.settings.codes[service.id] = _lodash2.default.uniq([].concat(_toConsumableArray(component.settings.codes[service.id] || []), _toConsumableArray(input.codes || [])));
            }
            //if not system, set service
            if (service && !component.system) {
                if (!component.settings.services.some(function (_ref2) {
                    var id = _ref2.id;
                    return id == service.id;
                })) {
                    component.settings.services.push(service);
                }
                //set values
                component.settings.values[service.id] = _lodash2.default.union(component.settings.values[service.id] || [], input.values);
                component.settings.defaults[service.id] = input.default;
            }
            if (defaultValue) {
                component.request[component.base] = defaultValue;
            }
            if (component.inheritable) {
                component.inheritWatcher = this.$scope.$watch('$ctrl.request["' + component.inherit + '"].data.' + component.base, function (value) {
                    component.request[component.base] = value;
                });
            }

            // this.buildComponent(component)
            return component;
        }
    }, {
        key: 'init',
        value: function init() {
            var _this = this;

            this.id = this.group.id;
            Object.assign(this, {
                loading: false,
                prices: {
                    expenses: 0,
                    services: 0,
                    total: 0
                },
                params: {},
                results: {},
                _services: _lodash2.default.cloneDeep(_lodash2.default.pick(this.services, this.group.services)),
                _outputs: _lodash2.default.sortBy(_lodash2.default.values(this.group.outputs).filter(function (output) {
                    return output.resultable;
                }).map(function (output) {
                    return _extends({}, output, { name: (_this.outputs[output.base] || { name: output.base }).name });
                }), ['r_index', 'name'], ['asc', 'asc']),
                components: [],
                groups: _lodash2.default.sortBy(this.group.groups, ['index', 'name'], ['asc', 'asc']),
                //some components can be moved up to parent and won't be accessed via this.components. So, the container bellow is a container of all our components
                managedComponents: [],
                actualAssocData: {},
                dictionaryComponents: {}
            });

            // init request, merge with existing
            this.request[this.id] = Object.assign({
                data: {},
                confirm: !!this.group.required,
                groups: {}
            }, this.request[this.id] || {});

            if (this.group.has_production) {
                this.managedComponents.push(this.registerComponent({ base: 'production', type: 2 }, null, false, 'number', this.group.default_production));
            }

            if (this.group.has_name) {
                this.managedComponents.push(this.registerComponent({ base: 'name', type: 1 }, null, false, 'text'));
            }

            if (this.group.has_comment) {
                this.managedComponents.push(this.registerComponent({ base: 'comment', type: 1 }, null, false, 'text'));
            }

            _lodash2.default.values(this.group.extras).forEach(function (base) {
                return _this.managedComponents.push(_this.registerComponent({ base: base }, null, false));
            });

            _lodash2.default.values(this._services).forEach(function (service) {
                //register conditional components
                _lodash2.default.values(service.schema.condition.inputs).map(function (input) {
                    return _this.registerComponent(input, service, true);
                }).forEach(function (component) {
                    return _this.managedComponents.push(component);
                });
                //register normal components
                _lodash2.default.values(service.schema.source.inputs).map(function (input) {
                    return _this.registerComponent(input, service, false);
                }).forEach(function (component) {
                    return _this.managedComponents.push(component);
                });
            });
            this.managedComponents.filter(function (cmp) {
                return cmp.type == 2;
            }).forEach(function (component) {
                _this.dictionaryComponents[component.dictionary.id] = component;
            });

            // rebuild group
            this.rebuild();

            var bases = this.components.map(function (cmp) {
                return cmp.base;
            }).filter(function (base) {
                return base != 'name' && base != 'comment';
            });
            var onUpdate = function onUpdate() {
                var newActualAssocData = Object.assign(_lodash2.default.omit(_this.assocData || {}, bases), _this.request[_this.id].data);
                if (!_lodash2.default.isEqual(_this.actualAssocData, newActualAssocData)) {
                    _this.actualAssocData = newActualAssocData;
                    _this.rebuild();
                }
            };

            // prepare binds and return them
            return [
            //results hook
            this.$scope.$watch('$ctrl.results', function () {
                return _this.onResults();
            }),
            //comes from parent
            this.$scope.$watch('$ctrl.assocData', onUpdate, true /*deep*/),
            // hook on updates
            this.$scope.$watch('$ctrl.request[$ctrl.id]', onUpdate, true /*deep*/)];
        }
    }, {
        key: 'setGroupPrices',
        value: function setGroupPrices(prices) {
            this.prices.expenses += prices.expenses;
            this.prices.services += prices.services;
            if (this.parent) {
                this.parent.setGroupPrices(prices);
            }
        }
    }, {
        key: 'onResults',
        value: function onResults() {
            var _this2 = this;

            if (angular.isUndefined(this.results) || angular.isUndefined(this.results.default)) {
                return;
            }
            this.prices = {
                expenses: 0,
                services: 0,
                total: this.results.default.results.default
            };
            this.params = _lodash2.default.pickBy(_lodash2.default.get(this, 'results.default.results.data', {}), function (value, key) {
                return key[0] === '$';
            });
            _lodash2.default.values(this._services).forEach(function (service) {
                var source = _this2.results.sources[service.id];
                service.price = source.results.default;
                if (service.type == 'service') {
                    _this2.prices.services += service.price;
                } else {
                    _this2.prices.expenses += service.price;
                }
            });
            if (this.parent && this.request[this.id].confirm) {
                this.parent.setGroupPrices(this.prices);
            }
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this3 = this;

            var binds = [];
            this.$scope.$watch('$ctrl.group', function () {
                binds.map(function (bind) {
                    return bind();
                });
                binds = _this3.init();
            });
        }
    }, {
        key: 'buildComponent',
        value: function buildComponent(component) {
            if (component.inheritable && this.level != 1) {
                return;
            }

            var availableServices = component.settings.services.filter(function (_ref3) {
                var enabled = _ref3.enabled;
                return enabled;
            }).map(function (_ref4) {
                var id = _ref4.id;
                return id;
            });

            //спрятать компонент здесь
            component.enabled = (availableServices.length !== 0 || component.conditional || component.system) && !component.invisible && !component.inheritable;

            var availableDefaults = availableServices.map(function (id) {
                return component.settings.defaults[id];
            }).filter(function (defaults) {
                return defaults != undefined;
            });

            if (component.type == 2) {
                component.codes = _lodash2.default.uniq(_lodash2.default.concat.apply(null, availableServices.map(function (id) {
                    return component.settings.codes[id] || [];
                })));
                // console.log(component.base, component.codes, availableServices, component.settings.codes, availableServices.map(id => component.settings.codes[id] || []))

                var conditionValues = _lodash2.default.concat.apply(null, _lodash2.default.values(component.conditions).filter(function (values) {
                    return values != [];
                }));
                var availableValues = [];
                if (!component.system) {
                    availableValues = component.conditional
                    //union all posible values, needed for the component conditional view
                    ? _lodash2.default.union.apply(null, _lodash2.default.values(component.settings.values))
                    // overwise only intersection
                    : _lodash2.default.intersection.apply(null, availableServices.map(function (id) {
                        return component.settings.values[id];
                    }));

                    if (conditionValues.length != 0) {
                        availableValues = _lodash2.default.intersection(availableValues, conditionValues);
                    }
                    component.values = component.allValues.filter(function (value) {
                        return availableValues.includes(value.id) || value.own;
                    });
                    availableDefaults = availableDefaults.filter(function (def) {
                        return availableValues.includes(def);
                    });
                } else {
                    component.values = conditionValues.length == 0 ? component.allValues : component.allValues.filter(function (value) {
                        return conditionValues.includes(value.id) || value.own;
                    });
                    availableValues = component.values.map(function (value) {
                        return value.id;
                    });
                }

                var firstDefaultValue = availableDefaults.shift();

                var request = component.request[component.base] || { id: -1, own: false };

                var value = component.values.find(function (value) {
                    return value.id == firstDefaultValue;
                }) || component.values[0];
                if (value) {
                    if (!availableValues.includes(request.id) && !request.own || component.invisible) {
                        component.request[component.base] = value;
                    }
                }

                component.visible = component.values.length > 1;
            } else {
                var _firstDefaultValue = availableDefaults.shift();
                if (!component.request[component.base]) {
                    component.request[component.base] = _firstDefaultValue;
                }
            }
        }
    }, {
        key: 'rebuild',
        value: function rebuild() {
            var _this4 = this;

            var ready = true;
            _lodash2.default.values(this._services).forEach(function (service) {
                try {
                    service.enabled = (0, _saferEval2.default)(service.condition, _this4.actualAssocData);
                } catch (e) {
                    ready = false;
                    // service.enabled = true
                }
            });
            if (!ready) {
                //let's init only conditional components
                this.managedComponents.filter(function (component) {
                    return component.conditional;
                }).forEach(function (component) {
                    return _this4.buildComponent(component);
                });
                return;
            }
            var components = this.managedComponents.filter(function (cmp) {
                return cmp.type == 2;
            });
            //clear conditions
            components.forEach(function (cmp) {
                return cmp.conditions = {};
            });
            //set new conditions
            //associations algorithm
            components.forEach(function (component) {
                var value = _this4.request[_this4.id].data[component.base];
                if (value == undefined) {
                    return;
                }
                var id = value.id,
                    meta = value.meta;

                _lodash2.default.forEach((meta || { associations: {} }).associations, function (ids, dictionary_id) {
                    (_this4.dictionaryComponents[dictionary_id] || { conditions: {} }).conditions[id] = ids;
                });
            });
            //build components
            this.managedComponents.forEach(function (component) {
                return _this4.buildComponent(component);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;