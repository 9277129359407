'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _source = require('./source.component');

var _source2 = _interopRequireDefault(_source);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.source',
    url: '/sources/',
    template: '<' + _source2.default.name + '/>',
    title: 'SOURCE.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'SOURCE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.SOURCE',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-map'
    }
};