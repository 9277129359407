'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _image = require('./image.pug');

var _image2 = _interopRequireDefault(_image);

var _image3 = require('./image.controller');

var _image4 = _interopRequireDefault(_image3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _image2.default)({}),
    name: 'image-dialog',
    size: 'lg',
    controller: _image4.default
};