'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _services = require('./services.pug');

var _services2 = _interopRequireDefault(_services);

var _services3 = require('./services.controller');

var _services4 = _interopRequireDefault(_services3);

require('./services.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _services2.default)({}),
    controller: _services4.default,
    name: 'nlc-services',
    bindings: {
        controller: '=',
        request: '=?',
        mode: '@',
        onSelect: '&'
    },
    bindToController: true
};