'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dialog = require('modules/dialog/controllers/dialog.controller');

var _dialog2 = _interopRequireDefault(_dialog);

var _track = require('../../../components/calculations/dialog/track/track.dialog');

var _track2 = _interopRequireDefault(_track);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DialogController) {
    _inherits(_class, _DialogController);

    function _class($scope, data, TranslateDialogService, product_calc) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, $scope));

        console.log(product_calc);
        Object.assign(_this, { data: data, dialog: TranslateDialogService, product_calc: product_calc });
        return _this;
    }

    _createClass(_class, [{
        key: 'submit',
        value: function submit() {
            this.promise = this.calc.calc();
        }
    }, {
        key: 'track',
        value: function track() {
            this.dialog.open(_track2.default, { inputs: this.request, product_calc: this.product_calc });
        }
    }]);

    return _class;
}(_dialog2.default);

exports.default = _class;