'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _password = require('./password.pug');

var _password2 = _interopRequireDefault(_password);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    abstract: true,
    url: '/password',
    template: (0, _password2.default)({}),
    name: 'password',
    layout: {
        class: 'background-white'
    }
};