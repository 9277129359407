'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _items = require('./items.pug');

var _items2 = _interopRequireDefault(_items);

var _items3 = require('./items.controller');

var _items4 = _interopRequireDefault(_items3);

require('./items.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _items2.default)({}),
    controller: _items4.default,
    name: 'nlc-order-items',
    bindings: {
        controller: '=',
        order: '<'
    },
    bindToController: true
};