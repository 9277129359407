'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _statusDialog = require('./status.dialog.pug');

var _statusDialog2 = _interopRequireDefault(_statusDialog);

var _statusDialog3 = require('./status.dialog.controller');

var _statusDialog4 = _interopRequireDefault(_statusDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _statusDialog2.default)({}),
    controller: _statusDialog4.default,
    name: 'nlc-order-status-dialog'
};