'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, [{
        key: 'component',
        value: function component(node) {
            return node && node.type == 'component';
        }
    }, {
        key: 'output',
        value: function output(node) {
            return node && node.type == 'output';
        }
    }, {
        key: 'group',
        value: function group(node) {
            return node && node.type == 'group';
        }
    }, {
        key: 'service',
        value: function service(node) {
            return node && node.type == 'service';
        }
    }, {
        key: 'rootGroup',
        value: function rootGroup(node, data) {
            return this.group(node) && node == data[0];
        }
    }, {
        key: 'systemComponent',
        value: function systemComponent(node) {
            return node.system && this.component(node);
        }
    }, {
        key: 'smartComponent',
        value: function smartComponent(node, dictionaries, code) {
            if (!dictionaries) {
                return;
            }
            var dictionary = dictionaries[node.base];

            return this.component(node) && dictionary && dictionary.type == 'smart' && dictionary.schema && dictionary.schema.smart == code;
        }
    }, {
        key: 'editable',
        value: function editable(node) {
            return this.component(node) || this.group(node) || this.service(node);
        }
    }, {
        key: 'resultable',
        value: function resultable(node) {
            return this.component(node) || this.output(node);
        }
    }, {
        key: 'clientable',
        value: function clientable(node) {
            return this.component(node) || this.output(node);
        }
    }, {
        key: 'moved',
        value: function moved(node) {
            return node && node.moved;
        }
    }, {
        key: 'hidden',
        value: function hidden(node) {
            return node && node.hidden;
        }
    }, {
        key: 'moveable',
        value: function moveable(node, parent, data) {
            return data && parent && data[0] !== parent && !node.system && !node.hidden;
        }
    }, {
        key: 'lighted',
        value: function lighted(node) {
            return this.output(node) && !node.resultable || this.hidden(node);
        }
    }]);

    return _class;
}();

exports.default = _class;