'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _sources = require('./sources.pug');

var _sources2 = _interopRequireDefault(_sources);

var _sources3 = require('./sources.controller');

var _sources4 = _interopRequireDefault(_sources3);

require('./sources.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _sources2.default)({}),
    controller: _sources4.default,
    name: 'nlc-sources',
    bindings: {
        controller: '='
    },
    bindToControler: true
};