'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _product = require('./product.state');

var _product2 = _interopRequireDefault(_product);

var _product3 = require('./product.component');

var _product4 = _interopRequireDefault(_product3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = 'product';
exports.default = _angular2.default.module('app.pages.product', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_product2.default.name, _product2.default);
}).component(_product4.default.name.camelCase(), _product4.default);