'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nlInputContainer = require('./nl-input-container.pug');

var _nlInputContainer2 = _interopRequireDefault(_nlInputContainer);

var _nlInputContainer3 = require('./nl-input-container.controller');

var _nlInputContainer4 = _interopRequireDefault(_nlInputContainer3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class() {
        _classCallCheck(this, _class);

        Object.assign(this, {
            restrict: 'E',
            transclude: true,
            scope: {},
            replace: true,
            template: (0, _nlInputContainer2.default)({}),
            controller: _nlInputContainer4.default,
            controllerAs: '$ctrl'
        });
    }

    _createClass(_class, [{
        key: 'link',
        value: function link(scope, element, attrs) {
            scope.control = [].concat(_toConsumableArray(element.find('input')), _toConsumableArray(element.find('select')), _toConsumableArray(element.find('textarea'))).map(function (input) {
                return angular.element(input).data();
            }).filter(function (data) {
                return data.$ngModelController;
            }).map(function (data) {
                return data.$ngModelController;
            }).shift();
            var onFocus = function onFocus() {
                return scope.control.$setValidity('remote', true);
            };
            scope.control.$viewChangeListeners.push(onFocus);
            scope.control.$$element.on('focus', onFocus);
            scope.$on('$destroy', function () {
                scope.control.$$element.off('focus', onFocus);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;