'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _pages = require('./pages.pug');

var _pages2 = _interopRequireDefault(_pages);

var _pages3 = require('./pages.controller');

var _pages4 = _interopRequireDefault(_pages3);

require('./pages.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _pages2.default)({}),
    controller: _pages4.default,
    name: 'nlc-content-pages',
    bindings: {
        controller: '='
    },
    bindToController: true
};