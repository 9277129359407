'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dataArray = require('./data.array.pug');

var _dataArray2 = _interopRequireDefault(_dataArray);

var _dataArray3 = require('./data.array.controller');

var _dataArray4 = _interopRequireDefault(_dataArray3);

require('./data.array.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _dataArray2.default)({}),
    controller: _dataArray4.default,
    name: 'nlc-data-array-page'
};