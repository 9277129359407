'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _action = require('./action.state');

var _action2 = _interopRequireDefault(_action);

var _action3 = require('./action.component');

var _action4 = _interopRequireDefault(_action3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.action', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_action2.default.name, _action2.default);
}).component(_action4.default.name.camelCase(), _action4.default);