'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _calculation = require('./calculation.state');

var _calculation2 = _interopRequireDefault(_calculation);

var _calculation3 = require('./calculation.component');

var _calculation4 = _interopRequireDefault(_calculation3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.calculation', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_calculation2.default.name, _calculation2.default);
}).component(_calculation4.default.name.camelCase(), _calculation4.default);