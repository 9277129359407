'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _constants = require('./constants');

var _constants2 = _interopRequireDefault(_constants);

var _breadcrumb = require('assets/tpls/breadcrumb.pug');

var _breadcrumb2 = _interopRequireDefault(_breadcrumb);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.config', [_constants2.default.name]).config(function ($qProvider) {
    'ngInject';

    $qProvider.errorOnUnhandledRejections(false);
}).config(function ($locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
})
//local - storage
.config(function (localStorageServiceProvider, APP_PREFIX) {
    'ngInject';

    localStorageServiceProvider.setPrefix(APP_PREFIX);
})
//default router
.config(function ($urlRouterProvider, DEFAULT_STATE) {
    'ngInject';
    // Prevent router from automatic state resolving

    $urlRouterProvider.deferIntercept();

    // Use instead
    $urlRouterProvider.when('/', function ($injector) {
        return $injector.get("$state").go(DEFAULT_STATE);
    }).otherwise(function ($injector) {
        return $injector.get("$state").go('not-found');
    });
})
//setup all interceptors
.config(function ($httpProvider) {
    'ngInject';

    $httpProvider.defaults.headers.common['Accept'] = 'application/json';
    $httpProvider.interceptors.push('AuthInterceptor');
    $httpProvider.interceptors.push('LanguageInterceptor');
    $httpProvider.interceptors.push('ErrorInterceptor');
    $httpProvider.interceptors.push('TimezoneInterceptor');
    // $httpProvider.interceptors.push('ConfirmConflictInterceptor');

    // $httpProvider.interceptors.push('EchoInterceptor');
})
//spinner
.config(function (cfpLoadingBarProvider) {
    'ngInject';

    cfpLoadingBarProvider.includeSpinner = false;
    // cfpLoadingBarProvider.latencyThreshold = 300;
}).config(function ($breadcrumbProvider, DEFAULT_STATE) {
    'ngInject';

    $breadcrumbProvider.setOptions({
        prefixStateName: 'app',
        includeAbstract: true,
        template: (0, _breadcrumb2.default)({})
    });
}).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.decorator('root', function (state) {
        state.self.root = state.parent.self;
    });
})
// angular translate
.config(function (DirectionProvider) {
    'ngInject';

    DirectionProvider.registerLanguage('en', 'ltr');
    DirectionProvider.registerLanguage('ru', 'rtl');
}).config(function ($translateProvider) {
    'ngInject';

    $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: '{part}/{lang}.json'
    }).useMessageFormatInterpolation().registerAvailableLanguageKeys(['en', 'ru'], {
        'en_*': 'en',
        'ru_*': 'ru'
        // '*': 'en',
    }).preferredLanguage('ru').determinePreferredLanguage().fallbackLanguage('ru')
    //security
    .useSanitizeValueStrategy('escape')
    //save state into local storage
    // .useLocalStorage()
    //log mistakes
    .useMissingTranslationHandlerLog();
}).config(function (laddaProvider) {
    'ngInject';

    laddaProvider.setOption({
        style: 'expand-right',
        spinnerSize: 25
    });
});