'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _deliveryFullInfo = require('./delivery-full-info.pug');

var _deliveryFullInfo2 = _interopRequireDefault(_deliveryFullInfo);

var _deliveryFullInfo3 = require('./delivery-full-info.controller');

var _deliveryFullInfo4 = _interopRequireDefault(_deliveryFullInfo3);

require('./delivery-full-info.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _deliveryFullInfo2.default)({}),
    controller: _deliveryFullInfo4.default,
    name: 'nlc-delivery-full-info',
    bindings: {
        provider: '<',
        inputs: '<',
        data: '<',
        results: '<'
    },
    bindToController: true
};