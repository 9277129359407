'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _product = require('./product.policy');

var _product2 = _interopRequireDefault(_product);

var _user = require('./user.policy');

var _user2 = _interopRequireDefault(_user);

var _client = require('./client.policy');

var _client2 = _interopRequireDefault(_client);

var _status = require('./status.policy');

var _status2 = _interopRequireDefault(_status);

var _service = require('./service.policy');

var _service2 = _interopRequireDefault(_service);

var _parameter = require('./parameter.policy');

var _parameter2 = _interopRequireDefault(_parameter);

var _dictionary = require('./dictionary.policy');

var _dictionary2 = _interopRequireDefault(_dictionary);

var _dataArray = require('./data.array.policy');

var _dataArray2 = _interopRequireDefault(_dataArray);

var _dataArrayValue = require('./data.array.value.policy');

var _dataArrayValue2 = _interopRequireDefault(_dataArrayValue);

var _report = require('./report.policy');

var _report2 = _interopRequireDefault(_report);

var _action = require('./action.policy');

var _action2 = _interopRequireDefault(_action);

var _source = require('./source.policy');

var _source2 = _interopRequireDefault(_source);

var _constant = require('./constant.policy');

var _constant2 = _interopRequireDefault(_constant);

var _value = require('./value.policy');

var _value2 = _interopRequireDefault(_value);

var _calc = require('./calc.policy');

var _calc2 = _interopRequireDefault(_calc);

var _calculation = require('./calculation.policy');

var _calculation2 = _interopRequireDefault(_calculation);

var _order = require('./order.policy');

var _order2 = _interopRequireDefault(_order);

var _item = require('./order/item.policy');

var _item2 = _interopRequireDefault(_item);

var _asset = require('./order/item/asset.policy');

var _asset2 = _interopRequireDefault(_asset);

var _invoice = require('./invoice.policy');

var _invoice2 = _interopRequireDefault(_invoice);

var _transaction = require('./transaction.policy');

var _transaction2 = _interopRequireDefault(_transaction);

var _valueAssociation = require('./value.association.policy');

var _valueAssociation2 = _interopRequireDefault(_valueAssociation);

var _office = require('./office.policy');

var _office2 = _interopRequireDefault(_office);

var _tenant = require('./tenant.policy');

var _tenant2 = _interopRequireDefault(_tenant);

var _reward = require('./reward.policy');

var _reward2 = _interopRequireDefault(_reward);

var _menu = require('./content/menu.policy');

var _menu2 = _interopRequireDefault(_menu);

var _menuItem = require('./content/menu.item.policy');

var _menuItem2 = _interopRequireDefault(_menuItem);

var _block = require('./content/block.policy');

var _block2 = _interopRequireDefault(_block);

var _page = require('./content/page.policy');

var _page2 = _interopRequireDefault(_page);

var _asset3 = require('./asset.policy');

var _asset4 = _interopRequireDefault(_asset3);

var _clientLegalEntity = require('./client.legal.entity.policy');

var _clientLegalEntity2 = _interopRequireDefault(_clientLegalEntity);

var _deliveryType = require('./delivery.type.policy');

var _deliveryType2 = _interopRequireDefault(_deliveryType);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.policies', []).service('ClientPolicy', _client2.default).service('StatusPolicy', _status2.default).service('ProductPolicy', _product2.default).service('ServicePolicy', _service2.default).service('ParameterPolicy', _parameter2.default).service('DictionaryPolicy', _dictionary2.default).service('DataArrayPolicy', _dataArray2.default).service('DataArrayValuePolicy', _dataArrayValue2.default).service('ReportPolicy', _report2.default).service('ActionPolicy', _action2.default).service('SourcePolicy', _source2.default).service('ConstantPolicy', _constant2.default).service('UserPolicy', _user2.default).service('ValuePolicy', _value2.default).service('CalcPolicy', _calc2.default).service('CalculationPolicy', _calculation2.default).service('OrderPolicy', _order2.default).service('OrderItemPolicy', _item2.default).service('OrderItemAssetPolicy', _asset2.default).service('InvoicePolicy', _invoice2.default).service('TransactionPolicy', _transaction2.default).service('ValueAssociationPolicy', _valueAssociation2.default).service('OfficePolicy', _office2.default).service('TenantPolicy', _tenant2.default).service('RewardPolicy', _reward2.default).service('ContentMenuPolicy', _menu2.default).service('ContentMenuItemPolicy', _menuItem2.default).service('ContentBlockPolicy', _block2.default).service('ContentPagePolicy', _page2.default).service('AssetPolicy', _asset4.default).service('ClientLegalEntityPolicy', _clientLegalEntity2.default).service('DeliveryTypePolicy', _deliveryType2.default);