'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _tree = require('./tree');

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _calcRaw = require('../../common/dialog/calc-raw/calc-raw.dialog');

var _calcRaw2 = _interopRequireDefault(_calcRaw);

var _calc = require('../../common/dialog/calc/calc.dialog');

var _calc2 = _interopRequireDefault(_calc);

var _extra = require('./dialog/extra/extra.dialog');

var _extra2 = _interopRequireDefault(_extra);

var _calcConstructor = require('./calc-constructor.utils');

var _calcConstructor2 = require('./calc-constructor.policy');

var _calcConstructor3 = _interopRequireDefault(_calcConstructor2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Controller
 */
var nodeOrders = {
    service: 1,
    component: 2,
    output: 3,
    group: 4
};

var _class = function () {
    function _class(TranslateDialogService, CacheActionService, CacheTenantService, CacheDictionaryService, CacheValueService, ServiceService, $scope) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            policy: new _calcConstructor3.default(),
            dialog: TranslateDialogService,
            CacheActionService: CacheActionService,
            CacheDictionaryService: CacheDictionaryService,
            CacheValueService: CacheValueService,
            CacheTenantService: CacheTenantService,
            ServiceService: ServiceService,
            $scope: $scope,
            names: {},
            dictionaries: {},
            loading: true,
            nodes: [],
            options: {
                orderBy: function orderBy(_ref) {
                    var type = _ref.type;
                    return nodeOrders[type];
                },
                orderByTypedIndex: function orderByTypedIndex(_ref2) {
                    var type = _ref2.type,
                        index = _ref2.index,
                        r_index = _ref2.r_index;
                    return type == 'output' ? r_index : index;
                }
            }
        });
    }

    _createClass(_class, [{
        key: 'getFormatHint',
        value: function getFormatHint(base) {
            var dictionary = this.dictionaries[base];
            if (!dictionary || !dictionary.schema) {
                return '';
            }
            switch (dictionary.type) {
                case 'dictionary':
                    {
                        return '%(' + dictionary.schema.map(function (_ref3) {
                            var code = _ref3.code;
                            return code;
                        }).join(')s, %(') + ')s';
                        break;
                    }
                case 'smart':
                    {
                        return 'Обратитесь к разработчику. Компонент: ' + dictionary.schema.smart;
                        break;
                    }
            }
            return '';
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            this.$scope.$watch('$ctrl.selected.has_production', function () {
                if (_this.selected && _this.selected.type == 'group') {
                    if (_this.selected.has_production) {
                        _this.wrapNode(_this.selected, null).addComponent('production', false, true);
                        _this.expandedNodes.push(_this.selected);
                    } else {
                        _this.wrapNode(_this.selected, null).removeComponent('production');
                    }
                }
            });
            this.$scope.$watch('$ctrl.selected.has_name', function () {
                if (_this.selected && _this.selected.type == 'group') {
                    if (_this.selected.has_name) {
                        _this.wrapNode(_this.selected, null).addComponent('name', false, true);
                        _this.expandedNodes.push(_this.selected);
                    } else {
                        _this.wrapNode(_this.selected, null).removeComponent('name');
                    }
                }
            });
            this.$scope.$watch('$ctrl.selected.has_comment', function () {
                if (_this.selected && _this.selected.type == 'group') {
                    if (_this.selected.has_comment) {
                        _this.wrapNode(_this.selected, null).addComponent('comment', false, true);
                        _this.expandedNodes.push(_this.selected);
                    } else {
                        _this.wrapNode(_this.selected, null).removeComponent('comment');
                    }
                }
            });
            if (angular.isUndefined(this.data)) {
                this.data = { name: "Вид продукта", type: "group", required: true, id: (0, _v2.default)().slice(0, 8) };
            }
            var serviceIds = function serviceIds(_ref4) {
                var services = _ref4.services,
                    groups = _ref4.groups;
                return _lodash2.default.uniq([].concat(_toConsumableArray(_lodash2.default.values(services)), _toConsumableArray(_lodash2.default.concat.apply(null, _lodash2.default.values(groups).map(function (group) {
                    return serviceIds(group);
                })))));
            };
            var ids = serviceIds(this.data);

            this.promise = Promise.all([this.CacheDictionaryService.data({ limit: 999 }).then(function (data) {
                return data.forEach(function (row) {
                    _this.names[row.code] = row.name;
                    _this.dictionaries[row.code] = _extends({}, row);
                });
            }), ids.length == 0 ? Promise.resolve({}) : this.ServiceService.data({ ids: ids, limit: 999 }).then(function (services) {
                var results = {};
                services.forEach(function (service) {
                    results[service.id] = {
                        id: service.id,
                        name: service.name,
                        type: 'service',
                        inputs: _lodash2.default.unionBy(_lodash2.default.keys(service.schema.condition.inputs), _lodash2.default.keys(service.schema.source.inputs)),
                        outputs: _lodash2.default.keys(service.schema.source.outputs)
                    };
                });
                return results;
            })]).then(function (data) {
                var _data = _slicedToArray(data, 2),
                    nothing = _data[0],
                    services = _data[1];

                _this.nodes = [(0, _calcConstructor.decode)(_this.data, services, _this.names)];
                _this.expandedNodes = _lodash2.default.values(_this.nodes);
            }).finally(function () {
                return _this.loading = false;
            });

            this.$scope.$watch('$ctrl.nodes', function () {
                if (_this.loading || !_this.nodes || !_this.nodes[0]) return;
                _this.data = (0, _calcConstructor.encode)(_this.nodes[0]);
            }, true);
        }
    }, {
        key: 'onSelection',
        value: function onSelection(path) {
            var _path = path(),
                _path2 = _slicedToArray(_path, 3),
                node = _path2[0],
                rawParent = _path2[1],
                rawRootParent = _path2[2];

            this.siblings = node.type == 'component' ? this.wrapNode(rawParent, rawRootParent).getSiblings() : [];
        }
    }, {
        key: 'calc',
        value: function calc(data) {
            return this.dialog.open(_calc2.default, { data: (0, _calcConstructor.encode)(data), product_calc: { id: -1 } });
        }
    }, {
        key: 'serviceCalc',
        value: function serviceCalc(_ref5) {
            var _this2 = this;

            var id = _ref5.id;

            return this.ServiceService.get(id).then(function (_ref6) {
                var schema = _ref6.schema;
                return _this2.dialog.open(_calcRaw2.default, { id: id, type: 'service', schema: schema });
            });
        }
    }, {
        key: 'wrapNode',
        value: function wrapNode(node, parent) {
            return _tree.Factory.create(node, parent, { names: this.names, dictionaries: this.dictionaries, ServiceService: this.ServiceService });
        }
    }, {
        key: 'moveUp',
        value: function moveUp(node, parent, path) {
            this.wrapNode(node, parent).moveUp(path().slice(1));
        }
    }, {
        key: 'moveBack',
        value: function moveBack(node, parent) {
            this.wrapNode(node, parent).moveBack();
        }
    }, {
        key: 'addExtra',
        value: function addExtra(node, parent) {
            var _this3 = this;

            return this.dialog.open(_extra2.default).then(function (dictionary) {
                if (!node.extras) {
                    node.extras = [];
                }
                _this3.wrapNode(node, parent).addExtra(dictionary.code);
            });
        }
    }, {
        key: 'addGroup',
        value: function addGroup(node, parent) {
            var group = this.wrapNode(node, parent).addGroup('Технологическая операция');
            this.expandedNodes.push(group.node);
            this.selected = group.node;
        }
    }, {
        key: 'addService',
        value: function addService(node, parent, data) {
            this.wrapNode(node, parent).addService(data.id, data.name, data);
            this.expandedNodes.push(parent);
        }
    }, {
        key: 'refresh',
        value: function refresh(node, parent) {
            this.wrapNode(node, parent).refresh();
        }
    }, {
        key: 'remove',
        value: function remove(node, parent) {
            var wrappedNode = this.wrapNode(node, parent);
            if (confirm('Вы действительно хотите удалить?')) {
                wrappedNode.remove();
            }
        }
    }]);

    return _class;
}();

exports.default = _class;