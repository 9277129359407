'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _test = require('./test.component');

var _test2 = _interopRequireDefault(_test);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.tests',
    url: '/test/',
    template: '<' + _test2.default.name + '/>',
    title: 'VALUE.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'VALUE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.TEST',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-cog'
    }
};