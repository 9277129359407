'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _radioGroup = require('./radio-group.pug');

var _radioGroup2 = _interopRequireDefault(_radioGroup);

var _radioGroup3 = require('./radio-group.controller');

var _radioGroup4 = _interopRequireDefault(_radioGroup3);

require('./radio-group.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _radioGroup2.default)({}),
    controller: _radioGroup4.default,
    name: 'nlc-radio-group',
    transclude: true,
    bindings: {
        service: '=',
        field: '@',
        request: '=',
        selected: '=?'
    },
    require: {
        ngModel: '^ngModel'
    },
    bindToController: true
};