'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _slPickup = require('./dialog/sl-pickup/sl-pickup.dialog');

var _slPickup2 = _interopRequireDefault(_slPickup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope, TranslateDialogService, DeliveryTypeService, CacheGeoCityService, CacheValueService) {
        'ngInject';

        var _this = this;

        _classCallCheck(this, _class);

        Object.assign(this, {
            DeliveryTypeService: DeliveryTypeService,
            CacheGeoCityService: CacheGeoCityService,
            CacheValueService: CacheValueService,
            calculating: false,
            price: 0,
            delivery_days: 0,
            uid: 'uid',
            dialog: TranslateDialogService,
            request: {
                data: {}
            }
        });
        $scope.$watch('$ctrl.deliveryType', function () {
            if (angular.isDefined(_this.deliveryType) && _this.deliveryType.provider === 'irm1-courier') {
                _this.calc();
            }
        });
    }

    _createClass(_class, [{
        key: 'slPickup',
        value: function slPickup() {
            var _this2 = this;

            return this.dialog.open(_slPickup2.default, _extends({}, this.request, this.deliveryType.data)).then(function (data) {
                _this2.request.pickup_place = data.code_id;
                _this2.request.data.pickup = {
                    name: decodeURIComponent(data.name),
                    address: decodeURIComponent(data.address),
                    phone: decodeURIComponent(data.phone),
                    proezd_info: decodeURIComponent(data.proezd_info),
                    worktime: decodeURIComponent(data.worktime)
                };
                _this2.price = parseFloat(data.price);
                _this2.delivery_days = data.srok_dostavki;
                _this2.onCalc({ price: _this2.price, delivery_days: _this2.delivery_days });
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            this.controller = this;
        }
    }, {
        key: 'calc',
        value: function calc() {
            var _this3 = this;

            this.calculating = true;
            return this.promise = this.DeliveryTypeService.price(this.deliveryType.id, this.request).then(function (_ref) {
                var price = _ref.price,
                    delivery_days = _ref.delivery_days;
                return Object.assign(_this3, { price: price, delivery_days: delivery_days });
            }).then(function () {
                return _this3.onCalc({ price: _this3.price, delivery_days: _this3.delivery_days });
            }).finally(function () {
                return _this3.calculating = false;
            });
        }
    }]);

    return _class;
}();

exports.default = _class;