'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _menu = require('./menu.state');

var _menu2 = _interopRequireDefault(_menu);

var _menu3 = require('./menu.component');

var _menu4 = _interopRequireDefault(_menu3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = 'product';
exports.default = _angular2.default.module('app.pages.content-menu', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_menu2.default.name, _menu2.default);
}).component(_menu4.default.name.camelCase(), _menu4.default);