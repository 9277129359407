'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

require('./abn-tree.directive.js');

require('./abn-tree.css');

exports.default = angular.module('app.modules.tree', ['angularBootstrapNavTree']);