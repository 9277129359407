'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $scope: $scope });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            this.controller = this;
            if (_angular2.default.isUndefined(this.valueField)) {
                this.valueField = 'id';
            }
            if (_angular2.default.isUndefined(this.field) || this.field == 'id') {
                this.field = 'name';
            }
            this.ngModel.$render = function () {
                if (_angular2.default.isUndefined(_this.selected) || _this.selected.id != _this.ngModel.$viewValue) Object.assign(_this, { selected: { id: _this.ngModel.$viewValue } });
            };
            this.$scope.$watch(function () {
                return _this.selected;
            }, function (value) {
                return _this.ngModel.$setViewValue(value ? value.id : null);
            });
            this.$scope.$watch(function () {
                return _this.request;
            }, function (value, old) {
                if (!_angular2.default.equals(value, old)) {
                    _this.refresh();
                }
            });
            this.refresh();
        }
    }, {
        key: 'refresh',
        value: function refresh() {
            var _this2 = this;

            return this.promise = this.service.data(Object.assign({}, this.request || {}, { limit: 9999 })).then(function (data) {
                return Object.assign(_this2, { data: _lodash2.default.uniqBy(data, _this2.valueField).map(function (row) {
                        return _extends({}, row, { id: _lodash2.default.get(row, _this2.valueField), name: _lodash2.default.get(row, _this2.field) });
                    }) });
            }).then(function () {
                if (_angular2.default.isDefined(_this2.initNumber) && _angular2.default.isUndefined(_this2.selected.id)) {
                    _this2.selected = _this2.data[_this2.initNumber];
                } else if (_this2.ngModel.$viewValue && !_this2.noInit) {
                    _this2.selected = _this2.data.filter(function (row) {
                        return row[_this2.valueField] == _this2.ngModel.$viewValue;
                    })[0];
                }
            });
        }
    }]);

    return _class;
}();

exports.default = _class;