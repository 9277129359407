'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _datetimeRange = require('./datetime-range.pug');

var _datetimeRange2 = _interopRequireDefault(_datetimeRange);

var _datetimeRange3 = require('./datetime-range.controller');

var _datetimeRange4 = _interopRequireDefault(_datetimeRange3);

require('./datetime-range.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _datetimeRange2.default)({}),
    controller: _datetimeRange4.default,
    name: 'nlc-datetime-range',
    bindings: {
        presets: '<'
    },
    require: {
        ngModel: '^ngModel'
    },
    bindToController: true
};