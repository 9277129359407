'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _saleDialog = require('./sale.dialog.pug');

var _saleDialog2 = _interopRequireDefault(_saleDialog);

var _saleDialog3 = require('./sale.dialog.controller');

var _saleDialog4 = _interopRequireDefault(_saleDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _saleDialog2.default)({}),
    controller: _saleDialog4.default,
    name: 'nlc-order-sale-dialog'
};