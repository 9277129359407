'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(CacheDictionaryService, ServiceService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { CacheDictionaryService: CacheDictionaryService, ServiceService: ServiceService, dictionaries: { 1: {}, 2: {}, 3: {} } });
        this.classes = {
            condition: ['font-blue-dark'],
            source: ['font-green-sharp'],
            outputs: ['font-purple-plum'],
            output: ['font-grey-silver']
        };
    }

    _createClass(_class, [{
        key: 'getInputsBranch',
        value: function getInputsBranch(type, label) {
            var _this = this;

            return {
                label: label,
                type: type,
                children: _lodash2.default.sortBy(_lodash2.default.values(this.service.schema[type].inputs).map(function (input) {
                    return {
                        label: (_this.dictionaries[input.type][input.base] || { name: input.base }).name + ' (' + input.base + ')',
                        type: 'component',
                        dictionary: _this.dictionaries[2][input.base] || null,
                        input: input
                    };
                }), 'label')
            };
        }
    }, {
        key: 'getOutputsBranch',
        value: function getOutputsBranch(label) {
            var _this2 = this;

            return {
                label: label,
                type: 'outputs',
                children: _lodash2.default.sortBy(_lodash2.default.values(this.service.schema.outputs).map(function (output) {
                    return {
                        label: (_this2.dictionaries[1][output.base] || { name: output.base }).name + ' (' + output.base + ')',
                        type: 'output',
                        output: output
                    };
                }), 'label')
            };
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this3 = this;

            var types = {
                dictionary: 2,
                simple: 1,
                smart: 3
            };
            this.CacheDictionaryService.data({ limit: 999 }).then(function (data) {
                return data.forEach(function (row) {
                    return _this3.dictionaries[types[row.type]][row.code] = row;
                });
            }).then(function () {
                _this3.data = [_this3.getInputsBranch('condition', 'Поля условия'), _this3.getInputsBranch('source', 'Поля калькулятора'), _this3.getOutputsBranch('Дополнения')];
            });
        }
    }, {
        key: 'onSelect',
        value: function onSelect(branch) {
            this.selected = branch;
        }
    }, {
        key: 'submit',
        value: function submit() {
            this.promise = this.ServiceService.update(this.service.id, this.service);
        }
    }]);

    return _class;
}();

exports.default = _class;