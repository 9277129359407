'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, [{
        key: 'wish_readiness_date',
        value: function wish_readiness_date(_ref) {
            var _wish_readiness_date = _ref.wish_readiness_date,
                status = _ref.status;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            if (status.is_final || _wish_readiness_date == null) {
                return '';
            }
            var days = (0, _moment2.default)(_wish_readiness_date).diff(new Date(), 'days');
            if (days <= 0) {
                return prefix + 'danger';
            }
            if (days <= 2) {
                return prefix + 'warning';
            }
            if (days <= 3) {
                return prefix + 'info';
            }
            return prefix + 'default';
        }
    }]);

    return _class;
}();

exports.default = _class;