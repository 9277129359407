'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _legalEntities = require('./legal-entities.pug');

var _legalEntities2 = _interopRequireDefault(_legalEntities);

var _legalEntities3 = require('./legal-entities.controller');

var _legalEntities4 = _interopRequireDefault(_legalEntities3);

require('./legal-entities.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _legalEntities2.default)({}),
    controller: _legalEntities4.default,
    name: 'nlc-legal-entities',
    bindings: {
        controller: '=',
        client: '<'
    },
    bindToController: true
};