'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _blocks = require('./blocks/blocks.component');

var _blocks2 = _interopRequireDefault(_blocks);

var _pages = require('./pages/pages.component');

var _pages2 = _interopRequireDefault(_pages);

var _menus = require('./menus/menus.component');

var _menus2 = _interopRequireDefault(_menus);

var _menuItems = require('./menu-items/menu-items.component');

var _menuItems2 = _interopRequireDefault(_menuItems);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.components.content', []).component(_blocks2.default.name.camelCase(), _blocks2.default).component(_pages2.default.name.camelCase(), _pages2.default).component(_menus2.default.name.camelCase(), _menus2.default).component(_menuItems2.default.name.camelCase(), _menuItems2.default);