'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _transaction = require('./transaction.pug');

var _transaction2 = _interopRequireDefault(_transaction);

var _transaction3 = require('./transaction.controller');

var _transaction4 = _interopRequireDefault(_transaction3);

require('./transaction.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _transaction2.default)({}),
    controller: _transaction4.default,
    name: 'nlc-transactions-page'
};