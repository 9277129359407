"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(service) {
        var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
        var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 40;

        _classCallCheck(this, _class);

        Object.assign(this, { service: service, request: { page: page, limit: limit }, from: 0, to: 0, total: 0, totalPages: 0, currentPage: 0 });
    }

    _createClass(_class, [{
        key: "reset",
        value: function reset() {
            Object.assign(this, { from: 0, to: 0, total: 0, totalPages: 0, currentPage: 0, data: [] });
        }
    }, {
        key: "$onInit",
        value: function $onInit() {
            return this.refresh();
        }
    }, {
        key: "refresh",
        value: function refresh() {
            var _this = this;

            if (this.loading) {
                return Promise.resolve(this);
            }
            this.loading = true;
            return this.promise = this.service.paginate(this.request).then(function (response) {
                return Object.assign(_this, response);
            }).finally(function () {
                return _this.loading = false;
            });
        }
    }]);

    return _class;
}();

exports.default = _class;