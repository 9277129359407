'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _offices = require('./offices.pug');

var _offices2 = _interopRequireDefault(_offices);

var _offices3 = require('./offices.controller');

var _offices4 = _interopRequireDefault(_offices3);

require('./offices.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _offices2.default)({}),
    controller: _offices4.default,
    name: 'nlc-offices',
    bindings: {
        controller: '='
    },
    bindToController: true
};