'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _client = require('./client.state');

var _client2 = _interopRequireDefault(_client);

var _client3 = require('./client.component');

var _client4 = _interopRequireDefault(_client3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.client', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_client2.default.name, _client2.default);
}).component(_client4.default.name.camelCase(), _client4.default);