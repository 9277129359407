'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _deliveryTypeCalc = require('./delivery-type-calc.pug');

var _deliveryTypeCalc2 = _interopRequireDefault(_deliveryTypeCalc);

var _deliveryTypeCalc3 = require('./delivery-type-calc.controller');

var _deliveryTypeCalc4 = _interopRequireDefault(_deliveryTypeCalc3);

require('./delivery-type-calc.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _deliveryTypeCalc2.default)({}),
    controller: _deliveryTypeCalc4.default,
    name: 'nlc-delivery-type-calc',
    bindings: {
        deliveryType: '=',
        controller: '=',
        onCalc: '&',
        request: '='
    },
    bindToController: true
};