'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

require('jquery');

require('font-awesome/scss/font-awesome.scss');

require('simple-line-icons/scss/simple-line-icons.scss');

require('bootstrap');

require('assets/sass/bootstrap.scss');

require('assets/sass/global/components.scss');

require('assets/sass/layouts/layout/layout.scss');

require('assets/sass/layouts/layout/themes/default.scss');

require('assets/sass/layouts/layout/custom.scss');

require('textAngular/dist/textAngular.css');

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _modal = require('angular-ui-bootstrap/src/modal');

var _modal2 = _interopRequireDefault(_modal);

var _pagination = require('angular-ui-bootstrap/src/pagination');

var _pagination2 = _interopRequireDefault(_pagination);

var _tabs = require('angular-ui-bootstrap/src/tabs');

var _tabs2 = _interopRequireDefault(_tabs);

var _angularBreadcrumb = require('angular-breadcrumb');

var _angularBreadcrumb2 = _interopRequireDefault(_angularBreadcrumb);

var _angularUiRouterMenus = require('angular-ui-router-menus');

var _angularUiRouterMenus2 = _interopRequireDefault(_angularUiRouterMenus);

var _angularLocalStorage = require('angular-local-storage');

var _angularLocalStorage2 = _interopRequireDefault(_angularLocalStorage);

var _angularPermission = require('angular-permission');

var _angularAnimate = require('angular-animate');

var _angularAnimate2 = _interopRequireDefault(_angularAnimate);

var _angularSanitize = require('angular-sanitize');

var _angularSanitize2 = _interopRequireDefault(_angularSanitize);

var _angularTranslate = require('angular-translate');

var _angularTranslate2 = _interopRequireDefault(_angularTranslate);

var _angularMessages = require('angular-messages');

var _angularMessages2 = _interopRequireDefault(_angularMessages);

var _angularCookies = require('angular-cookies');

var _angularCookies2 = _interopRequireDefault(_angularCookies);

var _angularTranslateStorageCookie = require('angular-translate-storage-cookie');

var _angularTranslateStorageCookie2 = _interopRequireDefault(_angularTranslateStorageCookie);

var _angularTranslateStorageLocal = require('angular-translate-storage-local');

var _angularTranslateStorageLocal2 = _interopRequireDefault(_angularTranslateStorageLocal);

var _angularTranslateLoaderPartial = require('angular-translate-loader-partial');

var _angularTranslateLoaderPartial2 = _interopRequireDefault(_angularTranslateLoaderPartial);

var _angularTranslateInterpolationMessageformat = require('angular-translate-interpolation-messageformat');

var _angularTranslateInterpolationMessageformat2 = _interopRequireDefault(_angularTranslateInterpolationMessageformat);

var _angularTranslateHandlerLog = require('angular-translate-handler-log');

var _angularTranslateHandlerLog2 = _interopRequireDefault(_angularTranslateHandlerLog);

var _ngFileUpload = require('ng-file-upload');

var _ngFileUpload2 = _interopRequireDefault(_ngFileUpload);

var _textangular = require('textangular');

var _textangular2 = _interopRequireDefault(_textangular);

var _angularLoadingBar = require('angular-loading-bar');

var _angularLoadingBar2 = _interopRequireDefault(_angularLoadingBar);

require('angular-loading-bar/src/loading-bar.css');

require('ladda/css/ladda-themed.scss');

var _angularLadda = require('angular-ladda');

var _angularLadda2 = _interopRequireDefault(_angularLadda);

require('helpers/common.helpers');

var _angularTimeago = require('angular-timeago');

var _angularTimeago2 = _interopRequireDefault(_angularTimeago);

var _filters = require('./filters');

var _filters2 = _interopRequireDefault(_filters);

var _choices = require('./choices');

var _choices2 = _interopRequireDefault(_choices);

var _config = require('./config/config');

var _config2 = _interopRequireDefault(_config);

var _policies = require('./policies/policies');

var _policies2 = _interopRequireDefault(_policies);

var _services = require('./services/services');

var _services2 = _interopRequireDefault(_services);

var _providers = require('./providers/providers');

var _providers2 = _interopRequireDefault(_providers);

var _directives = require('./directives/directives');

var _directives2 = _interopRequireDefault(_directives);

var _factories = require('./factories/factories');

var _factories2 = _interopRequireDefault(_factories);

var _modules = require('./modules/modules');

var _modules2 = _interopRequireDefault(_modules);

var _common = require('./common/common');

var _common2 = _interopRequireDefault(_common);

var _pages = require('./pages/pages');

var _pages2 = _interopRequireDefault(_pages);

var _angularTreeControl = require('angular-tree-control');

var _angularTreeControl2 = _interopRequireDefault(_angularTreeControl);

require('angular-tree-control/css/tree-control.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_angular2.default.lowercase = function (text) {
    return text ? text.toLowerCase() : undefined;
};
//awesome loadings

// Import attache all styles


require('textangular/dist/textAngular-sanitize.min');
_angular2.default.module("contextMenu", []);

exports.default = _angular2.default.module('App', ['treeControl', _angularLocalStorage2.default, _angularUiRouter2.default, 'ncy-angular-breadcrumb', 'ui.router.menus', 'yaru22.angular-timeago', _angularSanitize2.default, _textangular2.default, _angularTranslate2.default, _angularTranslateLoaderPartial2.default, _angularCookies2.default, _angularTranslateStorageCookie2.default, _angularTranslateStorageLocal2.default, _angularTranslateInterpolationMessageformat2.default, _angularTranslateHandlerLog2.default, _angularLadda2.default, _angularMessages2.default, _angularAnimate2.default, _ngFileUpload2.default, _angularLoadingBar2.default, _angularPermission.permission, _angularPermission.uiPermission, _modal2.default, _pagination2.default, _tabs2.default, _filters2.default.name, _providers2.default.name, _factories2.default.name, _modules2.default.name, _config2.default.name, _choices2.default.name, _policies2.default.name, _services2.default.name, _directives2.default.name, _common2.default.name, _pages2.default.name]).run(function ($q, $urlRouter, $httpParamSerializerJQLike, $translate, $http, PermRoleStore, AuthService, $rootScope, MenuService, TranslationFactory, LayoutService, BootService, EventService, DirectionService, $state) {
    'ngInject';

    $http.defaults.paramSerializer = $httpParamSerializerJQLike;

    PermRoleStore.defineManyRoles({
        AUTHORIZED: function AUTHORIZED(roleName, stateTranstitionParams) {
            return AuthService.isAuthorized;
        },
        ANONYMOUS: function ANONYMOUS(roleName, stateTranstitionParams) {
            return !AuthService.isAuthorized;
        }
    });

    var unbinds = [LayoutService.init(), MenuService.init(), DirectionService.init()];
    //TODO: run it after:
    // 1. common translates loaded
    // 2. Boot service loaded data
    $q.all([
    //wait untill refresh will load default translations
    TranslationFactory.load(require.context("i18n", false, /^\.\/.*\.json$/))]).then(function () {
        // Once permissions are set-up
        // // kick-off router and start the application rendering
        $urlRouter.sync();
        // // Also enable router to listen to url changes
        $urlRouter.listen();
        //fire app init event
        if (!AuthService.isAuthorized) {
            EventService.fire('app.init');
        }
        $translate.refresh();
    });
    BootService.out.filter(function (b) {
        return b;
    }).subscribe(function () {
        return EventService.fire('app.init');
    });

    //destroy all events attached in the this section
    $rootScope.$on('$destroy', function () {
        return unbinds.forEach(function (unbind) {
            return unbind();
        });
    });
});