'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dictionary = require('./dictionary.component');

var _dictionary2 = _interopRequireDefault(_dictionary);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.dictionary',
    url: '/dictionaries/',
    template: '<' + _dictionary2.default.name + '/>',
    title: 'DICTIONARY.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'DICTIONARY.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.DICTIONARY',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-map'
    }
};