'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class($scope, ValueService, ValuePolicy, ValueChoice) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, ValueService));

        Object.assign(_this, { $scope: $scope, policy: ValuePolicy, choice: ValueChoice });
        return _this;
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            this.oldDictionary = this.dictionary;
            if (!this.checks) {
                this.checks = [];
            }
            this.request.dictionary_id = this.dictionary.id;
            this.columns = _lodash2.default.sortBy(this.dictionary.schema, ['index', 'name'], ['asc', 'asc']);
            _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this);
        }
    }, {
        key: '$doCheck',
        value: function $doCheck() {
            if (this.dictionary != undefined && this.oldDictionary.id != this.dictionary.id) {
                this.reset();
                this.$onInit();
            }
        }
    }, {
        key: 'onDefault',
        value: function onDefault(value) {
            this.default = value.id;
            if (!this.isChecked(value)) {
                this.onCheck(value);
            }
        }
    }, {
        key: 'onCheck',
        value: function onCheck(value) {
            var index = this.checks.indexOf(value.id);
            if (index !== -1) {
                this.checks.splice(index, 1);
            } else {
                this.checks.push(value.id);
            }
        }
    }, {
        key: 'isDefault',
        value: function isDefault(value) {
            return this.default == value.id;
        }
    }, {
        key: 'isChecked',
        value: function isChecked(value) {
            return this.checks.some(function (check) {
                return check == value.id;
            });
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;