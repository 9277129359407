'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calcs = require('./calcs.pug');

var _calcs2 = _interopRequireDefault(_calcs);

var _calcs3 = require('./calcs.controller');

var _calcs4 = _interopRequireDefault(_calcs3);

require('./calcs.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calcs2.default)({}),
    controller: _calcs4.default,
    name: 'nlc-calcs',
    bindings: {
        controller: '=',
        product: '<'
    },
    bindToController: true
};