'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AppController = function AppController(BootService, EventService) {
    'ngInject';

    var _this = this;

    _classCallCheck(this, AppController);

    Object.assign(this, { BootService: BootService, EventService: EventService });
    BootService.out.subscribe(function (booted) {
        return _this.booted = booted;
    });
    if (!this.booted) {
        this.EventService.fire('app.load');
    }
};

exports.default = AppController;