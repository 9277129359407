'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _orderDelivery = require('./order-delivery.state');

var _orderDelivery2 = _interopRequireDefault(_orderDelivery);

var _orderDelivery3 = require('./order-delivery.component');

var _orderDelivery4 = _interopRequireDefault(_orderDelivery3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.order-delivery', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_orderDelivery2.default.name, _orderDelivery2.default);
}).component(_orderDelivery4.default.name.camelCase(), _orderDelivery4.default);