'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _page = require('./page.pug');

var _page2 = _interopRequireDefault(_page);

require('./page.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _page2.default)({}),
    name: 'nlc-content-pages-page'
};