'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _constants = require('./constants.pug');

var _constants2 = _interopRequireDefault(_constants);

var _constants3 = require('./constants.controller');

var _constants4 = _interopRequireDefault(_constants3);

require('./constants.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _constants2.default)({}),
    controller: _constants4.default,
    name: 'nlc-constants',
    bindings: {
        controller: '=',
        onSelect: '&'
    },
    bindToController: true
};