'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _page = require('./page.state');

var _page2 = _interopRequireDefault(_page);

var _page3 = require('./page.component');

var _page4 = _interopRequireDefault(_page3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.content-page', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_page2.default.name, _page2.default);
}).component(_page4.default.name.camelCase(), _page4.default);