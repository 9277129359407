'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _app = require('./app.component');

var _app2 = _interopRequireDefault(_app);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppState = {
    abstract: true,
    template: '<' + _app2.default.name + '/>',
    resolve: {
        translations: /*@ngInject*/function translations(TranslationFactory) {
            return TranslationFactory.load(require.context("./i18n", false, /^\.\/.*\.json$/));
        }
    },
    ncyBreadcrumb: {
        label: 'APP.BREADCRUMB'
    },
    layout: {
        contentWhite: true,
        class: ''
    }
};
exports.default = AppState;