'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _users = require('./users.pug');

var _users2 = _interopRequireDefault(_users);

var _users3 = require('./users.controller');

var _users4 = _interopRequireDefault(_users3);

require('./users.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _users2.default)({}),
    controller: _users4.default,
    name: 'nlc-users',
    bindings: {
        controller: '='
    },
    bindToController: true
};