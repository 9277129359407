'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope, $state, TranslateDialogService, OrderItemService, CacheDeliveryTypeService, OrderItemPolicy) {
        'ngInject';

        var _this = this;

        _classCallCheck(this, _class);

        Object.assign(this, {
            $scope: $scope,
            $state: $state,
            OrderItemService: OrderItemService,
            CacheDeliveryTypeService: CacheDeliveryTypeService,
            dialog: TranslateDialogService,
            policy: OrderItemPolicy
        });

        $scope.$watch('$ctrl.request.items', function () {
            return _this.onItemsChange();
        }, true);
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            if (_angular2.default.isUndefined(this.request)) {
                this.request = {};
            }
            if (!this.request.payer) {
                this.request.payer = 'client';
            }
            if (_angular2.default.isUndefined(this.request.items)) {
                this.request.items = [];
            }
            Object.assign(this.request, {
                price: 0,
                delivery_days: '',
                items: [],
                inputs: {
                    weight: 0,
                    price: 0
                }
            });
            // delete this.request.data
            this.$scope.$watch('$ctrl.request.price', function () {
                if (_angular2.default.isUndefined(_this2.request)) {
                    return;
                }
                _this2.request.total_price = Math.round(_this2.request.price * 100) / 100;
            });
            this.$scope.$watch('$ctrl.request.total_price', function () {
                if (_angular2.default.isUndefined(_this2.request)) {
                    return;
                }
                var diff = _this2.request.total_price - _this2.request.price;

                _this2.request.discount_price = Math.round(Math.max(0, -diff) * 100) / 100;
                _this2.request.additional_price = Math.round(Math.max(0, diff) * 100) / 100;
                _this2.onCalc();
            });
        }
    }, {
        key: 'onItemsChange',
        value: function onItemsChange() {
            if (this.request.items.length) {
                this.request.inputs.weight = this.request.items.map(function (_ref) {
                    var results = _ref.results;
                    return _.get(results, 'default.results.data.$weight', 0);
                }).reduce(function (a, b) {
                    return a + b;
                });
                this.request.inputs.price = this.request.items.map(function (_ref2) {
                    var total_price = _ref2.total_price;
                    return total_price;
                }).reduce(function (a, b) {
                    return a + b;
                });
            } else {
                this.request.inputs.price = 0;
                this.request.inputs.weight = 500;
            }
            if (this.request.inputs.weight <= 0) {
                this.request.inputs.weight = 500;
            }
        }
    }]);

    return _class;
}();

exports.default = _class;