'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dialog = require('modules/dialog/controllers/dialog.controller');

var _dialog2 = _interopRequireDefault(_dialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DialogController) {
    _inherits(_class, _DialogController);

    function _class($scope, TransactionService, invoice, ValueService, InvoiceService) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, $scope));

        _this.need_amount = Math.max(0, invoice.amount - invoice.paid_amount);
        Object.assign(_this, { TransactionService: TransactionService, invoice: invoice, ValueService: ValueService });
        _this.request = {
            type: invoice.order_id != null ? 'order' : 'debit',
            order_id: _angular2.default.isDefined(invoice.order_id) ? invoice.order_id : null,
            amount: _this.need_amount,
            paid_at: new Date(),
            invoice_id: invoice.id
        };
        return _this;
    }

    _createClass(_class, [{
        key: 'submit',
        value: function submit() {
            var _this2 = this;

            this.promise = this.TransactionService.store(this.request).then(function (transaction) {
                return _this2.resolve(transaction);
            });
        }
    }]);

    return _class;
}(_dialog2.default);

exports.default = _class;