'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _menu = require('./menu.pug');

var _menu2 = _interopRequireDefault(_menu);

require('./menu.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _menu2.default)({}),
    name: 'nlc-content-menus-page'
};