'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _checkboxGroup = require('./checkbox-group.pug');

var _checkboxGroup2 = _interopRequireDefault(_checkboxGroup);

var _checkboxGroup3 = require('./checkbox-group.controller');

var _checkboxGroup4 = _interopRequireDefault(_checkboxGroup3);

require('./checkbox-group.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _checkboxGroup2.default)({}),
    controller: _checkboxGroup4.default,
    name: 'nlc-checkbox-group',
    transclude: true,
    bindings: {
        service: '=',
        format: '@',
        request: '=',
        selected: '=?'
    },
    require: {
        ngModel: '^ngModel'
    },
    bindToController: true
};