'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

require('./test.scss');

var _test = require('./test.state');

var _test2 = _interopRequireDefault(_test);

var _test3 = require('./test.component');

var _test4 = _interopRequireDefault(_test3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.test', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_test2.default.name, _test2.default);
}).component(_test4.default.name.camelCase(), _test4.default);