'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _products = require('./products.pug');

var _products2 = _interopRequireDefault(_products);

var _products3 = require('./products.controller');

var _products4 = _interopRequireDefault(_products3);

require('./products.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _products2.default)({}),
    controller: _products4.default,
    name: 'nlc-products',
    bindings: {
        controller: '='
    },
    bindToController: true
};