'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _rx = require('rx');

var _rx2 = _interopRequireDefault(_rx);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(AuthService, IdentityService, $http, API_ENDPOINT) {
        'ngInject';

        var _this = this;

        _classCallCheck(this, _class);

        Object.assign(this, { IdentityService: IdentityService, $http: $http, API_ENDPOINT: API_ENDPOINT, out: new _rx2.default.ReplaySubject(1) });
        AuthService.out.subscribe(function (a) {
            return a ? _this.init() : _this.clear();
        });
    }

    _createClass(_class, [{
        key: 'clear',
        value: function clear() {
            this.IdentityService.user = null;
            this.out.onNext(false);
        }
    }, {
        key: 'init',
        value: function init() {
            var _this2 = this;

            this.$http.get(this.API_ENDPOINT + 'boot/').then(function (_ref) {
                var data = _ref.data;
                return data;
            }).then(function (_ref2) {
                var user = _ref2.user;
                return _this2.IdentityService.user = user;
            }).then(function (user) {
                return _this2.out.onNext(true);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;