'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ConflictInterceptor = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.ConflictInterceptorWrapper = ConflictInterceptorWrapper;

var _interceptor = require('../interceptor');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ConflictInterceptor = exports.ConflictInterceptor = function (_Interceptor) {
    _inherits(ConflictInterceptor, _Interceptor);

    function ConflictInterceptor($q, $injector) {
        'ngInject';

        var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'unknown';

        _classCallCheck(this, ConflictInterceptor);

        var _this = _possibleConstructorReturn(this, (ConflictInterceptor.__proto__ || Object.getPrototypeOf(ConflictInterceptor)).call(this, $q, $injector));

        Object.assign(_this, { type: type });
        return _this;
    }

    _createClass(ConflictInterceptor, [{
        key: 'condition',
        value: function condition(response) {
            return 409 == response.status && this.type == response.data.type;
        }
    }, {
        key: 'responseError',
        value: function responseError(response) {
            var _this2 = this;

            var deferred = this.$q.defer();
            if (this.condition(response)) {
                this.action(response).then(function () {
                    return _this2.$injector.get('$http')(response.config).then(function (response) {
                        return deferred.resolve(response);
                    }, function (response) {
                        return deferred.reject(response);
                    });
                }, function () {
                    return _this2.cancel(response).finally(function () {
                        return deferred.reject(response);
                    });
                });
            } else {
                deferred.reject(response);
            }

            return deferred.promise;
        }
    }, {
        key: 'action',
        value: function action(response) {
            return undefined; //promise
        }
    }, {
        key: 'cancel',
        value: function cancel(response) {}
    }]);

    return ConflictInterceptor;
}(_interceptor.Interceptor);

function ConflictInterceptorWrapper(ConflictInterceptorClassRef) {
    return (0, _interceptor.InterceptorWrapper)(ConflictInterceptorClassRef, { responseError: true });
}