'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _appHeader = require('./app-header.pug');

var _appHeader2 = _interopRequireDefault(_appHeader);

var _appHeader3 = require('./app-header.controller');

var _appHeader4 = _interopRequireDefault(_appHeader3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppHeaderComponent = {
    template: (0, _appHeader2.default)({}),
    controller: _appHeader4.default,
    name: 'app-header'
};

exports.default = AppHeaderComponent;