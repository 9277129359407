'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _service = require('./service.component');

var _service2 = _interopRequireDefault(_service);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.service',
    url: '/services/',
    template: '<' + _service2.default.name + '/>',
    title: 'SERVICE.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'SERVICE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.SERVICE',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-clone'
    }
};