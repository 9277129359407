'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _sdescriptionDialog = require('./sdescription.dialog.pug');

var _sdescriptionDialog2 = _interopRequireDefault(_sdescriptionDialog);

var _sdescriptionDialog3 = require('./sdescription.dialog.controller');

var _sdescriptionDialog4 = _interopRequireDefault(_sdescriptionDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _sdescriptionDialog2.default)({}),
    controller: _sdescriptionDialog4.default,
    name: 'nlc-order-system_description-dialog'
};