'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($state, OrderService, OrderPolicy, CacheValueService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            $state: $state,
            policy: OrderPolicy,
            loading: false,
            OrderService: OrderService,
            CacheValueService: CacheValueService
        });
    }

    _createClass(_class, [{
        key: 'refresh',
        value: function refresh() {
            var _this = this;

            if (this.loading) {
                return Promise.resolve(this);
            }
            this.loading = true;
            return this.promise = this.OrderService.statistic(this.request).then(function (data) {
                return _this.data = data;
            }).finally(function () {
                return _this.loading = false;
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            this.CacheValueService.data({ limit: 999, dictionary_id: 'account', sort: 'name' }).then(function (accounts) {
                return _this2.accounts = accounts;
            });
            this.CacheValueService.data({ limit: 999, dictionary_id: 'production', sort: 'name' }).then(function (productions) {
                return _this2.productions = productions;
            });

            this.controller = this;
        }
    }]);

    return _class;
}();

exports.default = _class;