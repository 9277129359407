'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _interceptor = require('../interceptor');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AuthInterceptor = function (_Interceptor) {
    _inherits(AuthInterceptor, _Interceptor);

    function AuthInterceptor($q, $injector, $rootScope) {
        'ngInject';

        _classCallCheck(this, AuthInterceptor);

        return _possibleConstructorReturn(this, (AuthInterceptor.__proto__ || Object.getPrototypeOf(AuthInterceptor)).call(this, $q, $injector, $rootScope));
    }

    _createClass(AuthInterceptor, [{
        key: 'condition',
        value: function condition(response) {
            return 401 == response.status;
        }
    }, {
        key: 'action',
        value: function action(response) {
            this.$injector.get('AuthService').release();
            this.$injector.get('$state').go(this.$injector.get('AUTHENTICATION_STATE'));
        }
    }, {
        key: 'request',
        value: function request(config) {
            var authService = this.$injector.get('AuthService');
            if (authService.isAuthorized) {
                config.headers['Authorization'] = 'Bearer ' + authService.token;
            }
            return config;
        }
    }]);

    return AuthInterceptor;
}(_interceptor.Interceptor);

exports.default = (0, _interceptor.InterceptorWrapper)(AuthInterceptor, { responseError: true, request: true });