'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _error = require('./factories/error.interceptor');

var _error2 = _interopRequireDefault(_error);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('app.modules.error', []).factory('ErrorInterceptor', _error2.default);