'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _status = require('./dialog/status/status.dialog');

var _status2 = _interopRequireDefault(_status);

var _sale = require('./dialog/sale/sale.dialog');

var _sale2 = _interopRequireDefault(_sale);

var _payment_status = require('./dialog/payment_status/payment_status.dialog');

var _payment_status2 = _interopRequireDefault(_payment_status);

var _update = require('../clients/dialog/update/update.dialog');

var _update2 = _interopRequireDefault(_update);

var _transaction = require('./dialog/transaction/transaction.dialog');

var _transaction2 = _interopRequireDefault(_transaction);

var _store = require('../invoices/dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

var _report2 = require('../../common/dialog/report/report.dialog');

var _report3 = _interopRequireDefault(_report2);

var _store3 = require('../order/items/dialog/store/store.dialog');

var _store4 = _interopRequireDefault(_store3);

var _sdescription = require('./dialog/sdescription/sdescription.dialog');

var _sdescription2 = _interopRequireDefault(_sdescription);

var _handlebars = require('handlebars');

var _handlebars2 = _interopRequireDefault(_handlebars);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Order controller
 */


var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class($state, TranslateDialogService, CacheReportService, OrderService, OrderPolicy, OrderChoice, ClientChoice, ValueService) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, OrderService));

        Object.assign(_this.request, {
            type: 'order',
            withs: ['client', 'status', 'manager', 'sale']
        });
        Object.assign(_this, {
            timer: null,
            $state: $state,
            dialog: TranslateDialogService,
            policy: OrderPolicy,
            choices: {
                order: OrderChoice,
                client: ClientChoice
            },
            ValueService: ValueService,
            CacheReportService: CacheReportService
        });
        return _this;
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            if (_angular2.default.isDefined(this.client)) {
                this.request.client_id = this.client.id;
            }
            if (_angular2.default.isDefined(this.id)) {
                this.request.id = this.id;
            }
            if (_angular2.default.isDefined(this.type)) {
                this.request.type = this.type;
            }
            if (_angular2.default.isDefined(this.invoice)) {
                this.request.invoice = { id: this.invoice.id };
            }
            this.controller = this;
            _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this);
            this.timer = setInterval(function () {
                return _this2.refresh();
            }, 600000);
        }
    }, {
        key: '$onDestroy',
        value: function $onDestroy() {
            clearInterval(this.timer);
        }
    }, {
        key: 'replicate',
        value: function replicate(order) {
            var _this3 = this;

            return this.service.replicate(order.id, order).then(function () {
                return _this3.refresh();
            });
        }
        /**
         * 
         * @param {*} order 
         */

    }, {
        key: 'uclient',
        value: function uclient(order) {
            return this.dialog.open(_update2.default, { client: order.client }).then(function (client) {
                return Object.assign(order.client, client);
            });
        }
        /**
         * 
         * @param {*} report 
         * @param {*} order 
         */

    }, {
        key: 'report',
        value: function report(_report, order) {
            var _this4 = this;

            return this.service.report(order.id, order).then(function (data) {
                return _this4.dialog.open(_report3.default, { report: _report, data: data });
            });
        }
        /**
         * 
         * @param {*} order 
         */

    }, {
        key: 'status',
        value: function status(order) {
            return this.dialog.open(_status2.default, { order: order }).then(function (updated) {
                return Object.assign(order, updated);
            });
        }
    }, {
        key: 'sale',
        value: function sale(order) {
            return this.dialog.open(_sale2.default, { order: order }).then(function (updated) {
                return Object.assign(order, updated);
            });
        }
    }, {
        key: 'payment_status',
        value: function payment_status(order) {
            return this.dialog.open(_payment_status2.default, { order: order }).then(function (updated) {
                return Object.assign(order, updated);
            });
        }
    }, {
        key: 'cinvoice',
        value: function cinvoice(order) {
            return this.dialog.open(_store2.default, { order: order }).then(function (invoice) {
                return Object.assign(order, invoice.order || {});
            });
        }
    }, {
        key: 'item',
        value: function item(order) {
            var _this5 = this;

            return this.dialog.open(_store4.default, { order: order, type: 'product' }).then(function () {
                return _this5.refresh();
            });
        }
    }, {
        key: 'sdescription',
        value: function sdescription(order) {
            return this.dialog.open(_sdescription2.default, { order: order }).then(function (updatedOrder) {
                return Object.assign(order, updatedOrder);
            });
        }
    }, {
        key: 'delivery',
        value: function delivery(order) {
            var _this6 = this;

            return this.dialog.open(_store4.default, { order: order, type: 'delivery' }).then(function () {
                return _this6.refresh();
            });
        }
    }, {
        key: 'share',
        value: function share(order) {
            var _this7 = this;

            return this.dialog.confirm('COMPONENTS.ORDERS.GRID.ACTIONS.SHARE.TITLE', 'COMPONENTS.ORDERS.GRID.ACTIONS.SHARE.MESSAGE', {}, order).then(function () {
                return _this7.service.share(order.id, order);
            }).then(function (updated) {
                return Object.assign(order, updated);
            });
        }
        /**
         * 
         * @param {*} ext 
         */

    }, {
        key: 'export',
        value: function _export() {
            var ext = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'xlsx';

            return this.service.export(this.request, ext);
        }
    }, {
        key: 'transaction',
        value: function transaction(order) {
            var _this8 = this;

            return this.dialog.open(_transaction2.default, { order: order }).then(function () {
                return _this8.refresh();
            });
        }
    }, {
        key: 'view',
        value: function view(order) {
            var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'full';

            var oposite = {
                full: 'short',
                short: 'full'
            };
            order[type + '_show'] = !order[type + '_show'];
            if (order[type + '_show']) {
                order[oposite[type] + '_show'] = false;
            }
        }
    }, {
        key: 'store',
        value: function store() {
            return this.$state.go('app.order-create');
        }
    }, {
        key: 'update',
        value: function update(_ref /*order*/) {
            var id = _ref.id;

            return this.$state.go('app.order-update', { id: id });
        }
    }, {
        key: 'destroy',
        value: function destroy(order) {
            var _this9 = this;

            return this.dialog.confirm('COMPONENTS.ORDERS.GRID.ACTIONS.DESTROY.TITLE', 'COMPONENTS.ORDERS.GRID.ACTIONS.DESTROY.MESSAGE', {}, order /*interpolation params*/).then(function () {
                return _this9.service.destroy(order.id, order);
            }).then(function () {
                return _this9.refresh();
            });
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;