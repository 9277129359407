'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _transferDialog = require('./transfer.dialog.pug');

var _transferDialog2 = _interopRequireDefault(_transferDialog);

var _storeDialog = require('../store.dialog.controller');

var _storeDialog2 = _interopRequireDefault(_storeDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _transferDialog2.default)({}),
    controller: _storeDialog2.default,
    resolve: {
        type: function type() {
            return 'transfer';
        }
    },
    name: 'nlc-transactions-transfer-dialog'
};