'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _splashScreen = require('./splash-screen/splash-screen.component');

var _splashScreen2 = _interopRequireDefault(_splashScreen);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.common.components', []).component(_splashScreen2.default.name.camelCase(), _splashScreen2.default);