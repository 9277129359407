'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dictionary = require('./dictionary.state');

var _dictionary2 = _interopRequireDefault(_dictionary);

var _dictionary3 = require('./dictionary.component');

var _dictionary4 = _interopRequireDefault(_dictionary3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = 'product';
exports.default = _angular2.default.module('app.pages.dictionary', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_dictionary2.default.name, _dictionary2.default);
}).component(_dictionary4.default.name.camelCase(), _dictionary4.default);