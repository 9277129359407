'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.InterceptorWrapper = InterceptorWrapper;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Interceptor = function () {
    function Interceptor($q, $injector) {
        _classCallCheck(this, Interceptor);

        Object.assign(this, { $q: $q, $injector: $injector });
    }

    _createClass(Interceptor, [{
        key: 'condition',
        value: function condition(response) {
            throw new Error('Need to override the condition method');
        }
    }, {
        key: 'action',
        value: function action(response) {
            throw new Error('Need to override the proccess method');
        }
    }, {
        key: 'request',
        value: function request(config) {
            return config;
        }
    }, {
        key: 'response',
        value: function response(_response) {
            var deferred = this.$q.defer();
            if (this.condition(_response)) {
                this.action(_response);
                deferred.reject(_response);
            } else {
                deferred.resolve(_response);
            }
            return deferred.promise;
        }
    }, {
        key: 'responseError',
        value: function responseError(rejection) {
            if (this.condition(rejection)) {
                this.action(rejection);
            }
            return this.$q.reject(rejection);
        }
    }]);

    return Interceptor;
}();

exports.Interceptor = Interceptor;
function InterceptorWrapper(InterceptorClassRef, abilities) {
    return function ($q, $injector) {
        'ngInject';

        var interceptor = new InterceptorClassRef($q, $injector);
        var result = {};

        var _loop = function _loop(pN) {
            if (abilities[pN]) {
                result[pN] = function (data) {
                    return interceptor[pN](data);
                };
            }
        };

        for (var pN in abilities) {
            _loop(pN);
        }
        return result;
    };
}