'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _office = require('./office.component');

var _office2 = _interopRequireDefault(_office);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.office',
    url: '/offices/',
    template: '<' + _office2.default.name + '/>',
    title: 'OFFICE.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'OFFICE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.OFFICE',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-home'
    }
};