'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _reset = require('./reset.pug');

var _reset2 = _interopRequireDefault(_reset);

var _reset3 = require('./reset.controller');

var _reset4 = _interopRequireDefault(_reset3);

require('./reset.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _reset2.default)({}),
    controller: _reset4.default,
    name: 'reset'
};