'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dictionaries = require('./dictionaries.pug');

var _dictionaries2 = _interopRequireDefault(_dictionaries);

var _dictionaries3 = require('./dictionaries.controller');

var _dictionaries4 = _interopRequireDefault(_dictionaries3);

require('./dictionaries.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _dictionaries2.default)({}),
    controller: _dictionaries4.default,
    name: 'nlc-dictionaries',
    bindings: {
        controller: '='
    },
    bindToControler: true
};