'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _order = require('./order.state');

var _order2 = _interopRequireDefault(_order);

var _order3 = require('./order.component');

var _order4 = _interopRequireDefault(_order3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.order', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_order2.default.name, _order2.default);
}).component(_order4.default.name.camelCase(), _order4.default);