'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope, $element, $interval, $timeout) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $scope: $scope, $interval: $interval, $timeout: $timeout, element: $element.find('div'), count: 0 });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            this.$scope.$watch('$ctrl.promise', function (newVal, oldVal) {
                if (_angular2.default.isUndefined(_this.promise)) return;
                _this.start();
                _this.promise.finally(function () {
                    return _this.complete();
                });
            });
        }
    }, {
        key: '_inc',
        value: function _inc() {
            if (this.count >= 1) {
                return;
            }

            var rnd = 0;
            if (this.count >= 0 && this.count < 0.25) {
                // Start out between 3 - 6% increments
                rnd = (Math.random() * (5 - 3 + 1) + 3) / 100;
            } else if (this.count >= 0.25 && this.count < 0.65) {
                // increment between 0 - 3%
                rnd = Math.random() * 3 / 100;
            } else if (this.count >= 0.65 && this.count < 0.9) {
                // increment between 0 - 2%
                rnd = Math.random() * 2 / 100;
            } else if (this.count >= 0.9 && this.count < 0.99) {
                // finally, increment it .5 %
                rnd = 0.005;
            } else {
                // after 99%, don't increment:
                rnd = 0;
            }
            this._set(this.count + rnd);
        }
    }, {
        key: '_set',
        value: function _set(count) {
            this.element.css('width', (this.count = count) * 100 + '%');
        }
    }, {
        key: 'start',
        value: function start() {
            var _this2 = this;

            this._set(0);
            this.$interval.cancel(this.intervalPromise);
            this.intervalPromise = this.$interval(function () {
                return _this2._inc();
            }, 400);
            this.show = true;
        }
    }, {
        key: 'complete',
        value: function complete() {
            var _this3 = this;

            this._set(1);
            this.$interval.cancel(this.intervalPromise);
            this.$timeout(function () {
                if (_this3.count == 1) _this3.show = false;
            }, 400);
        }
    }]);

    return _class;
}();

exports.default = _class;