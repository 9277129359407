'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _checks = require('./checks.pug');

var _checks2 = _interopRequireDefault(_checks);

var _checks3 = require('./checks.controller');

var _checks4 = _interopRequireDefault(_checks3);

require('./checks.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _checks2.default)({}),
    controller: _checks4.default,
    name: 'nlc-values-checks',
    bindings: {
        controller: '=',
        request: '=?',
        checks: '=',
        default: '=',
        dictionary: '='
    },
    bindToController: true
};