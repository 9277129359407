'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calculation = require('./calculation.component');

var _calculation2 = _interopRequireDefault(_calculation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.calculation',
    url: '/calculations/',
    template: '<' + _calculation2.default.name + '/>',
    title: 'CALCULATION.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'CALCULATION.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.CALCULATION',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-calculator'
    }
};