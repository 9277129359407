'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _value = require('./value.component');

var _value2 = _interopRequireDefault(_value);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.value',
    url: '/dictionaries/:id/values/',
    template: '<' + _value2.default.name + ' dictionary=\'dictionary\'/>',
    controller: /*@ngInject*/function controller($scope, dictionary) {
        return $scope.dictionary = dictionary;
    },
    title: 'VALUE.TITLE',
    resolve: {
        dictionary: /*@ngInject*/function dictionary($stateParams, DictionaryService) {
            return DictionaryService.get($stateParams.id);
        }
    },
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'VALUE.BREADCRUMB' // angular-breadcrumb's configuration
    }
};