'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slPickupDialog = require('./sl-pickup.dialog.pug');

var _slPickupDialog2 = _interopRequireDefault(_slPickupDialog);

var _slPickupDialog3 = require('./sl-pickup.dialog.controller');

var _slPickupDialog4 = _interopRequireDefault(_slPickupDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _slPickupDialog2.default)({}),
    controller: _slPickupDialog4.default,
    name: 'nlc-delivery-type-sl-calc-pickup-dialog',
    size: 'lg'
};