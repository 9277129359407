'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularCache = require('angular-cache/dist/angular-cache.min');

var _angularCache2 = _interopRequireDefault(_angularCache);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.modules.cache', [_angularCache2.default]).config(function (CacheFactoryProvider) {
    'ngInject';

    _angular2.default.extend(CacheFactoryProvider.defaults, {
        maxAge: 15 * 60 * 1000, // Items added to this cache expire after 15 minutes
        cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour
        deleteOnExpire: 'aggressive' // Items will be deleted from this cache when they expire
        // storageMode: 'localStorage', // This cache will use `localStorage`.
    });
}).run(function ($http, CacheFactory) {
    'ngInject';
});