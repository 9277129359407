'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _statuses = require('./statuses.pug');

var _statuses2 = _interopRequireDefault(_statuses);

var _statuses3 = require('./statuses.controller');

var _statuses4 = _interopRequireDefault(_statuses3);

require('./statuses.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _statuses2.default)({}),
    controller: _statuses4.default,
    name: 'nlc-statuses',
    bindings: {
        controller: '=',
        short: '@'
        // status: '=',
    },
    bindToController: true
};