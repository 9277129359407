'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _direction = require('./providers/direction.provider');

var _direction2 = _interopRequireDefault(_direction);

var _direction3 = require('./services/direction.service');

var _direction4 = _interopRequireDefault(_direction3);

var _nlDir = require('./directives/nl-dir.directive');

var _nlDir2 = _interopRequireDefault(_nlDir);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('app.modules.direction', []).provider('Direction', _direction2.default).service('DirectionService', _direction4.default).directive('nlDir', /*@ngInject*/function (DirectionService) {
    return new _nlDir2.default(DirectionService);
});