'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _assets = require('./assets.pug');

var _assets2 = _interopRequireDefault(_assets);

var _assets3 = require('./assets.controller');

var _assets4 = _interopRequireDefault(_assets3);

require('./assets.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _assets2.default)({}),
    controller: _assets4.default,
    name: 'nlc-assets',
    bindings: {
        controller: '=',
        assetable: '<',
        type: '@'
    },
    bindToController: true
};