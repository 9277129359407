'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.decode = exports.encode = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var encode = exports.encode = function encode(group) {
    var defaults = {
        production: {}
    };
    _lodash2.default.values(group.children).filter(function (child) {
        return child.type == 'service';
    }).filter(function (service) {
        return service.default_production_id;
    }).forEach(function (service) {
        defaults.production[service.id] = service.default_production_id;
    });
    var result = _extends({}, _lodash2.default.pick(group, 'id', 'name', 'code', 'index', 'type', 'required', 'action_id', 'has_name', 'has_comment', 'extras'), {
        services: _lodash2.default.values(group.children).filter(function (child) {
            return child.type == 'service';
        }).map(function (service) {
            return service.id;
        }),
        defaults: defaults,
        components: {},
        outputs: {},
        groups: {}
    });
    var children = _lodash2.default.values(group.children);
    children.filter(function (child) {
        return child.type == 'component';
    }).forEach(function (component) {
        return result.components[component.base] = component;
    });
    children.filter(function (child) {
        return child.type == 'output';
    }).forEach(function (output) {
        return result.outputs[output.base] = output;
    });
    children.filter(function (child) {
        return child.type == 'group';
    }).forEach(function (group) {
        return result.groups[group.id] = encode(group);
    });
    return result;
};
var decode = exports.decode = function decode(group, services, names) {
    if (group.defaults && group.defaults.production) {
        for (var id in group.defaults.production) {
            services[id].default_production_id = group.defaults.production[id];
        }
    }
    var result = _extends({}, _lodash2.default.pick(group, 'id', 'name', 'code', 'index', 'type', 'required', 'action_id', 'has_name', 'has_comment', 'extras'), {
        children: []
    });
    _lodash2.default.values(group.components).map(function (component) {
        return _extends({}, component, { name: names[component.base] || component.base });
    }).forEach(function (component) {
        return result.children.push(component);
    });
    _lodash2.default.values(group.outputs).map(function (output) {
        return _extends({}, output, { name: names[output.base] || output.base });
    }).forEach(function (output) {
        return result.children.push(output);
    });
    _lodash2.default.values(group.groups).forEach(function (group) {
        return result.children.push(decode(group, services, names));
    });
    _lodash2.default.values(group.services).forEach(function (id) {
        return result.children.push(services[id]);
    });
    return result;
};