'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _values = require('./values.pug');

var _values2 = _interopRequireDefault(_values);

var _values3 = require('./values.controller');

var _values4 = _interopRequireDefault(_values3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _values2.default)({}),
    controller: _values4.default,
    name: 'nlc-schema-input-values',
    bindings: {
        input: '=',
        dictionary: '<'
    },
    bindToController: true
};