'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dialog = require('modules/dialog/controllers/dialog.controller');

var _dialog2 = _interopRequireDefault(_dialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DialogController) {
    _inherits(_class, _DialogController);

    function _class($scope, $sce, weight, price, to_city, code) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, $scope));

        $scope.src = $sce.trustAsResourceUrl('//client-shop-logistics.ru/index.php?route=widget/widget&ref=' + encodeURIComponent(_this.getReference()) + ('&delivery_city=' + encodeURIComponent(to_city)) + ('&order_weight=' + Math.round(weight / 10) / 100) + '&order_length=0' + '&order_width=0' + '&order_height=0' + ('&order_price=' + price) + ('&code=' + encodeURIComponent(code)) + '&widget_type=pickup');
        return _this;
    }

    _createClass(_class, [{
        key: 'getReference',
        value: function getReference() {
            var ref = document.location.toString();
            var index = ref.indexOf('#');
            if (index > 0) {
                return ref.substr(0, index);
            }
            return ref;
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            document.location.hash = '#';
            this.timer = setInterval(function () {
                return _this2.checkHash();
            }, 50);
        }
    }, {
        key: '$onDestroy',
        value: function $onDestroy() {
            clearInterval(this.timer);
        }
    }, {
        key: 'checkHash',
        value: function checkHash() {
            var hash = document.location.hash.toString();
            if (hash.indexOf('ShopLogistics') === -1) {
                return;
            }
            var request = {};
            hash.split('&').forEach(function (param) {
                var _param$split = param.split('='),
                    _param$split2 = _slicedToArray(_param$split, 2),
                    key = _param$split2[0],
                    value = _param$split2[1];

                request[key] = value;
            });
            this.resolve(request);
        }
    }]);

    return _class;
}(_dialog2.default);

exports.default = _class;