'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _rest = require('./rest.policy');

var _rest2 = _interopRequireDefault(_rest);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_RestPolicy) {
    _inherits(_class, _RestPolicy);

    function _class(IdentityService) {
        'ngInject';

        _classCallCheck(this, _class);

        return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, IdentityService));
    }

    _createClass(_class, [{
        key: 'report',
        value: function report(order) {
            return this.isInRoles('admin', 'manager', 'designer', 'master', 'virtual', 'viewer') && order.type == 'order';
        }
    }, {
        key: 'store',
        value: function store() {
            return this.isInRoles('admin', 'manager', 'designer', 'virtual');
        }
    }, {
        key: 'update',
        value: function update(order) {
            return this.isInRoles('admin', 'manager', 'designer', 'virtual') && order.type == 'bid';
        }
    }, {
        key: 'destroy',
        value: function destroy(order) {
            return this.isAdmin || order.manager_id == this.user.id && order.status.is_default;
        }
    }, {
        key: 'view',
        value: function view(order) {
            return true;
        }
    }, {
        key: 'export',
        value: function _export() {
            return true;
        }
    }, {
        key: 'item',
        value: function item(order) {
            return !order.status.is_final && this.isInRoles('admin', 'manager', 'designer', 'virtual');
        }
    }, {
        key: 'delivery',
        value: function delivery(order) {
            return !order.status.is_final && this.isInRoles('admin', 'manager', 'designer', 'virtual');
        }
    }, {
        key: 'invoice',
        value: function invoice(order) {
            return order.payment_status !== 'paid' && this.isInRoles('admin', 'manager', 'designer', 'virtual') && order.type != 'bid';
        }
    }, {
        key: 'status',
        value: function status(order) {
            return this.isInRoles('admin', 'manager', 'virtual', 'master', 'deisgner') && order.type != 'bid';
        }
    }, {
        key: 'payment_status',
        value: function payment_status(order) {
            return !['partial', 'paid'].includes(order.payment_status) && this.isInRoles('admin', 'manager', 'virtual') && order.type != 'bid';
        }
    }, {
        key: 'sdescription',
        value: function sdescription(order) {
            return this.isInRoles('admin', 'manager', 'virtual');
        }
    }, {
        key: 'client',
        value: function client(order) {
            return this.isInRoles('admin', 'manager', 'designer', 'virtual') && (order.client.shared || order.client.sale_id == this.user.sale_id || this.isAdmin);
        }
    }, {
        key: 'transaction',
        value: function transaction(order) {
            return order.payment_status !== 'paid' && this.isInRoles('admin', 'manager', 'designer', 'virtual') && order.type != 'bid';
        }
    }, {
        key: 'replicate',
        value: function replicate(order) {
            return this.isInRoles('admin', 'manager', 'designer', 'master', 'virtual');
        }
    }, {
        key: 'price',
        value: function price(order) {
            return order.payment_status !== 'paid' && this.isInRoles('admin', 'manager', 'designer', 'virtual') && order.type != 'bid';
        }
    }, {
        key: 'share',
        value: function share(order) {
            return this.isInRoles('admin', 'manager', 'designer', 'virtual') && order.type != 'bid';
        }
    }, {
        key: 'sale',
        value: function sale(order) {
            return this.isAdmin;
        }
    }, {
        key: 'manager',
        value: function manager(order) {
            return this.isAdmin;
        }
    }]);

    return _class;
}(_rest2.default);

exports.default = _class;