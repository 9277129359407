'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function _class(OrderService, CacheUserService, CacheValueService) {
    'ngInject';

    _classCallCheck(this, _class);

    Object.assign(this, {
        filter: {
            value: CacheValueService,
            order: OrderService,
            user: CacheUserService
        }
    });
};

exports.default = _class;