'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _report = require('./report.state');

var _report2 = _interopRequireDefault(_report);

var _report3 = require('./report.component');

var _report4 = _interopRequireDefault(_report3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.report', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_report2.default.name, _report2.default);
}).component(_report4.default.name.camelCase(), _report4.default);