'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(ClientService, IdentityService, OrderPolicy, CacheValueService, CacheProductService, CacheProductCalcService, InvoiceService, CacheStatusService, CacheUserService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            filter: {
                value: CacheValueService,
                client: ClientService,
                user: CacheUserService,
                product: CacheProductService,
                product_calc: CacheProductCalcService,
                order: {
                    status: CacheStatusService
                },
                invoice: InvoiceService
            },
            IdentityService: IdentityService,
            policy: OrderPolicy
        });
    }

    _createClass(_class, [{
        key: 'user',
        get: function get() {
            return this.IdentityService.user;
        }
    }]);

    return _class;
}();

exports.default = _class;