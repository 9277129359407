'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _transaction = require('./transaction.state');

var _transaction2 = _interopRequireDefault(_transaction);

var _transaction3 = require('./transaction.component');

var _transaction4 = _interopRequireDefault(_transaction3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.transaction', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_transaction2.default.name, _transaction2.default);
}).component(_transaction4.default.name.camelCase(), _transaction4.default);