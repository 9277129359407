'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _storeDialog = require('./store.dialog.pug');

var _storeDialog2 = _interopRequireDefault(_storeDialog);

var _storeDialog3 = require('./store.dialog.controller');

var _storeDialog4 = _interopRequireDefault(_storeDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _storeDialog2.default)({}),
    controller: _storeDialog4.default,
    name: 'nlc-store-dialog'
};