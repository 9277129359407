'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _auth = require('./auth/auth.interceptor');

var _auth2 = _interopRequireDefault(_auth);

var _language = require('./language/language.interceptor');

var _language2 = _interopRequireDefault(_language);

var _timezone = require('./timezone/timezone.interceptor');

var _timezone2 = _interopRequireDefault(_timezone);

var _translation = require('./translation.factory');

var _translation2 = _interopRequireDefault(_translation);

var _confirm = require('./conflict/confirm.interceptor');

var _confirm2 = _interopRequireDefault(_confirm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.factories', []).factory('TranslationFactory', _translation2.default).factory('AuthInterceptor', _auth2.default).factory('LanguageInterceptor', _language2.default).factory('TimezoneInterceptor', _timezone2.default);
// .factory('ConfirmConflictInterceptor', ConfirmConflictInterceptor)