'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _register = require('./register.component');

var _register2 = _interopRequireDefault(_register);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'authentication.register',
    url: '/register/',
    template: '<' + _register2.default.name + '/>',
    title: 'REGISTER.TITLE'
};