'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dialog = require('./dialog/dialog');

var _dialog2 = _interopRequireDefault(_dialog);

var _form = require('./form/form');

var _form2 = _interopRequireDefault(_form);

var _direction = require('./direction/direction');

var _direction2 = _interopRequireDefault(_direction);

var _data = require('./data/data');

var _data2 = _interopRequireDefault(_data);

var _toast = require('./toast/toast');

var _toast2 = _interopRequireDefault(_toast);

var _error = require('./error/error');

var _error2 = _interopRequireDefault(_error);

var _cache = require('./cache/cache');

var _cache2 = _interopRequireDefault(_cache);

var _tree = require('./tree/tree');

var _tree2 = _interopRequireDefault(_tree);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('app.modules', [_cache2.default.name, _dialog2.default.name, _form2.default.name, _direction2.default.name, _data2.default.name, _toast2.default.name, _error2.default.name, _tree2.default.name]);