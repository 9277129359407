'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _select = require('./select/select.component');

var _select2 = _interopRequireDefault(_select);

var _dropdown = require('./dropdown/dropdown.component');

var _dropdown2 = _interopRequireDefault(_dropdown);

var _typeahead = require('./typeahead/typeahead.component');

var _typeahead2 = _interopRequireDefault(_typeahead);

var _datetimeRange = require('./datetime-range/datetime-range.component');

var _datetimeRange2 = _interopRequireDefault(_datetimeRange);

var _radioGroup = require('./radio-group/radio-group.component');

var _radioGroup2 = _interopRequireDefault(_radioGroup);

var _checkboxGroup = require('./checkbox-group/checkbox-group.component');

var _checkboxGroup2 = _interopRequireDefault(_checkboxGroup);

var _typeahead3 = require('angular-ui-bootstrap/src/typeahead');

var _typeahead4 = _interopRequireDefault(_typeahead3);

require('angular-datetime-range/src/datetime-range.css');

var _angularDatetimeRange = require('angular-datetime-range');

var _angularDatetimeRange2 = _interopRequireDefault(_angularDatetimeRange);

var _cmykPantone = require('./smart/cmyk-pantone/cmyk-pantone.component');

var _cmykPantone2 = _interopRequireDefault(_cmykPantone);

var _cmykPantoneSides = require('./smart/cmyk-pantone-sides/cmyk-pantone-sides.component');

var _cmykPantoneSides2 = _interopRequireDefault(_cmykPantoneSides);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.components.controls', [_typeahead4.default, _angularDatetimeRange2.default]).component(_select2.default.name.camelCase(), _select2.default).component(_dropdown2.default.name.camelCase(), _dropdown2.default).component(_typeahead2.default.name.camelCase(), _typeahead2.default).component(_datetimeRange2.default.name.camelCase(), _datetimeRange2.default).component(_radioGroup2.default.name.camelCase(), _radioGroup2.default).component(_checkboxGroup2.default.name.camelCase(), _checkboxGroup2.default).component(_cmykPantone2.default.name.camelCase(), _cmykPantone2.default).component(_cmykPantoneSides2.default.name.camelCase(), _cmykPantoneSides2.default);