'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _reward = require('./reward.component');

var _reward2 = _interopRequireDefault(_reward);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.reward',
    url: '/rewards/',
    template: '<' + _reward2.default.name + '/>',
    title: 'REWARD.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'REWARD.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.REWARD',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-money'
    }
};