'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _service = require('./service.state');

var _service2 = _interopRequireDefault(_service);

var _service3 = require('./service.component');

var _service4 = _interopRequireDefault(_service3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = 'product';
exports.default = _angular2.default.module('app.pages.service', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_service2.default.name, _service2.default);
}).component(_service4.default.name.camelCase(), _service4.default);