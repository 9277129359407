'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _menuItem = require('./menu-item.component');

var _menuItem2 = _interopRequireDefault(_menuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.content-menu-item',
    url: '/content/menus/:id/items/',
    template: '<' + _menuItem2.default.name + ' menu=\'menu\'/>',
    controller: /*@ngInject*/function controller($scope, menu) {
        return $scope.menu = menu;
    },
    title: 'CONTENT-MENU-ITEM.TITLE',
    resolve: {
        menu: /*@ngInject*/function menu($stateParams, ContentMenuService) {
            return ContentMenuService.get($stateParams.id);
        }
    },
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'CONTENT-MENU-ITEM.BREADCRUMB' // angular-breadcrumb's configuration
    }
};