'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _deliveryShortInfo = require('./delivery-short-info.pug');

var _deliveryShortInfo2 = _interopRequireDefault(_deliveryShortInfo);

var _deliveryShortInfo3 = require('./delivery-short-info.controller');

var _deliveryShortInfo4 = _interopRequireDefault(_deliveryShortInfo3);

require('./delivery-short-info.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _deliveryShortInfo2.default)({}),
    controller: _deliveryShortInfo4.default,
    name: 'nlc-delivery-short-info',
    bindings: {
        provider: '<',
        inputs: '<',
        data: '<',
        results: '<'
    },
    bindToController: true
};