'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    /**
     * 
     */
    function _class(toastr) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { toastr: toastr });
    }

    _createClass(_class, [{
        key: 'success',
        value: function success(title, content) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.toastr.success(content, title, angular.merge(this.defaultOptions, options)).open.promise;
        }
    }, {
        key: 'info',
        value: function info(title, content) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.toastr.info(content, title, angular.merge(this.defaultOptions, options)).open.promise;
        }
    }, {
        key: 'error',
        value: function error(title, content) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.toastr.error(content, title, angular.merge(this.defaultOptions, options)).open.promise;
        }
    }, {
        key: 'warning',
        value: function warning(title, content) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.toastr.warning(content, title, angular.merge(this.defaultOptions, options)).open.promise;
        }
    }, {
        key: 'defaultOptions',
        get: function get() {
            return {
                allowHtml: true,
                closeButton: true,
                autoDismiss: false
            };
        }
    }]);

    return _class;
}();

exports.default = _class;