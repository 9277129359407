'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, [{
        key: 'type',
        value: function type(_ref) {
            var _type = _ref.type;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            return '' + prefix + _type;
        }
    }, {
        key: 'track_price',
        value: function track_price(_ref2) {
            var _track_price = _ref2.track_price;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            return _track_price ? prefix + 'success' : prefix + 'default';
        }
    }, {
        key: 'last_tracked_price',
        value: function last_tracked_price(_ref3) {
            var price = _ref3.price,
                _last_tracked_price = _ref3.last_tracked_price;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            return price === _last_tracked_price ? prefix + 'success' : prefix + 'danger';
        }
    }]);

    return _class;
}();

exports.default = _class;