'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class($scope, ValueService, CacheDictionaryService, ValueAssociationService, ValueAssociationPolicy, ValueAssociationChoice) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, ValueService));

        Object.assign(_this, { $scope: $scope, CacheDictionaryService: CacheDictionaryService, actionService: ValueAssociationService, policy: ValueAssociationPolicy, choice: ValueAssociationChoice });
        return _this;
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            this.controller = this;
            Object.assign(this, {
                request: {
                    // appends: ['associated_dictionary_value_id_exists'],
                    dictionary_id: this.value.dictionary_id,
                    dictionary_value_id: this.value.id,
                    dictionary_value_id_exists: this.value.id
                }
            });
            this.$scope.$watch(function () {
                return _this2.dictionary;
            }, function () {
                if (angular.isUndefined(_this2.dictionary) || angular.isUndefined(_this2.dictionary.schema)) {
                    return;
                }
                _this2.columns = _lodash2.default.sortBy(_this2.dictionary.schema, ['index', 'name'], ['asc', 'asc']);
            });
            _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this);
        }
    }, {
        key: 'attach',
        value: function attach(association) {
            return this.actionService.attach(association.id, {
                dictionary_id: this.value.dictionary_id,
                dictionary_value_id: this.value.id
            }).then(function (updated) {
                return Object.assign(association, updated);
            });
        }
    }, {
        key: 'detach',
        value: function detach(association) {
            return this.actionService.detach(association.id, {
                dictionary_id: this.value.dictionary_id,
                dictionary_value_id: this.value.id
            }).then(function (updated) {
                return Object.assign(association, updated);
            });
        }
    }, {
        key: 'check',
        value: function check(association) {
            return association.dictionary_value_id_exists ? this.detach(association) : this.attach(association);
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;