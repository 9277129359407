'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _transaction = require('./transaction.component');

var _transaction2 = _interopRequireDefault(_transaction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.transaction',
    url: '/transactions/',
    template: '<' + _transaction2.default.name + '/>',
    title: 'TRANSACTION.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'TRANSACTION.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.TRANSACTION',
        priority: 100,
        roles: ['admin', 'manager', 'designer', 'master'],
        tag: 'sidebar',
        icon: 'fa fa-money'
    }
};