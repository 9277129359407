'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _templateDialog = require('./template.dialog.pug');

var _templateDialog2 = _interopRequireDefault(_templateDialog);

var _templateDialog3 = require('./template.dialog.controller');

var _templateDialog4 = _interopRequireDefault(_templateDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _templateDialog2.default)({}),
    controller: _templateDialog4.default,
    size: 'lg',
    name: 'nlc-product-calc-template-dialog'
};