'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dataArrayValue = require('./data.array.value.pug');

var _dataArrayValue2 = _interopRequireDefault(_dataArrayValue);

require('./data.array.value.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _dataArrayValue2.default)({}),
    name: 'nlc-data-array-values-page',
    bindings: {
        dArray: '<'
    },
    bindToController: true
};