'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _menuItems = require('./menu-items.pug');

var _menuItems2 = _interopRequireDefault(_menuItems);

var _menuItems3 = require('./menu-items.controller');

var _menuItems4 = _interopRequireDefault(_menuItems3);

require('./menu-items.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _menuItems2.default)({}),
    controller: _menuItems4.default,
    name: 'nlc-content-menu-items',
    bindings: {
        controller: '=',
        menu: '<'
    },
    bindToController: true
};