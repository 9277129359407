'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(IdentityService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { IdentityService: IdentityService });
    }

    _createClass(_class, [{
        key: 'hasRole',
        value: function hasRole(role) {
            return this.user.role == role;
        }
    }, {
        key: 'hasNotRole',
        value: function hasNotRole(role) {
            return !this.hasRole(role);
        }
    }, {
        key: 'isInRoles',
        value: function isInRoles() {
            for (var _len = arguments.length, roles = Array(_len), _key = 0; _key < _len; _key++) {
                roles[_key] = arguments[_key];
            }

            return roles.includes(this.user.role);
        }
    }, {
        key: 'isNotInRoles',
        value: function isNotInRoles() {
            for (var _len2 = arguments.length, roles = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
                roles[_key2] = arguments[_key2];
            }

            return !this.isInRoles(roles);
        }
    }, {
        key: 'store',
        value: function store() {
            return this.isAdmin;
        }
    }, {
        key: 'update',
        value: function update() {
            var ability = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return this.isAdmin;
        }
    }, {
        key: 'destroy',
        value: function destroy() {
            var ability = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return this.isAdmin;
        }
    }, {
        key: 'user',
        get: function get() {
            return this.IdentityService.user;
        }
    }, {
        key: 'isAdmin',
        get: function get() {
            return this.hasRole('admin');
        }
    }]);

    return _class;
}();

exports.default = _class;