'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(EventService, $animate, $element) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { EventService: EventService, $animate: $animate, $element: $element });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            this.EventService.out.filter(function (event) {
                return event.type == 'app.init';
            }).subscribe(function () {
                return _this.$animate.leave(_this.$element);
            });
            this.EventService.out.filter(function (event) {
                return event.type == 'app.load';
            }).subscribe(function () {
                return _this.$animate.enter(_this.$element, document.body);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;