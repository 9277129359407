'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calc = require('./calc.pug');

var _calc2 = _interopRequireDefault(_calc);

var _calc3 = require('./calc.controller');

var _calc4 = _interopRequireDefault(_calc3);

require('./calc.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calc2.default)({}),
    controller: _calc4.default,
    name: 'nlc-calc',
    bindings: {
        data: '<',
        request: '=',
        controller: '=',
        onCalc: '&'
    },
    bindToController: true
};