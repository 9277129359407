'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _splashScreen = require('./splash-screen.controller');

var _splashScreen2 = _interopRequireDefault(_splashScreen);

require('./splash-screen.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    transclude: true,
    template: '<ng-transclude/>',
    controller: _splashScreen2.default,
    name: 'splash-screen'
};