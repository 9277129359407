'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dictionary = require('./dictionary.pug');

var _dictionary2 = _interopRequireDefault(_dictionary);

var _dictionary3 = require('./dictionary.controller');

var _dictionary4 = _interopRequireDefault(_dictionary3);

require('./dictionary.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _dictionary2.default)({}),
    controller: _dictionary4.default,
    name: 'nlc-dictionaries-page'
};