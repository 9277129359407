'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

require('./authentication.scss');

var _authentication = require('./authentication.state');

var _authentication2 = _interopRequireDefault(_authentication);

var _authentication3 = require('./authentication.service');

var _authentication4 = _interopRequireDefault(_authentication3);

var _login = require('./login/login');

var _login2 = _interopRequireDefault(_login);

var _register = require('./register/register');

var _register2 = _interopRequireDefault(_register);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.authentication', [_login2.default.name, _register2.default.name]).config(function ($stateProvider, DEFAULT_STATE) {
    'ngInject';

    $stateProvider.state(_authentication2.default.name, _angular2.default.merge(_authentication2.default, {
        data: {
            permissions: {
                only: 'ANONYMOUS',
                redirectTo: DEFAULT_STATE
            }
        }
    }));
}).service('AuthenticationService', _authentication4.default);