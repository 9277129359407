'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _action = require('./action.pug');

var _action2 = _interopRequireDefault(_action);

var _action3 = require('./action.controller');

var _action4 = _interopRequireDefault(_action3);

require('./action.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _action2.default)({}),
    controller: _action4.default,
    name: 'nlc-actions-page'
};