'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _office = require('./office.state');

var _office2 = _interopRequireDefault(_office);

var _office3 = require('./office.component');

var _office4 = _interopRequireDefault(_office3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = 'product';
exports.default = _angular2.default.module('app.pages.office', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_office2.default.name, _office2.default);
}).component(_office4.default.name.camelCase(), _office4.default);