'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Created by shareed2k on 05/03/2017.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */


var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($rootScope, $state) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $rootScope: $rootScope, $state: $state });
    }

    _createClass(_class, [{
        key: 'menu',
        value: function menu(state, active) {
            var open = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            if (_angular2.default.isDefined(state.menu)) {
                state.menu.active = active;
                if (active) {
                    state.menu.open = open;
                }
            }
            if (_angular2.default.isDefined(state.root)) {
                this.menu(state.root, active, true);
            }
        }
    }, {
        key: 'init',
        value: function init() {
            var _this = this;

            return this.$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                _this.menu(fromState, false);
                _this.menu(toState, true);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;