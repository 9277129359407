'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _debitDialog = require('./debit.dialog.pug');

var _debitDialog2 = _interopRequireDefault(_debitDialog);

var _storeDialog = require('../store.dialog.controller');

var _storeDialog2 = _interopRequireDefault(_storeDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _debitDialog2.default)({}),
    controller: _storeDialog2.default,
    resolve: {
        type: function type() {
            return 'debit';
        }
    },
    name: 'nlc-transactions-debit-dialog'
};