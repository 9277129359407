'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _status = require('./status.component');

var _status2 = _interopRequireDefault(_status);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.status',
    url: '/statuses/',
    template: '<' + _status2.default.name + '/>',
    title: 'STATUS.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'STATUS.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.STATUS',
        priority: 2,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-angle-double-left'
    }
};