'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _menu = require('./menu/menu');

var _menu2 = _interopRequireDefault(_menu);

var _block = require('./block/block');

var _block2 = _interopRequireDefault(_block);

var _page = require('./page/page');

var _page2 = _interopRequireDefault(_page);

var _menuItem = require('./menu-item/menu-item');

var _menuItem2 = _interopRequireDefault(_menuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.content', [_menu2.default.name, _menuItem2.default.name, _block2.default.name, _page2.default.name]);