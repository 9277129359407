'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(AuthService, AuthenticationService, $state, DEFAULT_STATE, $timeout) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { AuthService: AuthService, AuthenticationService: AuthenticationService, $state: $state, DEFAULT_STATE: DEFAULT_STATE, $timeout: $timeout });
    }

    _createClass(_class, [{
        key: 'submit',
        value: function submit() {
            var _this = this;

            this.promise = this.AuthenticationService.register(this.data).then(function (_ref) {
                var access_token = _ref.access_token;
                return _this.AuthService.authorize(access_token);
            }).then(function () {
                return _this.$state.go(_this.DEFAULT_STATE);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;