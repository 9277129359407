'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _status = require('./status.pug');

var _status2 = _interopRequireDefault(_status);

var _status3 = require('./status.controller');

var _status4 = _interopRequireDefault(_status3);

require('./status.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _status2.default)({}),
    controller: _status4.default,
    name: 'nlc-statuses-page'
};