'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _app = require('./app.pug');

var _app2 = _interopRequireDefault(_app);

var _app3 = require('./app.controller');

var _app4 = _interopRequireDefault(_app3);

require('./app.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppComponent = {
  template: (0, _app2.default)({}),
  name: 'app',
  controller: _app4.default
};

exports.default = AppComponent;