'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _nlNullDataOrderBy = require('./nl-null-data-order-by.controller');

var _nlNullDataOrderBy2 = _interopRequireDefault(_nlNullDataOrderBy);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $scope: $scope, orderByColumn: new _nlNullDataOrderBy2.default() });
    }

    _createClass(_class, [{
        key: 'refresh',
        value: function refresh() {
            this.onRefresh();
            this.$scope.$apply();
        }
    }, {
        key: 'sort',
        value: function sort(column) {
            if (this.orderByColumn != column) this.orderByColumn.disable();
            (this.orderByColumn = column).fill(this.request);
            this.refresh();
        }
    }, {
        key: 'filter',
        value: function filter() {
            this.refresh();
        }
    }]);

    return _class;
}();

exports.default = _class;