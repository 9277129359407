'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dataArray = require('./data.array.state');

var _dataArray2 = _interopRequireDefault(_dataArray);

var _dataArray3 = require('./data.array.component');

var _dataArray4 = _interopRequireDefault(_dataArray3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.data-array', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_dataArray2.default.name, _dataArray2.default);
}).component(_dataArray4.default.name.camelCase(), _dataArray4.default);