'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _product = require('./product.component');

var _product2 = _interopRequireDefault(_product);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.product',
    url: '/products/',
    template: '<' + _product2.default.name + '/>',
    title: 'PRODUCT.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'PRODUCT.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.PRODUCT',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-image'
    }
};