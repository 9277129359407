'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _prompt = require('./prompt.pug');

var _prompt2 = _interopRequireDefault(_prompt);

var _prompt3 = require('./prompt.controller');

var _prompt4 = _interopRequireDefault(_prompt3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _prompt2.default)({}),
    name: 'prompt-dialog',
    controller: _prompt4.default
};