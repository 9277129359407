'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _associationDialog = require('./association.dialog.pug');

var _associationDialog2 = _interopRequireDefault(_associationDialog);

var _associationDialog3 = require('./association.dialog.controller');

var _associationDialog4 = _interopRequireDefault(_associationDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _associationDialog2.default)({}),
    controller: _associationDialog4.default,
    name: 'nlc-dictionary-value-association-dialog',
    size: 'lg'
};