'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _reward = require('./reward.pug');

var _reward2 = _interopRequireDefault(_reward);

require('./reward.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _reward2.default)({}),
    name: 'nlc-rewards-page'
};