'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _block = require('./block.pug');

var _block2 = _interopRequireDefault(_block);

require('./block.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _block2.default)({}),
    name: 'nlc-content-blocks-page'
};