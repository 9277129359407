'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $scope: $scope });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            this.controller = this;
            if (_angular2.default.isUndefined(this.field)) {
                this.field = 'name';
            }
            this.ngModel.$render = function () {
                return Object.assign(_this, { selected: { id: _this.ngModel.$viewValue } });
            };
            this.$scope.$watch(function () {
                return _this.selected;
            }, function (value) {
                return _this.ngModel.$setViewValue(value.id);
            });
            this.$scope.$watch(function () {
                return _this.request;
            }, function (value, old) {
                if (!_angular2.default.equals(value, old)) {
                    _this.refresh();
                }
            });
            this.refresh();
        }
    }, {
        key: 'refresh',
        value: function refresh() {
            var _this2 = this;

            this.promise = this.service.data(Object.assign({}, this.request || {}, { limit: 9999 })).then(function (data) {
                return Object.assign(_this2, { data: data });
            });
        }
    }]);

    return _class;
}();

exports.default = _class;