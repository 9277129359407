'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _report = require('./report.pug');

var _report2 = _interopRequireDefault(_report);

var _report3 = require('./report.controller');

var _report4 = _interopRequireDefault(_report3);

require('./report.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _report2.default)({}),
    controller: _report4.default,
    name: 'nlc-reports-page'
};