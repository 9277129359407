'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _user = require('./user.pug');

var _user2 = _interopRequireDefault(_user);

var _user3 = require('./user.controller');

var _user4 = _interopRequireDefault(_user3);

require('./user.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _user2.default)({}),
    controller: _user4.default,
    name: 'nlc-users-page'
};