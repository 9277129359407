'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _select = require('./select.pug');

var _select2 = _interopRequireDefault(_select);

var _select3 = require('./select.controller');

var _select4 = _interopRequireDefault(_select3);

require('./select.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _select2.default)({}),
    controller: _select4.default,
    name: 'nlc-select',
    transclude: true,
    bindings: {
        controller: '=?',
        service: '=',
        all: '<',
        field: '<',
        unique: '<',
        noValue: '<',
        request: '=',
        selected: '=?',
        noInit: '<',
        valueField: '<',
        initNumber: '<'
    },
    require: {
        ngModel: '^ngModel'
    },
    bindToController: true
};