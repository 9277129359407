'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dataArrayValue = require('./data.array.value.state');

var _dataArrayValue2 = _interopRequireDefault(_dataArrayValue);

var _dataArrayValue3 = require('./data.array.value.component');

var _dataArrayValue4 = _interopRequireDefault(_dataArrayValue3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.data-array-value', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_dataArrayValue2.default.name, _dataArrayValue2.default);
}).component(_dataArrayValue4.default.name.camelCase(), _dataArrayValue4.default);