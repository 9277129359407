'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _image = require('../components/image/image.component');

var _image2 = _interopRequireDefault(_image);

var _alert = require('../components/alert/alert.component');

var _alert2 = _interopRequireDefault(_alert);

var _confirm = require('../components/confirm/confirm.component');

var _confirm2 = _interopRequireDefault(_confirm);

var _prompt = require('../components/prompt/prompt.component');

var _prompt2 = _interopRequireDefault(_prompt);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    /**
     * 
     */
    function _class($uibModal) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { _dialog: $uibModal });
    }
    /**
     * Default options
     */


    _createClass(_class, [{
        key: 'open',
        value: function open(component) {
            var locals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var resolve = {};
            Object.keys(locals).forEach(function (key) {
                return resolve[key] = function () {
                    return locals[key];
                };
            });
            return this._dialog.open(angular.merge(component, this.defaultOptions, { resolve: resolve })).result;
        }
    }, {
        key: 'alert',
        value: function alert(title, content) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.open(angular.merge(_alert2.default, options), { title: title, content: content });
        }
    }, {
        key: 'confirm',
        value: function confirm(title, content) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.open(angular.merge(_confirm2.default, options), { title: title, content: content });
        }
    }, {
        key: 'prompt',
        value: function prompt(title, value) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.open(angular.merge(_prompt2.default, options), { title: title, value: value });
        }
    }, {
        key: 'image',
        value: function image(title, src) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.open(angular.merge(_image2.default, options), { title: title, src: src });
        }
    }, {
        key: 'defaultOptions',
        get: function get() {
            return {
                keyboard: false,
                controllerAs: '$ctrl',
                backdrop: 'static'
            };
        }
    }]);

    return _class;
}();

exports.default = _class;