'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _store = require('./dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

var _update = require('./dialog/update/update.dialog');

var _update2 = _interopRequireDefault(_update);

var _calc = require('../../common/dialog/calc/calc.dialog');

var _calc2 = _interopRequireDefault(_calc);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class(TranslateDialogService, $state, ReportService, ReportPolicy, ReportChoice, CacheActionService, CacheProductService, CacheProductCalcService) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, ReportService));

        Object.assign(_this, { $state: $state, dialog: TranslateDialogService, policy: ReportPolicy, choice: ReportChoice, CacheActionService: CacheActionService, CacheProductService: CacheProductService, CacheProductCalcService: CacheProductCalcService });
        _this.request = {
            withs: ['action', 'product', 'product_calc']
        };
        return _this;
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            this.controller = this;
            _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this);
        }
    }, {
        key: 'store',
        value: function store() {
            var _this2 = this;

            return this.dialog.open(_store2.default).then(function () {
                return _this2.refresh();
            });
        }
    }, {
        key: 'update',
        value: function update(report) {
            var _this3 = this;

            return this.dialog.open(_update2.default, { report: report }).then(function () {
                return _this3.refresh();
            });
        }
    }, {
        key: 'destroy',
        value: function destroy(report) {
            var _this4 = this;

            return this.dialog.confirm('COMPONENTS.REPORTS.GRID.ACTIONS.DESTROY.TITLE', 'COMPONENTS.REPORTS.GRID.ACTIONS.DESTROY.MESSAGE', {}, report /*interpolation params*/).then(function () {
                return _this4.service.destroy(report.id, report);
            }).then(function () {
                return _this4.refresh();
            });
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;