'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dropdown = require('./dropdown.pug');

var _dropdown2 = _interopRequireDefault(_dropdown);

var _dropdown3 = require('./dropdown.controller');

var _dropdown4 = _interopRequireDefault(_dropdown3);

require('./dropdown.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _dropdown2.default)({}),
    controller: _dropdown4.default,
    name: 'nlc-dropdown',
    transclude: true,
    bindings: {
        service: '=',
        field: '@',
        request: '=',
        onSelect: '&'
    },
    bindToController: true
};