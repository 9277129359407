'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _orders = require('./orders.pug');

var _orders2 = _interopRequireDefault(_orders);

var _orders3 = require('./orders.controller');

var _orders4 = _interopRequireDefault(_orders3);

require('./orders.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _orders2.default)({}),
    controller: _orders4.default,
    name: 'nlc-orders',
    bindings: {
        controller: '=',
        id: '<',
        tableMode: '=',
        client: '<',
        invoice: '<',
        type: '@'
    },
    bindToController: true
};