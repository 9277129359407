'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calcDialog = require('./calc.dialog.pug');

var _calcDialog2 = _interopRequireDefault(_calcDialog);

var _calcDialog3 = require('./calc.dialog.controller');

var _calcDialog4 = _interopRequireDefault(_calcDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calcDialog2.default)({}),
    controller: _calcDialog4.default,
    name: 'nlc-calc-dialog'
};