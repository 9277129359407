'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _invoice = require('./invoice.state');

var _invoice2 = _interopRequireDefault(_invoice);

var _invoice3 = require('./invoice.component');

var _invoice4 = _interopRequireDefault(_invoice3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.invoice', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_invoice2.default.name, _invoice2.default);
}).component(_invoice4.default.name.camelCase(), _invoice4.default);