'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _orderItem = require('./order-item.state');

var _orderItem2 = _interopRequireDefault(_orderItem);

var _orderItem3 = require('./order-item.component');

var _orderItem4 = _interopRequireDefault(_orderItem3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.order-item', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_orderItem2.default.name, _orderItem2.default);
}).component(_orderItem4.default.name.camelCase(), _orderItem4.default);