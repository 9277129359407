'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _app = require('./app.component');

var _app2 = _interopRequireDefault(_app);

var _app3 = require('./app.state');

var _app4 = _interopRequireDefault(_app3);

var _components = require('./components/components');

var _components2 = _interopRequireDefault(_components);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.main', [_components2.default.name]).config(function ($stateProvider, AUTHENTICATION_STATE) {
    'ngInject';

    $stateProvider.state('app', _angular2.default.merge(_app4.default, {
        data: {
            permissions: {
                only: 'AUTHORIZED',
                redirectTo: AUTHENTICATION_STATE
            }
        }
    }));
}).component(_app2.default.name.camelCase(), _app2.default);