'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _invoice = require('./invoice.component');

var _invoice2 = _interopRequireDefault(_invoice);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.invoice',
    url: '/invoices/',
    template: '<' + _invoice2.default.name + '/>',
    title: 'INVOICE.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'INVOICE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.INVOICE',
        priority: 100,
        roles: ['admin', 'manager', 'designer', 'master', 'viewer', 'accounter'],
        tag: 'sidebar',
        icon: 'fa fa-university'
    }
};