'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash.uniq');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function ($translatePartialLoader, $translate) {
    'ngInject';

    return {
        load: function load(context) {
            return Promise.all((0, _lodash2.default)(context.keys().map(context).map(function (key) {
                return key.substr(0, key.lastIndexOf('/'));
            })).map(function (part) {
                return $translatePartialLoader.addPart(part);
            })).then(function () {
                return $translate.refresh();
            });
        }
    };
};