'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class() {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { type: 0, id: 'nlDataOrderBy' });
    }

    _createClass(_class, [{
        key: 'disable',
        value: function disable() {
            this.type = 0;
        }
    }, {
        key: 'fill',
        value: function fill(data) {
            switch (this.type) {
                case 1:
                    {
                        data.sort = this.field;
                        data.direction = 'asc';
                        break;
                    }
                case 2:
                    {
                        data.sort = this.field;
                        data.direction = 'desc';
                        break;
                    }
                default:
                    {
                        delete data.sort;
                        delete data.direction;
                    }
            }
        }
    }, {
        key: 'sort',
        value: function sort() {
            if (++this.type > 2) {
                this.type = 0;
            }
            this.nlData.sort(this);
        }
    }, {
        key: 'asc',
        get: function get() {
            return 1 === this.type;
        }
    }, {
        key: 'desc',
        get: function get() {
            return 2 === this.type;
        }
    }, {
        key: 'none',
        get: function get() {
            return 0 === this.type;
        }
    }]);

    return _class;
}();

exports.default = _class;