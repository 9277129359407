'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AppSidebarController = function AppSidebarController($rootScope) {
    'ngInject';

    _classCallCheck(this, AppSidebarController);

    Object.assign(this, { layout: $rootScope.layout });
};

exports.default = AppSidebarController;