'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope, $translate) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            $scope: $scope,
            $translate: $translate,
            ranges: {
                start: (0, _moment2.default)().subtract(1, 'month').startOf('day'),
                end: (0, _moment2.default)().add(1, 'day').endOf('day')
            }
        });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            if (angular.isUndefined(this.presets)) {
                this.presets = [{
                    'name': this.$translate.instant('COMPONENTS.CONTROLS.DATETIME_RANGE.RANGES.THIS_WEEK'),
                    'start': (0, _moment2.default)().startOf('week').startOf('day'),
                    'end': (0, _moment2.default)().endOf('week').endOf('day')
                }, {
                    'name': this.$translate.instant('COMPONENTS.CONTROLS.DATETIME_RANGE.RANGES.THIS_MONTH'),
                    'start': (0, _moment2.default)().startOf('month').startOf('day'),
                    'end': (0, _moment2.default)().endOf('month').endOf('day')
                }, {
                    'name': this.$translate.instant('COMPONENTS.CONTROLS.DATETIME_RANGE.RANGES.THIS_YEAR'),
                    'start': (0, _moment2.default)().startOf('year').startOf('day'),
                    'end': (0, _moment2.default)().endOf('year').endOf('day')
                }];
            }

            this.ngModel.$render = function () {
                return Object.assign(_this, { model: _this.ngModel.$viewValue });
            };
            this.$scope.$watch(function () {
                return _this.model;
            }, function (value) {
                return _this.ngModel.$setViewValue(value);
            });
        }
    }, {
        key: 'change',
        value: function change() {
            this.model = {
                start: this.ranges.start.toDate(),
                end: this.ranges.end.toDate()
            };
        }
    }]);

    return _class;
}();

exports.default = _class;