'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope, CacheValueService, CalcService, CacheDictionaryService, CacheServiceService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            CacheValueService: CacheValueService,
            CalcService: CalcService,
            CacheDictionaryService: CacheDictionaryService,
            CacheServiceService: CacheServiceService,
            $scope: $scope,
            ready: false,
            results: {},
            dictionaries: {},
            outputs: {},
            services: {}
        });
    }

    _createClass(_class, [{
        key: 'init',
        value: function init() {
            var _this = this;

            Object.assign(this, {
                ready: false,
                results: {},
                dictionaries: {},
                outputs: {},
                services: {}
            });
            var serviceIds = function serviceIds(_ref) {
                var services = _ref.services,
                    groups = _ref.groups;
                return _lodash2.default.uniq([].concat(_toConsumableArray(_lodash2.default.values(services)), _toConsumableArray(_lodash2.default.concat.apply(null, _lodash2.default.values(groups).map(function (group) {
                    return serviceIds(group);
                })))));
            };
            var componentCodes = function componentCodes(_ref2) {
                var components = _ref2.components,
                    extras = _ref2.extras,
                    groups = _ref2.groups;
                return _lodash2.default.uniq([].concat(_toConsumableArray(_lodash2.default.keys(components)), _toConsumableArray(_lodash2.default.values(extras)), _toConsumableArray(_lodash2.default.concat.apply(null, _lodash2.default.values(groups).map(function (group) {
                    return componentCodes(group);
                })))));
            };
            var outputCodes = function outputCodes(_ref3) {
                var outputs = _ref3.outputs,
                    groups = _ref3.groups;
                return _lodash2.default.uniq([].concat(_toConsumableArray(_lodash2.default.keys(outputs)), _toConsumableArray(_lodash2.default.concat.apply(null, _lodash2.default.values(groups).map(function (group) {
                    return outputCodes(group);
                })))));
            };
            var ids = serviceIds(this.data);

            this.initPromise = this.CacheServiceService.data({ ids: ids, limit: 999 }).then(function (services) {
                services.forEach(function (service) {
                    return _this.services[service.id] = {
                        id: service.id,
                        name: service.name,
                        type: service.type,
                        enabled: true,
                        condition: service.formula.condition || 'true',
                        schema: service.schema,
                        inputs: _lodash2.default.unionBy(_lodash2.default.keys(service.schema.condition.inputs), _lodash2.default.keys(service.schema.source.inputs))
                    };
                });
            }).then(function () {
                var codes = _lodash2.default.uniq([].concat(_toConsumableArray(componentCodes(_this.data)), _toConsumableArray(_lodash2.default.concat.apply(null, _lodash2.default.values(_this.services).map(function (_ref4) {
                    var inputs = _ref4.inputs;
                    return inputs;
                })))));
                return Promise.all([_this.CacheDictionaryService.data({ limit: 999, codes: codes, withs: ['values'] }).then(function (data) {
                    data.forEach(function (row) {
                        var schema = {};
                        if (row.type == 'dictionary') {
                            row.schema.forEach(function (input) {
                                schema[input.code] = input;
                            });
                        } else {
                            schema = row.schema;
                        }
                        _this.dictionaries[row.code] = _extends({}, row, {
                            schema: schema,
                            values: _lodash2.default.sortBy(row.values, ['index', 'name'], ['asc', 'asc']).map(function (_ref5) {
                                var id = _ref5.id,
                                    data = _ref5.data,
                                    name = _ref5.name,
                                    meta = _ref5.meta;
                                return _extends({ id: id }, data, { name: name, meta: meta });
                            })
                        });
                    });
                }), _this.CacheDictionaryService.data({ limit: 999, codes: outputCodes(_this.data) }).then(function (data) {
                    data.forEach(function (row) {
                        return _this.outputs[row.code] = row;
                    });
                })]);
            }).finally(function () {
                return _this.ready = true;
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            this.$scope.$watch('$ctrl.data', function () {
                if (angular.isDefined(_this2.data)) {
                    _this2.init();
                }
            });
            if (angular.isUndefined(this.request)) {
                this.request = {};
            }
            this.controller = this;
        }
    }, {
        key: 'calc',
        value: function calc() {
            var _this3 = this;

            this.calculating = true;
            this.promise = this.CalcService.run(this.data, this.request).then(function (data) {
                return Object.assign(_this3, { result: data.default.results.default, results: data });
            }).then(function () {
                return _this3.onCalc({ result: _this3.result, results: _this3.results });
            }).finally(function () {
                return _this3.calculating = false;
            });
        }
    }]);

    return _class;
}();

exports.default = _class;