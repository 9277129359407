'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _parameter = require('./parameter.state');

var _parameter2 = _interopRequireDefault(_parameter);

var _parameter3 = require('./parameter.component');

var _parameter4 = _interopRequireDefault(_parameter3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = 'product';
exports.default = _angular2.default.module('app.pages.parameter', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_parameter2.default.name, _parameter2.default);
}).component(_parameter4.default.name.camelCase(), _parameter4.default);