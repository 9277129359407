'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _inputs = require('./inputs.pug');

var _inputs2 = _interopRequireDefault(_inputs);

var _inputs3 = require('./inputs.controller');

var _inputs4 = _interopRequireDefault(_inputs3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _inputs2.default)({}),
    controller: _inputs4.default,
    name: 'nlc-schema-inputs',
    bindings: {
        schema: '=',
        key: '@'
    },
    bindToController: true
};