'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _notFound = require('./not-found.component');

var _notFound2 = _interopRequireDefault(_notFound);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'not-found',
    url: '/not-found/',
    template: '<' + _notFound2.default.name + ' />',
    title: 'NOT_FOUND.TITLE',
    layout: {
        class: 'page-404'
    }
};