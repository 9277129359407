'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _order = require('./order.pug');

var _order2 = _interopRequireDefault(_order);

var _order3 = require('./order.controller');

var _order4 = _interopRequireDefault(_order3);

require('./order.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _order2.default)({}),
    controller: _order4.default,
    name: 'nlc-orders-page'
};