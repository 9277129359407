'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _trackDialog = require('./track.dialog.pug');

var _trackDialog2 = _interopRequireDefault(_trackDialog);

var _trackDialog3 = require('./track.dialog.controller');

var _trackDialog4 = _interopRequireDefault(_trackDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _trackDialog2.default)({}),
    controller: _trackDialog4.default,
    name: 'nlc-calculation-track-dialog'
};