'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _saferEval = require('safer-eval');

var _saferEval2 = _interopRequireDefault(_saferEval);

var _calcRaw = require('./calc-raw.utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class(CalcRawService, CacheDictionaryService) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, null));

        Object.assign(_this, {
            CalcRawService: CalcRawService,
            CacheDictionaryService: CacheDictionaryService,
            dictionaries: {},
            components: []
        });
        return _this;
    }

    _createClass(_class, [{
        key: 'registerComponent',
        value: function registerComponent(input) {
            var type = (0, _calcRaw.detectType)(this.dictionaries, input.base) || input.type;
            var subtype = (0, _calcRaw.detectSubtype)(this.dictionaries, input.base, type, 'number');
            //check if added already
            var component = this.components.filter(function (component) {
                return component.base == input.base;
            }).shift();
            //if component not found, then init it
            if (!component) {
                component = {
                    base: input.base,
                    type: type,
                    subtype: subtype,
                    //take from dictionaries or take jsut base code
                    name: (this.dictionaries[input.base] || { name: input.base }).name,
                    //dictionary, actual meta data
                    dictionary: this.dictionaries[input.base] || {},
                    settings: {
                        values: [],
                        default: input.default
                    },
                    values: [],
                    allValues: (this.dictionaries[input.base] || { values: [] }).values
                };
                this.components = _lodash2.default.sortBy([].concat(_toConsumableArray(this.components), [component]), 'name', 'asc');
            }
            component.settings.values = _lodash2.default.union(component.settings.values, input.values);
            component.settings.default = input.default;
            //sort components
            return component;
        }
    }, {
        key: 'rebuild',
        value: function rebuild() {
            var _this2 = this;

            this.components.forEach(function (component) {
                component.values = component.allValues.filter(function (value) {
                    return component.settings.values.includes(value.id);
                });
                _this2.request[component.base] = component.type == 2 ? component.values.find(function (value) {
                    return value.id == component.settings.default;
                }) : component.settings.default;
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this3 = this;

            this.loading = true;
            this.controller = this;
            if (angular.isUndefined(this.request)) {
                this.request = {};
            }

            var codes = _lodash2.default.uniq([].concat(_toConsumableArray(_lodash2.default.keys(this.schema.condition.inputs)), _toConsumableArray(_lodash2.default.keys(this.schema.source.inputs))));

            this.initPromise = this.CacheDictionaryService.data({ limit: 999, codes: codes, withs: ['values'] }).then(function (data) {
                return data.forEach(function (row) {
                    _this3.dictionaries[row.code] = _extends({}, row, {
                        values: _lodash2.default.sortBy(row.values.map(function (_ref) {
                            var id = _ref.id,
                                data = _ref.data,
                                name = _ref.name;
                            return _extends({ id: id }, data, { name: name });
                        }), ['index', 'name'], ['asc', 'asc'])
                    });
                });
            }).then(function () {
                _lodash2.default.values(_this3.schema.condition.inputs).forEach(function (input) {
                    return _this3.registerComponent(input);
                });
                _lodash2.default.values(_this3.schema.source.inputs).forEach(function (input) {
                    return _this3.registerComponent(input);
                });
            }).then(function () {
                return _this3.rebuild();
            }).finally(function () {
                return _this3.loading = false;
            });
        }
    }, {
        key: 'calc',
        value: function calc() {
            var _this4 = this;

            return this.promise = this.CalcRawService.run(this.id, this.type, this.request).then(function (data) {
                return Object.assign(_this4, { result: data.default.results.default, results: data });
            });
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;