'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _invoices = require('./invoices.pug');

var _invoices2 = _interopRequireDefault(_invoices);

var _invoices3 = require('./invoices.controller');

var _invoices4 = _interopRequireDefault(_invoices3);

require('./invoices.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _invoices2.default)({}),
    controller: _invoices4.default,
    name: 'nlc-invoices',
    bindings: {
        controller: '=',
        order: '<'
    },
    bindToController: true
};