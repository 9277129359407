'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dialog = require('modules/dialog/controllers/dialog.controller');

var _dialog2 = _interopRequireDefault(_dialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DialogController) {
    _inherits(_class, _DialogController);

    function _class($scope, DataArrayValueService, CacheValueService, data_array) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, $scope));

        Object.assign(_this, {
            DataArrayValueService: DataArrayValueService,
            CacheValueService: CacheValueService,
            data_array: data_array,
            request: { data_array_id: data_array.id, inputs: {} }
        });
        return _this;
    }

    _createClass(_class, [{
        key: 'getDictionaryPivotCodeName',
        value: function getDictionaryPivotCodeName(dictionary) {
            var pivotCode = dictionary.pivot.code;
            if (pivotCode == 'id') {
                return 'Идентификатор';
            }
            var field = dictionary.schema.filter(function (_ref) {
                var code = _ref.code;
                return code == pivotCode;
            }).pop();
            return field ? field.name : pivotCode;
        }
    }, {
        key: 'getDictionaryValueField',
        value: function getDictionaryValueField(dictionary) {
            return dictionary.pivot.code != 'id' ? 'data.' + dictionary.pivot.code : 'id';
        }
    }, {
        key: 'getDictionaryField',
        value: function getDictionaryField(dictionary) {
            return dictionary.pivot.code != 'id' ? 'data.' + dictionary.pivot.code : 'name';
        }
    }, {
        key: 'submit',
        value: function submit() {
            var _this2 = this;

            this.promise = this.DataArrayValueService.store(this.request).then(function (value) {
                return _this2.resolve(value);
            });
        }
    }]);

    return _class;
}(_dialog2.default);

exports.default = _class;