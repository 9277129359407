'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _cmykPantone = require('./cmyk-pantone.pug');

var _cmykPantone2 = _interopRequireDefault(_cmykPantone);

var _cmykPantone3 = require('./cmyk-pantone.controller');

var _cmykPantone4 = _interopRequireDefault(_cmykPantone3);

require('./cmyk-pantone.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _cmykPantone2.default)({}),
    controller: _cmykPantone4.default,
    name: 'nlc-cmyk-pantone',
    bindings: {
        request: '='
    },
    bindToController: true
};