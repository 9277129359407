'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($http, API_ENDPOINT) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $http: $http, API_ENDPOINT: API_ENDPOINT });
    }

    _createClass(_class, [{
        key: 'endpoint',
        value: function endpoint(_ref) {
            var dictionary_id = _ref.dictionary_id,
                dictionary_value_id = _ref.dictionary_value_id;

            return this.API_ENDPOINT + 'dictionaries/' + dictionary_id + '/values/' + dictionary_value_id + '/associations/';
        }
    }, {
        key: 'attach',
        value: function attach(id, data) {
            return this.$http.put(this.endpoint(data) + id + '/attach', data, this.options).then(function (_ref2) {
                var data = _ref2.data;
                return data;
            });
        }
    }, {
        key: 'detach',
        value: function detach(id, data) {
            return this.$http.put(this.endpoint(data) + id + '/detach', data, this.options).then(function (_ref3) {
                var data = _ref3.data;
                return data;
            });
        }
    }, {
        key: 'options',
        get: function get() {
            return {
                // paramSerializer: '$httpParamSerializerJQLike',
            };
        }
    }]);

    return _class;
}();

exports.default = _class;