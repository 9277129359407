'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'app.order-create',
    url: '/orders/create/',
    template: '<nlc-order-form controller=\'form\'/>',
    title: 'ORDERS.CREATE.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'ORDERS.CREATE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.ORDERS.CREATE',
        priority: 0,
        tag: 'sidebar topmenu',
        icon: 'fa fa-clone'
    }
};