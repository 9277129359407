'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _menu = require('./menu.pug');

var _menu2 = _interopRequireDefault(_menu);

var _menu3 = require('./menu.controller');

var _menu4 = _interopRequireDefault(_menu3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppSidebarMenuDirective = {
    replace: true,
    template: (0, _menu2.default)({}),
    name: 'app-sidebar-menu',
    transclude: true,
    scope: {
        items: '<'
    },
    controller: _menu4.default
};
exports.default = AppSidebarMenuDirective;