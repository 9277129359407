'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _nlDataProgress = require('../tpls/nl-data-progress.pug');

var _nlDataProgress2 = _interopRequireDefault(_nlDataProgress);

var _nlDataProgress3 = require('../controllers/nl-data-progress.controller');

var _nlDataProgress4 = _interopRequireDefault(_nlDataProgress3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function _class() {
    'ngInject';

    _classCallCheck(this, _class);

    Object.assign(this, {
        replace: true,
        restrict: 'E',
        scope: {
            promise: "="
        },
        controller: _nlDataProgress4.default,
        template: (0, _nlDataProgress2.default)({}),
        bindToController: true,
        controllerAs: '$ctrl'
    });
};

exports.default = _class;