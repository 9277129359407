'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _notFound = require('./not-found.state');

var _notFound2 = _interopRequireDefault(_notFound);

var _notFound3 = require('./not-found.component');

var _notFound4 = _interopRequireDefault(_notFound3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.common.not-found', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_notFound2.default.name, _notFound2.default);
}).component(_notFound4.default.name.camelCase(), _notFound4.default);