'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _action = require('./action.component');

var _action2 = _interopRequireDefault(_action);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.action',
    url: '/actions/',
    template: '<' + _action2.default.name + '/>',
    title: 'ACTION.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'ACTION.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.ACTION',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-map'
    }
};