'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

require('./password.scss');

var _password = require('./password.state');

var _password2 = _interopRequireDefault(_password);

var _password3 = require('./password.service');

var _password4 = _interopRequireDefault(_password3);

var _forgot = require('./forgot/forgot');

var _forgot2 = _interopRequireDefault(_forgot);

var _reset = require('./reset/reset');

var _reset2 = _interopRequireDefault(_reset);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.password', [_forgot2.default.name, _reset2.default.name]).config(function ($stateProvider, DEFAULT_STATE) {
    'ngInject';

    $stateProvider.state(_password2.default.name, _angular2.default.merge(_password2.default, {
        data: {
            permissions: {
                only: 'ANONYMOUS',
                redirectTo: DEFAULT_STATE
            }
        }
    }));
}).service('PasswordService', _password4.default);