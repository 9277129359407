'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _reset = require('./reset.component');

var _reset2 = _interopRequireDefault(_reset);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'password.reset',
    url: '/reset/:token/:email',
    template: '<' + _reset2.default.name + '/>',
    title: 'RESET.TITLE'
};