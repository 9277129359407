'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _alert = require('./alert.pug');

var _alert2 = _interopRequireDefault(_alert);

var _alert3 = require('./alert.controller');

var _alert4 = _interopRequireDefault(_alert3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _alert2.default)({}),
    name: 'alert-dialog',
    controller: _alert4.default
};