'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(ProductCalcService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            ProductCalcService: ProductCalcService
        });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            this.request = _angular2.default.copy(this.calc);
        }
    }, {
        key: 'submit',
        value: function submit() {
            var _this = this;

            if (this.calc.id) {
                this.promise = this.ProductCalcService.update(this.calc.id, this.request).then(function (calc) {
                    return _this.resolve(calc);
                });
            } else {
                this.promise = this.ProductCalcService.store(this.request).then(function (calc) {
                    return resolve(calc);
                });
            }
        }
    }, {
        key: 'resolve',
        value: function resolve(calc) {
            Object.assign(this.calc, calc);
            this.onResolve({ calc: calc });
        }
    }, {
        key: 'reject',
        value: function reject() {
            this.onReject({ calc: this.calc });
        }
    }]);

    return _class;
}();

exports.default = _class;