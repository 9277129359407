'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($http, API_ENDPOINT) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $http: $http, API_ENDPOINT: API_ENDPOINT });
    }

    _createClass(_class, [{
        key: 'endpoint',
        value: function endpoint() {
            var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return '' + this.API_ENDPOINT;
        }
    }, {
        key: 'store',
        value: function store(data) {
            var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            return this.$http.post(this.endpoint(data), data, _angular2.default.merge(this.options, { params: params })).then(function (_ref) {
                var data = _ref.data;
                return data;
            });
        }
    }, {
        key: 'update',
        value: function update(id, data) {
            var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return this.$http.put(this.endpoint(data) + id, data, _angular2.default.merge(this.options, { params: params })).then(function (_ref2) {
                var data = _ref2.data;
                return data;
            });
        }
    }, {
        key: 'paginate',
        value: function paginate(params) {
            return this.$http.get(this.endpoint(params), _angular2.default.merge(this.options, { params: params })).then(function (_ref3) {
                var data = _ref3.data;
                return data;
            });
        }
    }, {
        key: 'data',
        value: function data(params) {
            return this.paginate(params).then(function (_ref4) {
                var data = _ref4.data;
                return data;
            });
        }
    }, {
        key: 'destroy',
        value: function destroy(id) {
            var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            return this.$http.delete(this.endpoint(data) + id, this.options).then(function (_ref5) {
                var data = _ref5.data;
                return data;
            });
        }
    }, {
        key: 'get',
        value: function get(id) {
            var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            return this.$http.get(this.endpoint(params) + id, _angular2.default.merge(this.options, { params: params })).then(function (_ref6) {
                var data = _ref6.data;
                return data;
            });
        }
    }, {
        key: 'options',
        get: function get() {
            return {
                // paramSerializer: '$httpParamSerializerJQLike',
            };
        }
    }]);

    return _class;
}();

exports.default = _class;