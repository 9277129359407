'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _user = require('./user/user');

var _user2 = _interopRequireDefault(_user);

var _order = require('./order/order');

var _order2 = _interopRequireDefault(_order);

var _orderItem = require('./order-item/order-item');

var _orderItem2 = _interopRequireDefault(_orderItem);

var _orderDelivery = require('./order-delivery/order-delivery');

var _orderDelivery2 = _interopRequireDefault(_orderDelivery);

var _create = require('./order/create/create');

var _create2 = _interopRequireDefault(_create);

var _update = require('./order/update/update');

var _update2 = _interopRequireDefault(_update);

var _transaction = require('./transaction/transaction');

var _transaction2 = _interopRequireDefault(_transaction);

var _invoice = require('./invoice/invoice');

var _invoice2 = _interopRequireDefault(_invoice);

var _client = require('./client/client');

var _client2 = _interopRequireDefault(_client);

var _status = require('./status/status');

var _status2 = _interopRequireDefault(_status);

var _product = require('./product/product');

var _product2 = _interopRequireDefault(_product);

var _office = require('./office/office');

var _office2 = _interopRequireDefault(_office);

var _service = require('./service/service');

var _service2 = _interopRequireDefault(_service);

var _parameter = require('./parameter/parameter');

var _parameter2 = _interopRequireDefault(_parameter);

var _dictionary = require('./dictionary/dictionary');

var _dictionary2 = _interopRequireDefault(_dictionary);

var _data = require('./data.array/data.array');

var _data2 = _interopRequireDefault(_data);

var _report = require('./report/report');

var _report2 = _interopRequireDefault(_report);

var _action = require('./action/action');

var _action2 = _interopRequireDefault(_action);

var _source = require('./source/source');

var _source2 = _interopRequireDefault(_source);

var _value = require('./value/value');

var _value2 = _interopRequireDefault(_value);

var _dataArray = require('./data.array.value/data.array.value');

var _dataArray2 = _interopRequireDefault(_dataArray);

var _deliveryType = require('./delivery-type/delivery-type');

var _deliveryType2 = _interopRequireDefault(_deliveryType);

var _reward = require('./reward/reward');

var _reward2 = _interopRequireDefault(_reward);

var _test = require('./test/test');

var _test2 = _interopRequireDefault(_test);

var _components = require('./components');

var _components2 = _interopRequireDefault(_components);

var _content = require('./content');

var _content2 = _interopRequireDefault(_content);

var _calculation = require('./calculation/calculation');

var _calculation2 = _interopRequireDefault(_calculation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages', [_components2.default.name,
// Order.name,
// OrderItem.name,
// OrderDelivery.name,
// OrderCreate.name,
// OrderUpdate.name,
// Transaction.name,
// Invoice.name,
// Client.name,
_status2.default.name, _product2.default.name, _office2.default.name, _service2.default.name,
// Reward.name,
_parameter2.default.name, _dictionary2.default.name, _data2.default.name, _report2.default.name, _action2.default.name, _value2.default.name, _dataArray2.default.name, _user2.default.name,
// Content.name,
_calculation2.default.name]
// Source.name,
// DeliveryType.name,
// Test.name,
);

// import Statistic from './statistic/statistic'