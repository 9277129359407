'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _interceptor = require('factories/interceptor');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ErrorInterceptor = function (_Interceptor) {
    _inherits(ErrorInterceptor, _Interceptor);

    function ErrorInterceptor($q, $injector) {
        'ngInject';

        _classCallCheck(this, ErrorInterceptor);

        return _possibleConstructorReturn(this, (ErrorInterceptor.__proto__ || Object.getPrototypeOf(ErrorInterceptor)).call(this, $q, $injector));
    }

    _createClass(ErrorInterceptor, [{
        key: 'condition',
        value: function condition(response) {
            return -1 !== this.codes.indexOf(response.status);
        }
    }, {
        key: 'action',
        value: function action(response) {
            // Pick toastr type according to code, default to 'error'
            var method = [408].indexOf(response.status) ? 'error' : 'warning';
            this.toast[method]('ERROR.' + response.status + '.TITLE', 'ERROR.' + response.status + '.CONTENT', response);
        }
    }, {
        key: 'toast',
        get: function get() {
            return this.$injector.get('TranslateToastService');
        }
    }, {
        key: 'codes',
        get: function get() {
            return [400, 403, 404, 405, 406, 408, 409, 429, 500, -1];
        }
    }]);

    return ErrorInterceptor;
}(_interceptor.Interceptor);

exports.default = (0, _interceptor.InterceptorWrapper)(ErrorInterceptor, { responseError: true });