'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _forgot = require('./forgot.state');

var _forgot2 = _interopRequireDefault(_forgot);

var _forgot3 = require('./forgot.component');

var _forgot4 = _interopRequireDefault(_forgot3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('password.forgot', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_forgot2.default.name, _forgot2.default);
}).component(_forgot4.default.name.camelCase(), _forgot4.default);