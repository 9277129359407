'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _deliveryType = require('./delivery-type.pug');

var _deliveryType2 = _interopRequireDefault(_deliveryType);

var _deliveryType3 = require('./delivery-type.controller');

var _deliveryType4 = _interopRequireDefault(_deliveryType3);

require('./delivery-type.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _deliveryType2.default)({}),
    controller: _deliveryType4.default,
    name: 'nlc-delivery-types-page'
};