'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dialog = require('modules/dialog/controllers/dialog.controller');

var _dialog2 = _interopRequireDefault(_dialog);

var _store = require('../../../legal-entities/dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

var _update = require('../../../legal-entities/dialog/update/update.dialog');

var _update2 = _interopRequireDefault(_update);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DialogController) {
    _inherits(_class, _DialogController);

    function _class($scope, TranslateDialogService, ClientLegalEntityService, InvoiceService, InvoicePolicy, OrderService, OrderItemService, CacheValueService, order) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, $scope));

        Object.assign(_this, {
            InvoiceService: InvoiceService,
            hasOrder: order !== undefined,
            OrderService: OrderService,
            policy: InvoicePolicy,
            OrderItemService: OrderItemService,
            CacheValueService: CacheValueService,
            order: order,
            request: { items: [] },
            dialog: TranslateDialogService,
            ClientLegalEntityService: ClientLegalEntityService
        });
        if (_this.hasOrder) {
            _this.request.order_id = order.id;
        }
        return _this;
    }

    _createClass(_class, [{
        key: 'addLegalEntity',
        value: function addLegalEntity(legalEntityCtrl) {
            var _this2 = this;

            return this.dialog.open(_store2.default, { client_id: this.order.client_id }).then(function (legalEntity) {
                try {
                    _lodash2.default.set(_this2, 'request.invoice.client_legal_entity_id', legalEntity.id);
                    legalEntityCtrl.refresh();
                    Object.assign(_this2.legalEntity, legalEntity);
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }, {
        key: 'editLegalEntity',
        value: function editLegalEntity(legalEntity, legalEntityCtrl) {
            var _this3 = this;

            return this.dialog.open(_update2.default, { client_id: this.order.client_id, legalEntity: legalEntity }).then(function (legalEntity) {
                try {
                    _lodash2.default.set(_this3, 'request.invoice.client_legal_entity_id', legalEntity.id);
                    legalEntityCtrl.refresh();
                    Object.assign(_this3.legalEntity, legalEntity);
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }, {
        key: 'submit',
        value: function submit() {
            var _this4 = this;

            this.request.type = angular.isDefined(this.order) ? 'order' : 'another';
            this.promise = this.InvoiceService.store(this.request).then(function (invoice) {
                return _this4.resolve(invoice);
            });
        }
    }, {
        key: 'amount',
        get: function get() {
            return _lodash2.default.sumBy(this.request.items, 'total_price');
        }
    }]);

    return _class;
}(_dialog2.default);

exports.default = _class;