'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _client = require('./client.choice');

var _client2 = _interopRequireDefault(_client);

var _clientLegalEntity = require('./client.legal.entity.choice');

var _clientLegalEntity2 = _interopRequireDefault(_clientLegalEntity);

var _status = require('./status.choice');

var _status2 = _interopRequireDefault(_status);

var _product = require('./product.choice');

var _product2 = _interopRequireDefault(_product);

var _operation = require('./operation.choice');

var _operation2 = _interopRequireDefault(_operation);

var _service = require('./service.choice');

var _service2 = _interopRequireDefault(_service);

var _parameter = require('./parameter.choice');

var _parameter2 = _interopRequireDefault(_parameter);

var _constant = require('./constant.choice');

var _constant2 = _interopRequireDefault(_constant);

var _dictionary = require('./dictionary.choice');

var _dictionary2 = _interopRequireDefault(_dictionary);

var _dataArray = require('./data.array.choice');

var _dataArray2 = _interopRequireDefault(_dataArray);

var _report = require('./report.choice');

var _report2 = _interopRequireDefault(_report);

var _action = require('./action.choice');

var _action2 = _interopRequireDefault(_action);

var _value = require('./value.choice');

var _value2 = _interopRequireDefault(_value);

var _dataArrayValue = require('./data.array.value.choice');

var _dataArrayValue2 = _interopRequireDefault(_dataArrayValue);

var _task = require('./task.choice');

var _task2 = _interopRequireDefault(_task);

var _calc = require('./calc.choice');

var _calc2 = _interopRequireDefault(_calc);

var _order = require('./order.choice');

var _order2 = _interopRequireDefault(_order);

var _item = require('./order/item.choice');

var _item2 = _interopRequireDefault(_item);

var _asset = require('./order/item/asset.choice');

var _asset2 = _interopRequireDefault(_asset);

var _invoice = require('./invoice.choice');

var _invoice2 = _interopRequireDefault(_invoice);

var _transaction = require('./transaction.choice');

var _transaction2 = _interopRequireDefault(_transaction);

var _valueAssociation = require('./value.association.choice');

var _valueAssociation2 = _interopRequireDefault(_valueAssociation);

var _office = require('./office.choice');

var _office2 = _interopRequireDefault(_office);

var _tenant = require('./tenant.choice');

var _tenant2 = _interopRequireDefault(_tenant);

var _reward = require('./reward.choice');

var _reward2 = _interopRequireDefault(_reward);

var _menu = require('./content/menu.choice');

var _menu2 = _interopRequireDefault(_menu);

var _menuItem = require('./content/menu.item.choice');

var _menuItem2 = _interopRequireDefault(_menuItem);

var _block = require('./content/block.choice');

var _block2 = _interopRequireDefault(_block);

var _page = require('./content/page.choice');

var _page2 = _interopRequireDefault(_page);

var _calculation = require('./calculation.choice');

var _calculation2 = _interopRequireDefault(_calculation);

var _source = require('./source.choice');

var _source2 = _interopRequireDefault(_source);

var _asset3 = require('./asset.choice');

var _asset4 = _interopRequireDefault(_asset3);

var _deliveryType = require('./delivery.type.choice');

var _deliveryType2 = _interopRequireDefault(_deliveryType);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.choices', []).service('ClientChoice', _client2.default).service('ClientLegalEntityChoice', _clientLegalEntity2.default).service('SourceChoice', _source2.default).service('StatusChoice', _status2.default).service('ProductChoice', _product2.default).service('OperationChoice', _operation2.default).service('ServiceChoice', _service2.default).service('ParameterChoice', _parameter2.default).service('ConstantChoice', _constant2.default).service('DictionaryChoice', _dictionary2.default).service('DataArrayChoice', _dataArray2.default).service('ReportChoice', _report2.default).service('ActionChoice', _action2.default).service('ValueChoice', _value2.default).service('DataArrayValueChoice', _dataArrayValue2.default).service('TaskChoice', _task2.default).service('CalcChoice', _calc2.default).service('OrderChoice', _order2.default).service('OrderItemChoice', _item2.default).service('OrderItemAssetChoice', _asset2.default).service('InvoiceChoice', _invoice2.default).service('TransactionChoice', _transaction2.default).service('ValueAssociationChoice', _valueAssociation2.default).service('OfficeChoice', _office2.default).service('TenantChoice', _tenant2.default).service('RewardChoice', _reward2.default).service('ContentBlockChoice', _block2.default).service('ContentMenuChoice', _menu2.default).service('ContentMenuItemChoice', _menuItem2.default).service('ContentPageChoice', _page2.default).service('CalculationChoice', _calculation2.default).service('AssetChoice', _asset4.default).service('DeliveryTypeChoice', _deliveryType2.default);