'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _store = require('../../../calculations/dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope, $state, TranslateDialogService, CacheProductService, CacheProductCalcService, OrderItemPolicy) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            $scope: $scope,
            $state: $state,
            dialog: TranslateDialogService,
            policy: OrderItemPolicy,
            CacheProductService: CacheProductService,
            CacheProductCalcService: CacheProductCalcService
        });
    }

    _createClass(_class, [{
        key: 'reportCalc',
        value: function reportCalc() {
            return this.dialog.open(_store2.default, { inputs: this.request.inputs, product_calc: this.mc }).then(function (updated) {
                return Object.assign(item, updated);
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            if (_angular2.default.isUndefined(this.request)) {
                this.request = {};
            } else {
                if (this.request.wish_readiness_date) {
                    this.request.wish_readiness_date = new Date(this.request.wish_readiness_date);
                }
            }
            // delete this.request.data
            this.$scope.$watch('$ctrl.request.price', function () {
                if (_angular2.default.isUndefined(_this.request)) {
                    return;
                }
                _this.request.total_price = Math.round(_this.request.price * 100) / 100;
            });
            this.$scope.$watch('$ctrl.request.total_price', function () {
                if (_angular2.default.isUndefined(_this.request)) {
                    return;
                }
                var diff = _this.request.total_price - _this.request.price;

                _this.request.discount_price = Math.round(Math.max(0, -diff) * 100) / 100;
                _this.request.additional_price = Math.round(Math.max(0, diff) * 100) / 100;
                _this.onCalc();
            });
            // console.log(this.request)
            this.$scope.$watch(function () {
                return _this.mc;
            }, function (value) {
                if (_angular2.default.isDefined(_this.mc)) {
                    if (_angular2.default.isDefined(_this.mc.data)) {
                        _this.request.inputs = {};
                        _this.request.price = 0;
                        _this.request.data = _this.mc.data;
                        // delete this.request.data;
                    }
                }
            });
        }
    }]);

    return _class;
}();

exports.default = _class;