'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _order = require('./order.component');

var _order2 = _interopRequireDefault(_order);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.order',
    url: '/orders/',
    template: '<' + _order2.default.name + '/>',
    title: 'ORDER.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'ORDER.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.ORDER',
        priority: 0,
        roles: ['admin', 'manager', 'designer', 'master', 'viewer'],
        tag: 'sidebar',
        icon: 'fa fa-toggle-on'
    }
};