'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _group = require('./group.pug');

var _group2 = _interopRequireDefault(_group);

var _group3 = require('./group.controller');

var _group4 = _interopRequireDefault(_group3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _group2.default)({}),
    controller: _group4.default,
    name: 'nlc-calc-group',
    bindings: {
        group: '<',
        request: '=',
        assocData: '=',
        dictionaries: '<',
        services: '<',
        outputs: '<',
        level: '<',
        results: '=',
        parent: '=',
        prices: '=?'
    },
    bindToController: true
};