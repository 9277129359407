'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(CacheDictionaryService, CacheServiceService, CacheParameterService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            CacheDictionaryService: CacheDictionaryService,
            CacheServiceService: CacheServiceService,
            CacheParameterService: CacheParameterService,
            ready: false,
            dictionaries: {},
            services: {},
            parameters: {}
        });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            var serviceIds = function serviceIds(_ref) {
                var services = _ref.services,
                    groups = _ref.groups;
                return _.uniq([].concat(_toConsumableArray(_.values(services)), _toConsumableArray(_.concat.apply(null, _.values(groups).map(function (group) {
                    return serviceIds(group);
                })))));
            };
            var resultables = function resultables(_ref2) {
                var components = _ref2.components,
                    outputs = _ref2.outputs,
                    groups = _ref2.groups;
                return [].concat(_toConsumableArray(_.values(components).filter(function (component) {
                    return component.resultable;
                })), _toConsumableArray(_.values(outputs).filter(function (output) {
                    return output.resultable;
                })), _toConsumableArray(_.concat.apply(null, _.values(groups).map(function (group) {
                    return resultables(group);
                }))));
            };
            var codes = _.uniq(resultables(this.data).map(function (output) {
                return output.base;
            }));

            this.promise = Promise.all([this.CacheDictionaryService.data({ codes: codes, limit: 999 }).then(function (data) {
                data.forEach(function (row) {
                    return _this.dictionaries[row.code] = row;
                });
            }), this.CacheServiceService.data({ ids: serviceIds(this.data), limit: 999 }).then(function (data) {
                data.forEach(function (row) {
                    return _this.services[row.id] = row;
                });
            })]
            // this.CacheParameterService.data({limit: 999}).then(data => {
            //     data.forEach(row => this.parameters[row.code] = row)
            // })
            ).then(function () {
                return _this.ready = true;
            });
            this.controller = this;
        }
    }]);

    return _class;
}();

exports.default = _class;