'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _page = require('./page.component');

var _page2 = _interopRequireDefault(_page);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * State
 */
exports.default = {
    name: 'app.conent-pages',
    url: '/content/pages/',
    template: '<' + _page2.default.name + '/>',
    title: 'CONTENT-PAGE.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'CONTENT-PAGE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.CONTENT-PAGE',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-image'
    }
};