'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope) {
        'ngInject';

        var _this = this;

        _classCallCheck(this, _class);

        Object.assign(this, { $scope: $scope });
        $scope.$on('modal.closing', function (event, reason, closed) {
            if (typeof _this.promise != 'undefined' && !_this.promise.$$state.status && !closed) {
                event.preventDefault();
            }
        });
    }

    _createClass(_class, [{
        key: 'resolve',
        value: function resolve() {
            var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            this.$scope.$close(data);
        }
    }, {
        key: 'reject',
        value: function reject() {
            var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            this.$scope.$dismiss(error);
        }
    }]);

    return _class;
}();

exports.default = _class;