'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _report = require('./report.component');

var _report2 = _interopRequireDefault(_report);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.report',
    url: '/reports/',
    template: '<' + _report2.default.name + '/>',
    title: 'REPORT.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'REPORT.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.REPORT',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-print'
    }
};