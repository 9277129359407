'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($q, TranslateToastService, ToastService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            $q: $q,
            ToastService: ToastService,
            TranslateToastService: TranslateToastService,
            require: '^form',
            scope: {
                promise: '=nlForm',
                transfer: '<',
                toasted: '<'
            }
        });
    }

    _createClass(_class, [{
        key: 'link',
        value: function link(scope, element, attrs, form) {
            var _this = this;

            form.$loading = false;
            Object.defineProperty(form, "$ready", {
                get: function get() {
                    return form.$valid && !form.$loading;
                },
                enumerable: true,
                configurable: true
            });
            scope.$watch('promise', function () {
                if (_angular2.default.isUndefined(scope.promise)) {
                    return;
                }
                form.$loading = true;
                _this.$q.when(scope.promise).finally(function () {
                    return form.$loading = false;
                }).catch(function (response) {
                    if (response.status == 422) {
                        var errors = response.data;
                        if (scope.toasted) {
                            _lodash2.default.values(errors).map(function (messages) {
                                return messages.join(', ');
                            }).forEach(function (message) {
                                return _this.ToastService.error('Ошибка', message);
                            });
                            _this.TranslateToastService.error('COMPONENTS.ORDERS.TOASTS.ERROR.TITLE', 'COMPONENTS.ORDERS.TOASTS.ERROR.MESSAGE');
                        }

                        // reset remote array to all form elements
                        for (var code in errors) {
                            var formControlCode = code.replace('.', '_');
                            if (_angular2.default.isUndefined(form[formControlCode])) continue;
                            var control = form[formControlCode];
                            control.$error.remote = null;
                        }

                        var _loop = function _loop(_code) {
                            var formControlCode = _code.replace('.', '_');
                            if (_angular2.default.isDefined((scope.transfer || {})[formControlCode])) {
                                formControlCode = scope.transfer[formControlCode];
                            }
                            if (_angular2.default.isUndefined(form[formControlCode])) return 'continue';
                            var control = form[formControlCode];
                            control.$setTouched(true);
                            if (!_angular2.default.isArray(control.$error.remote)) {
                                control.$setValidity('remote', false);
                                control.$error.remote = [];
                            }
                            _angular2.default.forEach(errors[_code], function (error) {
                                return control.$error.remote.push(error);
                            });
                        };

                        for (var _code in errors) {
                            var _ret = _loop(_code);

                            if (_ret === 'continue') continue;
                        }
                    }
                });
            });
        }
    }]);

    return _class;
}();

exports.default = _class;