'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _form = require('./form.pug');

var _form2 = _interopRequireDefault(_form);

var _form3 = require('./form.controller');

var _form4 = _interopRequireDefault(_form3);

require('./form.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _form2.default)({}),
    controller: _form4.default,
    name: 'nlc-order-item-form',
    bindings: {
        controller: '=',
        request: '=',
        onCalc: '&'
    },
    bindToController: true
};