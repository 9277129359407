'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nlValidationMessages = require('./nl-validation-messages.pug');

var _nlValidationMessages2 = _interopRequireDefault(_nlValidationMessages);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($rootScope, $translate) {
        _classCallCheck(this, _class);

        Object.assign(this, {
            $rootScope: $rootScope,
            $translate: $translate,
            require: '^nlInputContainer',
            replace: true,
            transclude: true,
            template: (0, _nlValidationMessages2.default)({}),
            scope: {
                label: '@'
            }
        });
    }

    _createClass(_class, [{
        key: 'init',
        value: function init(scope) {
            this.$translate(scope.label).then(function (name) {
                return scope.values = { name: name };
            }, function () {
                return scope.values = { name: scope.label };
            });
        }
    }, {
        key: 'link',
        value: function link(scope, element, attrs, nlInputContainerController) {
            var _this = this;

            scope.container = nlInputContainerController;
            this.init(scope);
            scope.$on('$destroy', this.$rootScope.$on('$translateChangeSuccess', function () {
                return _this.init(scope);
            }));
        }
    }]);

    return _class;
}();

exports.default = _class;