'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($http, API_ENDPOINT) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $http: $http, API_ENDPOINT: API_ENDPOINT });
    }

    _createClass(_class, [{
        key: 'endpoint',
        value: function endpoint() {
            var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return this.API_ENDPOINT + 'auth/';
        }
    }, {
        key: 'login',
        value: function login(data) {
            return this.$http.post(this.endpoint(data) + 'login/', data).then(function (_ref) {
                var data = _ref.data;
                return data;
            });
        }
    }, {
        key: 'register',
        value: function register(data) {
            return this.$http.post(this.endpoint(data) + 'register/', data).then(function (_ref2) {
                var data = _ref2.data;
                return data;
            });
        }
    }]);

    return _class;
}();

exports.default = _class;