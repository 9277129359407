'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AppSidebarMenuDirectiveController = function AppSidebarMenuDirectiveController($scope, AppSidebarMenuPolicy) {
    'ngInject';

    _classCallCheck(this, AppSidebarMenuDirectiveController);

    Object.assign($scope, { policy: AppSidebarMenuPolicy });
};

exports.default = AppSidebarMenuDirectiveController;