'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _associationGrid = require('./association-grid.pug');

var _associationGrid2 = _interopRequireDefault(_associationGrid);

var _associationGrid3 = require('./association-grid.controller');

var _associationGrid4 = _interopRequireDefault(_associationGrid3);

require('./association-grid.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _associationGrid2.default)({}),
    controller: _associationGrid4.default,
    name: 'nlc-association-grid',
    transclude: {
        head: '?head',
        filter: '?filter',
        body: '?body'
    },
    bindings: {
        service: '=',
        actionService: '=',
        entity: '=',
        entityName: '@'
    },
    bindToController: true
};