'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _test = require('./test.pug');

var _test2 = _interopRequireDefault(_test);

var _test3 = require('./test.controller');

var _test4 = _interopRequireDefault(_test3);

require('./test.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _test2.default)({}),
    controller: _test4.default,
    name: 'nlc-test-page'
};