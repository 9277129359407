'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _register = require('./register.pug');

var _register2 = _interopRequireDefault(_register);

var _register3 = require('./register.controller');

var _register4 = _interopRequireDefault(_register3);

require('./register.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _register2.default)({}),
    controller: _register4.default,
    name: 'register'
};