'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _deliveryType = require('./delivery-type.component');

var _deliveryType2 = _interopRequireDefault(_deliveryType);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.delivery-type',
    url: '/delivery-types/',
    template: '<' + _deliveryType2.default.name + '/>',
    title: 'COMPONENTS.DELIVERY-TYPES.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'COMPONENTS.DELIVERY-TYPES.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.DELIVERY-TYPE',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-map'
    }
};