'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _value = require('./value.pug');

var _value2 = _interopRequireDefault(_value);

var _value3 = require('./value.controller');

var _value4 = _interopRequireDefault(_value3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _value2.default)({}),
    controller: _value4.default,
    name: 'nlc-schema-input-value',
    bindings: {
        input: '='
    },
    bindToController: true
};