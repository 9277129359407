'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _orderItem = require('./order-item.component');

var _orderItem2 = _interopRequireDefault(_orderItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.order-item',
    url: '/order-items/',
    template: '<' + _orderItem2.default.name + '/>',
    title: 'ORDER_ITEM.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'ORDER_ITEM.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.ORDER_ITEM',
        priority: 0,
        roles: ['admin', 'manager', 'designer', 'master', 'viewer'],
        tag: 'sidebar',
        icon: 'fa fa-toggle-on'
    }
};