'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var detectType = exports.detectType = function detectType(dictionaries, base) {
    var _ref = dictionaries[base] || { type: 'simple' },
        type = _ref.type;

    var types = {
        dictionary: 2,
        simple: 1,
        smart: 3
    };
    return types[type];
};
var detectSubtype = exports.detectSubtype = function detectSubtype(dictionaries, base, type) {
    var defaultValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'number';

    if (type == 1) {
        return ((dictionaries[base] || { schema: { type: defaultValue } }).schema || { type: defaultValue }).type || defaultValue;
    }
    if (type == 3) {
        return ((dictionaries[base] || { schema: { smart: defaultValue } }).schema || { smart: defaultValue }).smart || defaultValue;
    }
    return defaultValue;
};