'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $scope: $scope });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            if (_angular2.default.isUndefined(this.valueField)) {
                this.valueField = 'id';
            }
            if (_angular2.default.isUndefined(this.request)) {
                this.request = {};
            }
            if (_angular2.default.isUndefined(this.field)) {
                this.field = 'name';
            }
            if (_angular2.default.isUndefined(this.termField)) {
                this.termField = this.field;
            }
            // this.ngModel.$render = () => Object.assign(this, {model: this.ngModel.$viewValue})
            this.$scope.$watch('$ctrl.selected', function (value) {
                return _this.select(value);
            });
        }
    }, {
        key: 'select',
        value: function select(row) {
            this.selected = row;
            this.ngModel.$setViewValue(row ? row[this.valueField] : null);
        }
    }, {
        key: 'data',
        value: function data(term) {
            return this.service.data(_extends({}, this.request, _defineProperty({}, this.termField, term)));
        }
    }, {
        key: 'clear',
        value: function clear() {
            this.select(null);
        }
    }]);

    return _class;
}();

exports.default = _class;