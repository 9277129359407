'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calculation = require('./calculation.pug');

var _calculation2 = _interopRequireDefault(_calculation);

var _calculation3 = require('./calculation.controller');

var _calculation4 = _interopRequireDefault(_calculation3);

require('./calculation.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calculation2.default)({}),
    controller: _calculation4.default,
    name: 'nlc-calculations-page'
};