'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _invoice = require('./invoice.pug');

var _invoice2 = _interopRequireDefault(_invoice);

var _invoice3 = require('./invoice.controller');

var _invoice4 = _interopRequireDefault(_invoice3);

require('./invoice.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _invoice2.default)({}),
    controller: _invoice4.default,
    name: 'nlc-invoices-page'
};