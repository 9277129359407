'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dataArray = require('./data.array.component');

var _dataArray2 = _interopRequireDefault(_dataArray);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.data-array',
    url: '/data-arrays/',
    template: '<' + _dataArray2.default.name + '/>',
    title: 'DATA_ARRAY.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'DATA_ARRAY.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.DATA_ARRAY',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-map'
    }
};