'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _appFooter = require('./app-footer.pug');

var _appFooter2 = _interopRequireDefault(_appFooter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppFooterComponent = {
    template: (0, _appFooter2.default)({}),
    name: 'app-footer'
};

exports.default = AppFooterComponent;