'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _reportDialog = require('./report.dialog.pug');

var _reportDialog2 = _interopRequireDefault(_reportDialog);

var _reportDialog3 = require('./report.dialog.controller');

var _reportDialog4 = _interopRequireDefault(_reportDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _reportDialog2.default)({}),
    controller: _reportDialog4.default,
    name: 'nlc-report-dialog',
    size: 'lg'
};