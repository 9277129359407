'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function _class(CacheUserService, OrderService, CacheValueService) {
    'ngInject';

    _classCallCheck(this, _class);

    Object.assign(this, {
        filter: {
            value: CacheValueService,
            user: CacheUserService,
            order: OrderService
        }
    });
};

exports.default = _class;