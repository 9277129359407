'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dataArrays = require('./data.arrays.pug');

var _dataArrays2 = _interopRequireDefault(_dataArrays);

var _dataArrays3 = require('./data.arrays.controller');

var _dataArrays4 = _interopRequireDefault(_dataArrays3);

require('./data.arrays.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _dataArrays2.default)({}),
    controller: _dataArrays4.default,
    name: 'nlc-data-arrays',
    bindings: {
        controller: '='
    },
    bindToControler: true
};