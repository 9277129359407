'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _notFound = require('./not-found.pug');

var _notFound2 = _interopRequireDefault(_notFound);

var _notFound3 = require('./not-found.controller');

var _notFound4 = _interopRequireDefault(_notFound3);

require('./not-found.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _notFound2.default)({}),
    controller: _notFound4.default,
    name: 'not-found'
};