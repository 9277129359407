'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nlDataOrderBy = require('../tpls/nl-data-order-by.pug');

var _nlDataOrderBy2 = _interopRequireDefault(_nlDataOrderBy);

var _nlDataOrderBy3 = require('../controllers/nl-data-order-by.controller');

var _nlDataOrderBy4 = _interopRequireDefault(_nlDataOrderBy3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($compile) {
        _classCallCheck(this, _class);

        Object.assign(this, {
            $compile: $compile,
            restrict: 'A',
            scope: {
                field: '@nlDataOrderBy'
            },
            require: ['nlDataOrderBy', '^nlData'],
            controllerAs: '$ctrl',
            controller: _nlDataOrderBy4.default,
            bindToController: true
        });
    }

    _createClass(_class, [{
        key: 'link',
        value: function link($scope, $element, $attrs, $ctrls) {
            var _$ctrls = _slicedToArray($ctrls, 2),
                nlDataOrderBy = _$ctrls[0],
                nlData = _$ctrls[1];

            nlDataOrderBy.nlData = nlData;
            $element.on('click', function () {
                return nlDataOrderBy.sort();
            });
            $element.append(this.$compile((0, _nlDataOrderBy2.default)({}))($scope));
        }
    }]);

    return _class;
}();

exports.default = _class;