'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _create = require('./create.state');

var _create2 = _interopRequireDefault(_create);

var _typeahead = require('angular-ui-bootstrap/src/typeahead');

var _typeahead2 = _interopRequireDefault(_typeahead);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('app.pages.order.create', [_typeahead2.default]).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_create2.default.name, _create2.default);
});