'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _orderDelivery = require('./order-delivery.pug');

var _orderDelivery2 = _interopRequireDefault(_orderDelivery);

var _orderDelivery3 = require('./order-delivery.controller');

var _orderDelivery4 = _interopRequireDefault(_orderDelivery3);

require('./order-delivery.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _orderDelivery2.default)({}),
    controller: _orderDelivery4.default,
    name: 'nlc-order-deliveries-page'
};