'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Factory = exports.Output = exports.Component = exports.Service = exports.Node = exports.Group = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Node = function () {
    function Node(node, rawParent) {
        var api = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        _classCallCheck(this, Node);

        Object.assign(this, { node: node, rawParent: rawParent, api: api });
    }

    _createClass(Node, [{
        key: 'child',
        value: function child(type, filter) {
            if (angular.isUndefined(this.node.children)) {
                return undefined;
            }
            var child = this.node.children.filter(function (child) {
                return child.type == type && filter(child);
            }).pop();
            if (child) {
                return Factory.create(child, this.node, this.api);
            }
            return undefined;
        }
    }, {
        key: 'children',
        value: function children(type) {
            var _this = this;

            var childs = this.node.children || [];
            return childs.filter(function (child) {
                return child.type == type;
            }).map(function (child) {
                return Factory.create(child, _this.node, _this.api);
            });
        }
    }, {
        key: 'rawChildren',
        value: function rawChildren(type) {
            var childs = this.node.children || [];
            return childs.filter(function (child) {
                return child.type == type;
            });
        }
    }, {
        key: 'remove',
        value: function remove() {
            var _this2 = this;

            if (this.rawParent !== null) {
                this.rawParent.children = _lodash2.default.remove(this.rawParent.children, function (el) {
                    return el !== _this2.node;
                });
            }
        }
    }, {
        key: 'addChild',
        value: function addChild(child) {
            var unique = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            if (angular.isUndefined(this.node.children)) {
                this.node.children = [];
            }
            if (unique !== false) {
                var foundNode = this.node.children.filter(unique).pop();
                if (foundNode) {
                    return Factory.create(foundNode, this.node, this.api);
                }
            }
            this.node.children.push(child);
            return Factory.create(child, this.node, this.api);
        }
    }, {
        key: 'rebuild',
        value: function rebuild() {}
    }, {
        key: 'getSiblings',
        value: function getSiblings() {
            var _this3 = this;

            if (this.rawParent == null) return [];
            return this.rawParent.children.filter(function (child) {
                return child.type == _this3.node.type && child != _this3.node;
            });
        }
    }]);

    return Node;
}();

var GroupChildNode = function (_Node) {
    _inherits(GroupChildNode, _Node);

    function GroupChildNode() {
        _classCallCheck(this, GroupChildNode);

        return _possibleConstructorReturn(this, (GroupChildNode.__proto__ || Object.getPrototypeOf(GroupChildNode)).apply(this, arguments));
    }

    _createClass(GroupChildNode, [{
        key: 'group',
        get: function get() {
            return Factory.create(this.rawParent, null, this.api);
        }
    }]);

    return GroupChildNode;
}(Node);

var Component = function (_GroupChildNode) {
    _inherits(Component, _GroupChildNode);

    function Component() {
        _classCallCheck(this, Component);

        return _possibleConstructorReturn(this, (Component.__proto__ || Object.getPrototypeOf(Component)).apply(this, arguments));
    }

    _createClass(Component, [{
        key: 'remove',
        value: function remove() {
            var _this6 = this;

            _get(Component.prototype.__proto__ || Object.getPrototypeOf(Component.prototype), 'remove', this).call(this);
            if (this.node.moved) {
                this.group.getGroups().forEach(function (group) {
                    group.getComponents().filter(function (component) {
                        return component.node.base == _this6.node.base;
                    }).forEach(function (component) {
                        return component.moveBack();
                    });
                });
            }
            if (this.node.system) {
                this.group.pullExtra(this.node.base);
            }
        }
    }, {
        key: 'moveUp',
        value: function moveUp(parents) {
            var _parents = _slicedToArray(parents, 2),
                rawParent = _parents[0],
                rawRootParent = _parents[1];

            var group = Factory.create(rawParent, rawRootParent, this.api);
            if (group.group) {
                group.group.addComponent(this.node.base, true);
                this.node.hidden = true;
            }
            return this;
        }
    }, {
        key: 'moveBack',
        value: function moveBack() {
            this.node.hidden = false;
            delete this.node.hidden;
            return this;
        }
    }]);

    return Component;
}(GroupChildNode);

var Output = function (_GroupChildNode2) {
    _inherits(Output, _GroupChildNode2);

    function Output() {
        _classCallCheck(this, Output);

        return _possibleConstructorReturn(this, (Output.__proto__ || Object.getPrototypeOf(Output)).apply(this, arguments));
    }

    return Output;
}(GroupChildNode);

var Service = function (_GroupChildNode3) {
    _inherits(Service, _GroupChildNode3);

    function Service() {
        _classCallCheck(this, Service);

        return _possibleConstructorReturn(this, (Service.__proto__ || Object.getPrototypeOf(Service)).apply(this, arguments));
    }

    _createClass(Service, [{
        key: 'rebuild',
        value: function rebuild() {
            var _this9 = this;

            this.node.inputs.forEach(function (base) {
                return _this9.group.addComponent(base);
            });
            this.node.outputs.forEach(function (base) {
                return _this9.group.addOutput(base);
            });
        }
    }, {
        key: 'refresh',
        value: function refresh() {
            var _this10 = this;

            var id = this.node.id;
            this.node.loading = true;
            return this.api.ServiceService.get(id).then(function (_ref) {
                var schema = _ref.schema,
                    name = _ref.name;

                _this10.node.name = name;
                _this10.node.inputs = _lodash2.default.unionBy(_lodash2.default.keys(schema.condition.inputs), _lodash2.default.keys(schema.source.inputs));
                _this10.node.outputs = _lodash2.default.keys(schema.source.outputs);
            }).then(function () {
                return _this10.rebuild();
            }).finally(function () {
                return delete _this10.node.loading;
            });
            return this;
        }
    }, {
        key: 'remove',
        value: function remove() {
            _get(Service.prototype.__proto__ || Object.getPrototypeOf(Service.prototype), 'remove', this).call(this);
            this.group.clear();
        }
    }]);

    return Service;
}(GroupChildNode);

var Group = function (_GroupChildNode4) {
    _inherits(Group, _GroupChildNode4);

    function Group() {
        _classCallCheck(this, Group);

        return _possibleConstructorReturn(this, (Group.__proto__ || Object.getPrototypeOf(Group)).apply(this, arguments));
    }

    _createClass(Group, [{
        key: 'getComponents',
        value: function getComponents() {
            return _get(Group.prototype.__proto__ || Object.getPrototypeOf(Group.prototype), 'children', this).call(this, 'component');
        }
    }, {
        key: 'getGroups',
        value: function getGroups() {
            return _get(Group.prototype.__proto__ || Object.getPrototypeOf(Group.prototype), 'children', this).call(this, 'group');
        }
    }, {
        key: 'addGroup',
        value: function addGroup(name) {
            return _get(Group.prototype.__proto__ || Object.getPrototypeOf(Group.prototype), 'addChild', this).call(this, {
                type: 'group',
                id: (0, _v2.default)().slice(0, 8),
                name: name
            });
        }
    }, {
        key: 'addService',
        value: function addService(id, name) {
            var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            var initiated = angular.isDefined(data.schema);

            var service = _get(Group.prototype.__proto__ || Object.getPrototypeOf(Group.prototype), 'addChild', this).call(this, {
                inputs: initiated ? _lodash2.default.unionBy(_lodash2.default.keys(data.schema.condition.inputs), _lodash2.default.keys(data.schema.source.inputs)) : [],
                outputs: initiated ? _lodash2.default.keys(data.schema.source.outputs) : [],
                type: 'service',
                id: id,
                name: name
            }, function (child) {
                return child.type == 'service' && child.id == id;
            });
            if (!initiated) {
                service.refresh();
            } else {
                service.rebuild();
            }
            return service;
        }
    }, {
        key: 'addComponent',
        value: function addComponent(base) {
            var moved = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
            var system = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
            var smart = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

            return _get(Group.prototype.__proto__ || Object.getPrototypeOf(Group.prototype), 'addChild', this).call(this, {
                name: this.api.names[base] || base,
                type: 'component',
                smart: smart,
                system: system,
                moved: moved,
                base: base
            }, function (child) {
                return child.type == 'component' && child.base == base;
            });
        }
    }, {
        key: 'addOutput',
        value: function addOutput(base) {
            return _get(Group.prototype.__proto__ || Object.getPrototypeOf(Group.prototype), 'addChild', this).call(this, {
                name: this.api.names[base] || base,
                type: 'output',
                base: base
            }, function (child) {
                return child.type == 'output' && child.base == base;
            });
        }
    }, {
        key: 'removeComponent',
        value: function removeComponent(base) {
            var child = _get(Group.prototype.__proto__ || Object.getPrototypeOf(Group.prototype), 'child', this).call(this, 'component', function (child) {
                return child.base == base;
            });
            var rawInputs = this.getRawInputs();
            if (child && !rawInputs.some(function (base) {
                return base == child.node.base;
            })) {
                child.remove();
            }
        }
    }, {
        key: 'pullExtra',
        value: function pullExtra(base) {
            _lodash2.default.pull(this.node.extras, base);
        }
    }, {
        key: 'addExtra',
        value: function addExtra(base) {
            this.addComponent(base, false, true);
            if (!this.node.extras) {
                this.node.extras = [base];
            } else {
                this.node.extras.push(base);
            }
        }
    }, {
        key: 'clear',
        value: function clear() {
            this._clearComponents();
            this._clearOutputs();
        }
    }, {
        key: 'rebuild',
        value: function rebuild() {
            this.children('service').forEach(function (service) {
                return service.rebuild();
            });
            this.getGroups().forEach(function (group) {
                return group.rebuild();
            });
            this.clear();
        }
    }, {
        key: 'getRawInputs',
        value: function getRawInputs() {
            return _lodash2.default.uniq([].concat(_toConsumableArray(_lodash2.default.concat.apply(null, this.rawChildren('service').map(function (node) {
                return node.inputs;
            }))), _toConsumableArray(this.getGroups().map(function (group) {
                return group.rawInputs;
            }))));
        }
    }, {
        key: 'getRawOutputs',
        value: function getRawOutputs() {
            return _lodash2.default.uniq([].concat(_toConsumableArray(_lodash2.default.concat.apply(null, this.rawChildren('service').map(function (node) {
                return node.outputs;
            }))), _toConsumableArray(this.getGroups().map(function (group) {
                return group.rawOutputs;
            }))));
        }
    }, {
        key: '_clearComponents',
        value: function _clearComponents() {
            var _this12 = this;

            var rawInputs = this.getRawInputs();
            //remove unused
            this.rawChildren('component').forEach(function (node) {
                if (!node.moved && !node.system && !rawInputs.some(function (base) {
                    return base == node.base;
                })) {
                    Factory.create(node, _this12.node, _this12.api).remove();
                }
            });
        }
    }, {
        key: '_clearOutputs',
        value: function _clearOutputs() {
            var _this13 = this;

            var rawOutputs = this.getRawOutputs();
            //remove unused outputs
            this.rawChildren('output').filter(function (node) {
                return !rawOutputs.some(function (base) {
                    return base == node.base;
                });
            }).forEach(function (node) {
                return Factory.create(node, _this13.node, _this13.api).remove();
            });
        }
    }]);

    return Group;
}(GroupChildNode);

var Factory = function () {
    function Factory() {
        _classCallCheck(this, Factory);
    }

    _createClass(Factory, null, [{
        key: 'create',
        value: function create(node, parent, api) {
            if (node == null) {
                return null;
            }
            switch (node.type) {
                case 'service':
                    {
                        return new Service(node, parent, api);
                    }
                case 'component':
                    {
                        return new Component(node, parent, api);
                    }
                case 'group':
                    {
                        return new Group(node, parent, api);
                    }
                case 'output':
                    {
                        return new Output(node, parent, api);
                    }

            }
            return new Node(node, parent, api);
        }
    }]);

    return Factory;
}();

exports.Group = Group;
exports.Node = Node;
exports.Service = Service;
exports.Component = Component;
exports.Output = Output;
exports.Factory = Factory;