'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class() {
        _classCallCheck(this, _class);

        Object.assign(this, {
            restrict: 'A',
            scope: {
                nlPromiseClick: '&',
                promising: '='
            }
        });
    }

    _createClass(_class, [{
        key: 'link',
        value: function link($scope, $element, $attrs) {
            $element.on('click', function () {
                $scope.promising = true;
                $scope.nlPromiseClick().finally(function () {
                    return $scope.promising = false;
                });
            });
        }
    }]);

    return _class;
}();

exports.default = _class;