'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _appHeader = require('./app-header/app-header.component');

var _appHeader2 = _interopRequireDefault(_appHeader);

var _menu = require('./app-sidebar/menu/menu.directive');

var _menu2 = _interopRequireDefault(_menu);

var _appSidebar = require('./app-sidebar/app-sidebar.component');

var _appSidebar2 = _interopRequireDefault(_appSidebar);

var _appFooter = require('./app-footer/app-footer.component');

var _appFooter2 = _interopRequireDefault(_appFooter);

var _menu3 = require('./app-sidebar/menu/menu.policy');

var _menu4 = _interopRequireDefault(_menu3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppComponents = _angular2.default.module('app.components', []).component(_appHeader2.default.name.camelCase(), _appHeader2.default).service('AppSidebarMenuPolicy', _menu4.default).directive(_menu2.default.name.camelCase(), /*@ngInject*/function () {
    return _menu2.default;
}).component(_appSidebar2.default.name.camelCase(), _appSidebar2.default).component(_appFooter2.default.name.camelCase(), _appFooter2.default);

exports.default = AppComponents;