'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _deliveries = require('./deliveries.pug');

var _deliveries2 = _interopRequireDefault(_deliveries);

var _deliveries3 = require('./deliveries.controller');

var _deliveries4 = _interopRequireDefault(_deliveries3);

require('./items.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _deliveries2.default)({}),
    controller: _deliveries4.default,
    name: 'nlc-order-deliveries',
    bindings: {
        controller: '=',
        order: '<'
    },
    bindToController: true
};