'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.detectType = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _sprintfJs = require('sprintf-js');

var _report2 = require('../../../common/dialog/report/report.dialog');

var _report3 = _interopRequireDefault(_report2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var detectType = exports.detectType = function detectType(dictionaries, base) {
    var _ref = dictionaries[base] || { type: 'simple' },
        type = _ref.type;

    var types = {
        dictionary: 2,
        simple: 1
    };
    return types[type];
};

var _class = function () {
    function _class($scope, CacheReportService, OrderItemGroupService, TranslateDialogService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, {
            $scope: $scope,
            CacheReportService: CacheReportService,
            OrderItemGroupService: OrderItemGroupService,
            dialog: TranslateDialogService
        });
    }

    _createClass(_class, [{
        key: 'report',
        value: function report(_report) {
            var _this = this;

            return this.OrderItemGroupService.report(this.id, this.request).then(function (data) {
                return _this.dialog.open(_report3.default, { report: _report, data: data });
            });
        }
    }, {
        key: 'getValue',
        value: function getValue(meta) {
            if (meta.type == 'output') {
                return this.results.default.results.data[meta.base];
            }
            if (meta.moved && this.parent) {
                return this.parent.getValue(meta);
            }
            return this.inputs[this.id].data[meta.base];
        }
    }, {
        key: 'setGroupPrices',
        value: function setGroupPrices(prices) {
            this.prices.expenses += prices.expenses;
            this.prices.services += prices.services;
            if (this.parent) {
                this.parent.setGroupPrices(prices);
            }
        }
    }, {
        key: 'getComponentValue',
        value: function getComponentValue(component) {
            if (component.input.type == 2) {
                var format = '%(name)s';
                if (component.value.own && component.flexible_r_format) {
                    format = component.flexible_r_format;
                } else if (component.r_format) {
                    format = component.r_format;
                }
                return (0, _sprintfJs.sprintf)(format, component.value);
            } else {
                return component.value;
            }
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            this.id = this.group.id;
            var inputs = {};
            Object.assign(this, {
                groups: this.group.groups,
                prices: {
                    expenses: 0,
                    services: 0,
                    total: this.results.default.results.default
                },
                output: {
                    services: _lodash2.default.values(this.group.services).filter(function (id) {
                        return _this2.results.sources[id].results.default;
                    }).map(function (id) {
                        inputs = _extends({}, inputs, _this2.services[id].schema.condition.inputs || {}, _this2.services[id].schema.source.inputs || {});
                        return _extends({}, _this2.services[id], {
                            price: _this2.results.sources[id].results.default
                        });
                    }),
                    components: _lodash2.default.sortBy(_lodash2.default.uniqBy(
                    // concat
                    _lodash2.default.concat(_lodash2.default.values(this.group.components), _lodash2.default.values(this.group.outputs)).filter(function (output) {
                        return output.resultable;
                    }), 'base').map(function (output) {
                        return _extends({}, output, {
                            input: inputs[output.base] || { type: detectType(_this2.dictionaries, output.base) },
                            name: (_this2.dictionaries[output.base] || { name: output.base }).name,
                            value: _this2.getValue(output)
                        });
                    }), ['r_index', 'name'], ['asc', 'asc'])
                }
            });
            // this.output.parameters = _.values(this.results.parameters)
            //     .map(({id}) => Object.assign(this.parameters[id], {value: this.results.parameters[id].results.default }))
            this.output.services.forEach(function (service) {
                if (service.type == 'service') {
                    _this2.prices.services += service.price;
                } else {
                    _this2.prices.expenses += service.price;
                }
            });

            if (this.parent && this.inputs[this.id].confirm) {
                this.parent.setGroupPrices(this.prices);
            }
        }
    }]);

    return _class;
}();

exports.default = _class;