'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _rewards = require('./rewards.pug');

var _rewards2 = _interopRequireDefault(_rewards);

var _rewards3 = require('./rewards.controller');

var _rewards4 = _interopRequireDefault(_rewards3);

require('./rewards.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _rewards2.default)({}),
    controller: _rewards4.default,
    name: 'nlc-rewards',
    bindings: {
        controller: '='
    },
    bindToController: true
};