'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _rx = require('rx');

var _rx2 = _interopRequireDefault(_rx);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(localStorageService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { storage: localStorageService, out: new _rx2.default.ReplaySubject(1) });
        this.out.onNext(this.isAuthorized);
    }

    _createClass(_class, [{
        key: 'authorize',

        /**
         * Authorize new user in the app
         */
        value: function authorize(token) {
            this.token = token;
            this.out.onNext(this.isAuthorized);
        }
        /**
         * Clean all user credentials
         */

    }, {
        key: 'release',
        value: function release() {
            this.token = undefined;
            this.out.onNext(this.isAuthorized);
        }
        /**
         * isAuthorized property
         */

    }, {
        key: 'token',
        set: function set(value) {
            this.storage.set('auth.token', value);
        },
        get: function get() {
            return this.storage.get('auth.token');
        }
    }, {
        key: 'isAuthorized',
        get: function get() {
            //TODO: check jwt validity
            return _angular2.default.isDefined(this.token) && _angular2.default.isString(this.token);
        }
    }]);

    return _class;
}();

exports.default = _class;