'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _cmykPantoneSides = require('./cmyk-pantone-sides.pug');

var _cmykPantoneSides2 = _interopRequireDefault(_cmykPantoneSides);

var _cmykPantoneSides3 = require('./cmyk-pantone-sides.controller');

var _cmykPantoneSides4 = _interopRequireDefault(_cmykPantoneSides3);

require('./cmyk-pantone-sides.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _cmykPantoneSides2.default)({}),
    controller: _cmykPantoneSides4.default,
    name: 'nlc-cmyk-pantone-sides',
    bindings: {
        request: '=',
        sides: '<'
    },
    bindToController: true
};