'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _interceptor = require('../interceptor');

var _momentTimezoneWithData = require('moment-timezone/builds/moment-timezone-with-data-2012-2022');

var _momentTimezoneWithData2 = _interopRequireDefault(_momentTimezoneWithData);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TimezoneInterceptor = function (_Interceptor) {
    _inherits(TimezoneInterceptor, _Interceptor);

    function TimezoneInterceptor($q, $injector) {
        'ngInject';

        _classCallCheck(this, TimezoneInterceptor);

        return _possibleConstructorReturn(this, (TimezoneInterceptor.__proto__ || Object.getPrototypeOf(TimezoneInterceptor)).call(this, $q, $injector));
    }

    _createClass(TimezoneInterceptor, [{
        key: 'request',
        value: function request(config) {
            config.headers['X-Timezone'] = _momentTimezoneWithData2.default.tz.guess();
            return config;
        }
    }]);

    return TimezoneInterceptor;
}(_interceptor.Interceptor);

exports.default = (0, _interceptor.InterceptorWrapper)(TimezoneInterceptor, { request: true });