'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _appSidebar = require('./app-sidebar.pug');

var _appSidebar2 = _interopRequireDefault(_appSidebar);

var _appSidebar3 = require('./app-sidebar.controller');

var _appSidebar4 = _interopRequireDefault(_appSidebar3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AppSidebarComponent = {
    template: (0, _appSidebar2.default)({}),
    controller: _appSidebar4.default,
    name: 'app-sidebar'
};

exports.default = AppSidebarComponent;