'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _client = require('./client.component');

var _client2 = _interopRequireDefault(_client);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.client',
    url: '/clients/',
    template: '<' + _client2.default.name + '/>',
    title: 'CLIENT.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'CLIENT.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.CLIENT',
        priority: 2,
        roles: ['admin', 'manager', 'designer'],
        tag: 'sidebar',
        icon: 'fa fa-users'
    }
};