'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($stateParams, $state, PasswordService, AUTHENTICATION_STATE) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { data: $stateParams, PasswordService: PasswordService, $state: $state, AUTHENTICATION_STATE: AUTHENTICATION_STATE });
    }

    _createClass(_class, [{
        key: 'submit',
        value: function submit() {
            var _this = this;

            this.promise = this.PasswordService.reset(this.data).then(function () {
                return _this.$state.go(_this.AUTHENTICATION_STATE);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;