'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, [{
        key: 'is_flexible',
        value: function is_flexible(_ref) {
            var _is_flexible = _ref.is_flexible;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            return _is_flexible ? prefix + 'success' : prefix + 'default';
        }
    }, {
        key: 'is_system',
        value: function is_system(_ref2) {
            var _is_system = _ref2.is_system;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            return _is_system ? prefix + 'success' : prefix + 'default';
        }
    }, {
        key: 'type',
        value: function type(_ref3) {
            var _type = _ref3.type;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            return _type == 'dictionary' ? prefix + 'info' : prefix + 'default';
        }
    }]);

    return _class;
}();

exports.default = _class;