'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calcResults = require('./calc-results.pug');

var _calcResults2 = _interopRequireDefault(_calcResults);

var _calcResults3 = require('./calc-results.controller');

var _calcResults4 = _interopRequireDefault(_calcResults3);

require('./calc-results.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calcResults2.default)({}),
    controller: _calcResults4.default,
    name: 'nlc-calc-results',
    bindings: {
        inputs: '<',
        data: '<',
        results: '<',
        controller: '=?',
        request: '<'
    },
    bindToController: true
};