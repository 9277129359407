'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _register = require('./register.state');

var _register2 = _interopRequireDefault(_register);

var _register3 = require('./register.component');

var _register4 = _interopRequireDefault(_register3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('authentication.register', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_register2.default.name, _register2.default);
}).component(_register4.default.name.camelCase(), _register4.default);