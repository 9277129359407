'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _transactions = require('./transactions.pug');

var _transactions2 = _interopRequireDefault(_transactions);

var _transactions3 = require('./transactions.controller');

var _transactions4 = _interopRequireDefault(_transactions3);

require('./transactions.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _transactions2.default)({}),
    controller: _transactions4.default,
    name: 'nlc-transactions',
    bindings: {
        controller: '=',
        order: '<',
        invoice: '<'
    },
    bindToController: true
};