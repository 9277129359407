'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calculations = require('./calculations.pug');

var _calculations2 = _interopRequireDefault(_calculations);

var _calculations3 = require('./calculations.controller');

var _calculations4 = _interopRequireDefault(_calculations3);

require('./calculations.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calculations2.default)({}),
    controller: _calculations4.default,
    name: 'nlc-calculations',
    bindings: {
        controller: '=',
        product_calc: '<'
    },
    bindToController: true
};