'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _confirm = require('./confirm.pug');

var _confirm2 = _interopRequireDefault(_confirm);

var _confirm3 = require('./confirm.controller');

var _confirm4 = _interopRequireDefault(_confirm3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _confirm2.default)({}),
    name: 'confirm-dialog',
    controller: _confirm4.default
};