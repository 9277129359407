'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _source = require('./source.state');

var _source2 = _interopRequireDefault(_source);

var _source3 = require('./source.component');

var _source4 = _interopRequireDefault(_source3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.source', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_source2.default.name, _source2.default);
}).component(_source4.default.name.camelCase(), _source4.default);