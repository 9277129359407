'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _app = require('./app/app');

var _app2 = _interopRequireDefault(_app);

var _authentication = require('./authentication/authentication');

var _authentication2 = _interopRequireDefault(_authentication);

var _password = require('./password/password');

var _password2 = _interopRequireDefault(_password);

var _components = require('./components/components');

var _components2 = _interopRequireDefault(_components);

var _notFound = require('./not-found/not-found');

var _notFound2 = _interopRequireDefault(_notFound);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.common', [_components2.default.name, _notFound2.default.name, _app2.default.name, _authentication2.default.name, _password2.default.name]);