'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

require('angular-toastr');

require('angular-toastr/src/toastr.less');

var _toast = require('./services/toast.service');

var _toast2 = _interopRequireDefault(_toast);

var _translateToast = require('./services/translate.toast.service');

var _translateToast2 = _interopRequireDefault(_translateToast);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('app.modules.toastr', ['toastr']).service('ToastService', _toast2.default).service('TranslateToastService', _translateToast2.default);