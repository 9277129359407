'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _parameter = require('./parameter.pug');

var _parameter2 = _interopRequireDefault(_parameter);

var _parameter3 = require('./parameter.controller');

var _parameter4 = _interopRequireDefault(_parameter3);

require('./parameter.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _parameter2.default)({}),
    controller: _parameter4.default,
    name: 'nlc-parameters-page'
};