'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _forgot = require('./forgot.component');

var _forgot2 = _interopRequireDefault(_forgot);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'password.forgot',
    url: '/forgot/',
    template: '<' + _forgot2.default.name + '/>',
    title: 'FORGOT.TITLE'
};