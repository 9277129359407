'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tenants = require('./tenants.pug');

var _tenants2 = _interopRequireDefault(_tenants);

var _tenants3 = require('./tenants.controller');

var _tenants4 = _interopRequireDefault(_tenants3);

require('./tenants.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _tenants2.default)({}),
    controller: _tenants4.default,
    name: 'nlc-office-tenants',
    bindings: {
        controller: '=',
        office: '<'
    },
    bindToController: true
};