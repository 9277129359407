'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.constants', [])
//application prefixes
.constant('APP_PREFIX', 'app').constant('API_ENDPOINT', process.env.API_ENDPOINT).constant('VAT', process.env.VAT).constant('DEFAULT_STATE', 'app.product').constant('AUTHENTICATION_STATE', 'authentication.login');