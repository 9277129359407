'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _service = require('./service.pug');

var _service2 = _interopRequireDefault(_service);

require('./service.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _service2.default)({}),
    name: 'nlc-services-page'
};