'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _status = require('./status.state');

var _status2 = _interopRequireDefault(_status);

var _status3 = require('./status.component');

var _status4 = _interopRequireDefault(_status3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.status', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_status2.default.name, _status2.default);
}).component(_status4.default.name.camelCase(), _status4.default);