'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _login = require('./login.state');

var _login2 = _interopRequireDefault(_login);

var _login3 = require('./login.component');

var _login4 = _interopRequireDefault(_login3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('authentication.login', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_login2.default.name, _login2.default);
}).component(_login4.default.name.camelCase(), _login4.default);