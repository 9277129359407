'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _orderDelivery = require('./order-delivery.component');

var _orderDelivery2 = _interopRequireDefault(_orderDelivery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'app.order-delivery',
    url: '/order-deliveries/',
    template: '<' + _orderDelivery2.default.name + '/>',
    title: 'ORDER_DELIVERY.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'ORDER_DELIVERY.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.ORDER_DELIVERY',
        priority: 0,
        roles: ['admin', 'manager', 'master', 'viewer'],
        tag: 'sidebar',
        icon: 'fa fa-truck'
    }
};