'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _forgot = require('./forgot.pug');

var _forgot2 = _interopRequireDefault(_forgot);

var _forgot3 = require('./forgot.controller');

var _forgot4 = _interopRequireDefault(_forgot3);

require('./forgot.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _forgot2.default)({}),
    controller: _forgot4.default,
    name: 'forgot'
};