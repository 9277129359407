'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _delivery = require('./delivery.pug');

var _delivery2 = _interopRequireDefault(_delivery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _delivery2.default)({}),
    name: 'nlc-order-delivery-info',
    bindings: {
        item: '='
    },
    bindToController: true
};