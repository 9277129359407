'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(DictionaryService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { DictionaryService: DictionaryService, names: [], dictionaries: [] });
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            this.DictionaryService.data({ limit: 999 }).then(function (data) {
                return data.forEach(function (row) {
                    _this.names[row.code] = row.name;
                    _this.dictionaries[row.code] = row;
                });
            }).then(function () {
                _this.inputs = _lodash2.default.sortBy(_lodash2.default.values(_this.schema[_this.key].inputs), function (input) {
                    return _this.names[input.base] || input.base;
                });
            });
        }
    }]);

    return _class;
}();

exports.default = _class;