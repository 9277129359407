'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.default = function () {
    'ngInject';

    return function (bytes) {
        var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

        //if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
    };
};