'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _block = require('./block.state');

var _block2 = _interopRequireDefault(_block);

var _block3 = require('./block.component');

var _block4 = _interopRequireDefault(_block3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var name = 'product';
exports.default = _angular2.default.module('app.pages.content-block', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_block2.default.name, _block2.default);
}).component(_block4.default.name.camelCase(), _block4.default);