'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _orderDialog = require('./order.dialog.pug');

var _orderDialog2 = _interopRequireDefault(_orderDialog);

var _orderDialog3 = require('./order.dialog.controller');

var _orderDialog4 = _interopRequireDefault(_orderDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _orderDialog2.default)({}),
    controller: _orderDialog4.default,
    resolve: {
        type: function type() {
            return 'order';
        }
    },
    name: 'nlc-transactions-order-dialog'
};