'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'app.order-update',
    url: '/orders/:id/',
    template: '<nlc-order-form request=\'order\' controller=\'form\'/>',
    title: 'ORDERS.UPDATE.TITLE',
    controller: /*@ngInject*/function controller($scope, order) {
        return Object.assign($scope, { order: order });
    },
    resolve: {
        order: /*@ngInject*/function order($stateParams, OrderService) {
            return OrderService.get($stateParams.id);
        }
    },
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'ORDERS.UPDATE.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.ORDERS.UPDATE',
        priority: 0,
        tag: 'sidebar',
        icon: 'fa fa-clone'
    }
};