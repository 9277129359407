'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _deliveryTypes = require('./delivery-types.pug');

var _deliveryTypes2 = _interopRequireDefault(_deliveryTypes);

var _deliveryTypes3 = require('./delivery-types.controller');

var _deliveryTypes4 = _interopRequireDefault(_deliveryTypes3);

require('./delivery-types.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _deliveryTypes2.default)({}),
    controller: _deliveryTypes4.default,
    name: 'nlc-delivery-types',
    bindings: {
        controller: '='
    },
    bindToController: true
};