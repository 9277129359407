'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _menuItem = require('./menu-item.pug');

var _menuItem2 = _interopRequireDefault(_menuItem);

require('./menu-item.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _menuItem2.default)({}),
    name: 'nlc-content-menu-items-page',
    bindings: {
        menu: '<'
    },
    bindToController: true
};