'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _login = require('./login.pug');

var _login2 = _interopRequireDefault(_login);

var _login3 = require('./login.controller');

var _login4 = _interopRequireDefault(_login3);

require('./login.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _login2.default)({}),
    controller: _login4.default,
    name: 'login'
};