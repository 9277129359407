'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _data = require('controllers/data.controller');

var _data2 = _interopRequireDefault(_data);

var _status = require('./dialog/status/status.dialog');

var _status2 = _interopRequireDefault(_status);

var _store = require('pages/components/assets/dialog/store/store.dialog');

var _store2 = _interopRequireDefault(_store);

var _report2 = require('../../../common/dialog/report/report.dialog');

var _report3 = _interopRequireDefault(_report2);

var _update = require('./dialog/update/update.dialog');

var _update2 = _interopRequireDefault(_update);

var _price = require('./dialog/price/price.dialog');

var _price2 = _interopRequireDefault(_price);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DataController) {
    _inherits(_class, _DataController);

    function _class($state, TranslateDialogService, IdentityService, OrderItemService, CacheReportService, CacheDictionaryService, OrderItemPolicy, OrderItemAssetPolicy, OrderItemChoice, ProductService, ValueService) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, OrderItemService));

        Object.assign(_this, {
            $state: $state,
            dialog: TranslateDialogService,
            policy: OrderItemPolicy,
            choice: OrderItemChoice,
            assetPolicy: OrderItemAssetPolicy,
            CacheDictionaryService: CacheDictionaryService,
            CacheReportService: CacheReportService,
            IdentityService: IdentityService,
            ProductService: ProductService,
            ValueService: ValueService
        });
        return _this;
    }

    _createClass(_class, [{
        key: 'ready',
        value: function ready(item) {
            return this.service.update(item.id, {
                delivery_status: 'ready',
                order_id: item.order_id
            }).then(function (updated) {
                return Object.assign(item, updated);
            });
        }
    }, {
        key: 'getDictionaryValue',
        value: function getDictionaryValue(dictionary, request, results, group, found) {
            var _this2 = this;

            var level = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 1;

            if (!request) {
                return;
            }
            var values = [];

            var value = _lodash2.default.get(request, group.id + '.data.' + dictionary.code, undefined) || _lodash2.default.get(results, 'default.results.data.' + dictionary.code, undefined);
            if (_angular2.default.isDefined(value)) {
                if (!_angular2.default.isObject(value)) {
                    values.push(value);
                    found = value;
                } else if (value.id != found) {
                    var component = group.components[dictionary.code];
                    if (!component.inheritable) {
                        var format = '%(name)s';
                        found = value.id;
                        if (value.own && component.flexible && component.flexible_r_format) {
                            format = component.flexible_r_format;
                        } else if (component.r_format) {
                            format = component.r_format;
                        }
                        values.push(sprintf(format, value));
                    }
                }
            }
            return [].concat(values, _toConsumableArray(_lodash2.default.concat.apply(null, _lodash2.default.values(group.groups).map(function (childGroup) {
                return _this2.getDictionaryValue(dictionary, _lodash2.default.get(request, group.id + '.groups', {}), _lodash2.default.get(results, 'groups.' + childGroup.id), childGroup, found, level + 1);
            }))));
        }
    }, {
        key: 'getDictionaryValues',
        value: function getDictionaryValues(dictionary, item) {
            return _lodash2.default.uniq(this.getDictionaryValue(dictionary, item.inputs, item.results, item.data)).join(', ');
        }
    }, {
        key: 'replicate',
        value: function replicate(item) {
            var _this3 = this;

            return this.service.replicate(item.id, item).then(function () {
                return _this3.refresh();
            });
        }
    }, {
        key: 'status',
        value: function status(item) {
            return this.dialog.open(_status2.default, { item: item }).then(function (updated) {
                return Object.assign(item, updated);
            });
        }
    }, {
        key: 'view',
        value: function view(item) {
            item.show = !item.show;
        }
    }, {
        key: 'price',
        value: function price(item) {
            return this.dialog.open(_price2.default, { item: item }).then(function (updated) {
                return Object.assign(item, updated);
            });
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            Object.assign(this.request, {
                withs: ['order', 'delivery_type']
            });
            if (_angular2.default.isDefined(this.order)) {
                this.request.order_id = this.order.id;
            }
            if (_angular2.default.isUndefined(this.request.type)) {
                this.request.type = 'delivery';
            }
            this.controller = this;
            _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), '$onInit', this).call(this);
        }

        /**
         * 
         * @param {*} report 
         * @param {*} order 
         */

    }, {
        key: 'report',
        value: function report(_report, item) {
            var _this4 = this;

            return this.service.report(item.id, item).then(function (data) {
                return _this4.dialog.open(_report3.default, { report: _report, data: data });
            });
        }
    }, {
        key: 'upload',
        value: function upload(item) {
            var _this5 = this;

            return this.dialog.open(_store2.default, { assetable_type: 'order-items', assetable_id: item.id }).then(function () {
                return _this5.refresh();
            });
        }
    }, {
        key: 'update',
        value: function update(item) {
            var _this6 = this;

            return this.dialog.open(_update2.default, { item: item }).then(function () {
                return _this6.refresh();
            });
        }
    }, {
        key: 'destroy',
        value: function destroy(item) {
            var _this7 = this;

            return this.dialog.confirm('COMPONENTS.ORDER-ITEMS.GRID.ACTIONS.DESTROY.TITLE', 'COMPONENTS.ORDER-ITEMS.GRID.ACTIONS.DESTROY.MESSAGE', {}, item /*interpolation params*/).then(function () {
                return _this7.service.destroy(item.id, item);
            }).then(function () {
                return _this7.refresh();
            });
        }
    }]);

    return _class;
}(_data2.default);

exports.default = _class;