'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AppHeaderController = function () {
    function AppHeaderController($translate, $rootScope, $state, localStorageService, TranslateDialogService, AuthService, IdentityService, AUTHENTICATION_STATE, DEFAULT_STATE) {
        'ngInject';

        _classCallCheck(this, AppHeaderController);

        Object.assign(this, { storage: localStorageService, $translate: $translate, $rootScope: $rootScope, $state: $state, TranslateDialogService: TranslateDialogService, AuthService: AuthService, IdentityService: IdentityService, AUTHENTICATION_STATE: AUTHENTICATION_STATE, DEFAULT_STATE: DEFAULT_STATE });
        this.$rootScope.layout.sideBarState = this.storage.get('settings.layout.side_bar_state', false);
    }

    _createClass(AppHeaderController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            this.IdentityService.out.subscribe(function (user) {
                return _this.user = user;
            });
            this.currentLanuageKey = this.$translate.use();
            this.availableLanguageKeys = this.$translate.getAvailableLanguageKeys();
        }
    }, {
        key: 'sideBarToggle',
        value: function sideBarToggle(event) {
            this.storage.set('settings.layout.side_bar_state', this.$rootScope.layout.sideBarState = !this.$rootScope.layout.sideBarState);
        }
    }, {
        key: 'language',
        value: function language(key) {
            var _this2 = this;

            this.currentLanuageKey = key;
            this.$translate.use(key).then(function () {
                return _this2.$translate.refresh();
            });
        }
    }, {
        key: 'logout',
        value: function logout() {
            var _this3 = this;

            this.TranslateDialogService.confirm('APP.HEADER.DIALOGS.LOG_OUT_CONFIRM.TITLE', 'APP.HEADER.DIALOGS.LOG_OUT_CONFIRM.CONTENT').then(function () {
                _this3.AuthService.release();
                _this3.$state.go(_this3.AUTHENTICATION_STATE);
            });
        }
    }]);

    return AppHeaderController;
}();

exports.default = AppHeaderController;