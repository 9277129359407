'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _info = require('./info.pug');

var _info2 = _interopRequireDefault(_info);

var _info3 = require('./info.controller');

var _info4 = _interopRequireDefault(_info3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _info2.default)({}),
    controller: _info4.default,
    name: 'nlc-order-item-info',
    bindings: {
        item: '='
    },
    bindToController: true
};