'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _transactionDialog = require('./transaction.dialog.pug');

var _transactionDialog2 = _interopRequireDefault(_transactionDialog);

var _transactionDialog3 = require('./transaction.dialog.controller');

var _transactionDialog4 = _interopRequireDefault(_transactionDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _transactionDialog2.default)({}),
    controller: _transactionDialog4.default,
    name: 'nlc-order-transaction-dialog'
};