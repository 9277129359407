'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calcRawDialog = require('./calc-raw.dialog.pug');

var _calcRawDialog2 = _interopRequireDefault(_calcRawDialog);

var _calcRawDialog3 = require('./calc-raw.dialog.controller');

var _calcRawDialog4 = _interopRequireDefault(_calcRawDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calcRawDialog2.default)({}),
    controller: _calcRawDialog4.default,
    name: 'nlc-calc-raw-dialog'
};