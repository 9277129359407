'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _reward = require('./reward.state');

var _reward2 = _interopRequireDefault(_reward);

var _reward3 = require('./reward.component');

var _reward4 = _interopRequireDefault(_reward3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.reward', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_reward2.default.name, _reward2.default);
}).component(_reward4.default.name.camelCase(), _reward4.default);