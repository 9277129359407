'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _client = require('./client.pug');

var _client2 = _interopRequireDefault(_client);

var _client3 = require('./client.controller');

var _client4 = _interopRequireDefault(_client3);

require('./client.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _client2.default)({}),
    controller: _client4.default,
    name: 'nlc-clients-page'
};