'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function _class(DEFAULT_STATE) {
    'ngInject';

    _classCallCheck(this, _class);

    Object.assign(this, { DEFAULT_STATE: DEFAULT_STATE });
};

exports.default = _class;