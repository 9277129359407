'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _nlPromise = require('./nl-promise.directive');

var _nlPromise2 = _interopRequireDefault(_nlPromise);

var _nlPromiseClick = require('./nl-promise-click.directive');

var _nlPromiseClick2 = _interopRequireDefault(_nlPromiseClick);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.directives', []).directive('nlPromise', /*@ngInject*/function () {
    return new _nlPromise2.default();
}).directive('nlPromiseClick', /*@ngInject*/function () {
    return new _nlPromiseClick2.default();
});