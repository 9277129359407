'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _dialog = require('modules/dialog/controllers/dialog.controller');

var _dialog2 = _interopRequireDefault(_dialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_DialogController) {
    _inherits(_class, _DialogController);

    function _class($scope, ProductCalcService, calc) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, $scope));

        Object.assign(_this, {
            ProductCalcService: ProductCalcService,
            request: {
                id: calc.id,
                template: calc.template,
                product_id: calc.product_id
            }
        });
        _this.pug = _this.getGroupPug(calc.data, '');
        return _this;
    }

    _createClass(_class, [{
        key: 'copy',
        value: function copy() {
            this.request.template = this.pug;
        }
    }, {
        key: 'getGroupPug',
        value: function getGroupPug(group) {
            var _this2 = this;

            var tab = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
            var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

            var components = _lodash2.default.sortBy(_lodash2.default.values(group.components), ['index', 'name'], ['asc', 'asc']);
            // .filter(component => !component.inheritable)
            var help = function help(message) {
                return index == 1 ? message : '';
            };
            var pug = tab + '<!-- ' + group.name + ' -->\r\n' + (tab + '<Group id=\'' + group.id + '\'>\r\n') + help(tab + '\t<!-- <template v-slot:confirm=\'confirm\'> -->\r\n') + help(tab + '\t\t<!-- <span> Group name is: {{ confirm.name }}-->\r\n') + help(tab + '\t\t<!-- <span> Group confirm is: {{ confirm.confirm }}-->\r\n') + help(tab + '\t\t<!-- <Confirm>{{ confirm.name }}</Confirm> -->\r\n') + help(tab + '\t<!-- </template> -->\r\n\r\n') + (tab + '\t<template v-slot:default=\'group\'>\r\n') + help(tab + '\t\t<!-- <span> Group result price is: {{ group.results.default }}-->\r\n') + help(tab + '\t\t<!-- <span> Group result any data is: {{ group.results.data.flat_count }}-->\r\n') + help(tab + '\t\t<!-- <span> Group name is: {{ group.name }}-->\r\n') + help(tab + '\t\t<!-- <span> Group input/request data: {{ group.data }}-->\r\n') + help(tab + '\t\t<!-- <span> Group input/request specific data: {{ group.data.mashprint.name }}-->\r\n') + help(tab + '\t\t<!-- <Field code=\'code\' type=\'number\' :min=\'1\', :max=\'group.data.count.t_count\'/> -->\r\n') + help(tab + '\t\t<!-- <Field code=\'code\' type=\'buttons\'> -->\r\n') + help(tab + '\t\t\t<!-- <Caption/> -->\r\n') + help(tab + '\t\t\t<!-- <Control type=\'buttons\'/> -->\r\n') + help(tab + '\t\t\t<!-- <Control type=\'select\'/> -->\r\n') + help(tab + '\t\t\t<!-- <Control type=\'radio-group\'/> -->\r\n') + help(tab + '\t\t\t<!-- <Control type=\'number\', :min=\'1\', :max=\'group.data.count.t_count\'/> -->\r\n') + help(tab + '\t\t\t<!-- <Control type=\'text\'/> -->\r\n') + help(tab + '\t\t\t<!-- <Control type=\'select\'/> -->\r\n') + help(tab + '\t\t<!-- </Field> -->\r\n\r\n')
            // fields
            + components.map(function (cmp) {
                return tab + '\t\t<Field code=\'' + cmp.base + '\' type=\'buttons\'/>\r\n';
            }).join('')
            // groups
            + _lodash2.default.values(group.groups).map(function (childGroup) {
                return _this2.getGroupPug(childGroup, tab + "\t\t", index + 1);
            }).join('') + (tab + '\t</template>\r\n') + (tab + '</Group>\r\n');
            return pug;
        }
    }, {
        key: 'submit',
        value: function submit() {
            var _this3 = this;

            this.promise = this.ProductCalcService.update(this.request.id, this.request).then(function (calc) {
                return _this3.resolve(calc);
            });
        }
    }]);

    return _class;
}(_dialog2.default);

exports.default = _class;