'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _updateDialog = require('./update.dialog.pug');

var _updateDialog2 = _interopRequireDefault(_updateDialog);

var _updateDialog3 = require('./update.dialog.controller');

var _updateDialog4 = _interopRequireDefault(_updateDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _updateDialog2.default)({}),
    controller: _updateDialog4.default,
    name: 'nlc-reward-update-dialog'
};