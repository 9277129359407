'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _actions = require('./actions.pug');

var _actions2 = _interopRequireDefault(_actions);

var _actions3 = require('./actions.controller');

var _actions4 = _interopRequireDefault(_actions3);

require('./actions.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _actions2.default)({}),
    controller: _actions4.default,
    name: 'nlc-actions',
    bindings: {
        controller: '='
    },
    bindToControler: true
};