'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _block = require('./block.component');

var _block2 = _interopRequireDefault(_block);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * State
 */
exports.default = {
    name: 'app.conent-block',
    url: '/content/blocks/',
    template: '<' + _block2.default.name + '/>',
    title: 'CONTENT-BLOCK.TITLE',
    /**
     * Setup breadcrumbs
     */
    ncyBreadcrumb: {
        label: 'CONTENT-BLOCK.BREADCRUMB' // angular-breadcrumb's configuration
    },
    /**
     * Setup menu
     */
    menu: {
        content: 'COMMON.MENU.CONTENT-BLOCK',
        priority: 100,
        roles: ['admin'],
        tag: 'sidebar',
        icon: 'fa fa-image'
    }
};