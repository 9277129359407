'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _product = require('./product.pug');

var _product2 = _interopRequireDefault(_product);

require('./product.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _product2.default)({}),
    name: 'nlc-products-page'
};