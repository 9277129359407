'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calcRaw = require('./calc-raw.pug');

var _calcRaw2 = _interopRequireDefault(_calcRaw);

var _calcRaw3 = require('./calc-raw.controller');

var _calcRaw4 = _interopRequireDefault(_calcRaw3);

require('./calc-raw.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calcRaw2.default)({}),
    controller: _calcRaw4.default,
    name: 'nlc-calc-raw',
    bindings: {
        id: '<',
        schema: '<',
        type: '<',
        request: '=',
        controller: '=',
        results: '=',
        loading: '='
    },
    bindToController: true
};