'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _payment_statusDialog = require('./payment_status.dialog.pug');

var _payment_statusDialog2 = _interopRequireDefault(_payment_statusDialog);

var _payment_statusDialog3 = require('./payment_status.dialog.controller');

var _payment_statusDialog4 = _interopRequireDefault(_payment_statusDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _payment_statusDialog2.default)({}),
    controller: _payment_statusDialog4.default,
    name: 'nlc-order-payment_status-dialog'
};