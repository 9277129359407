'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _menuItem = require('./menu-item.state');

var _menuItem2 = _interopRequireDefault(_menuItem);

var _menuItem3 = require('./menu-item.component');

var _menuItem4 = _interopRequireDefault(_menuItem3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.content-menu-item', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_menuItem2.default.name, _menuItem2.default);
}).component(_menuItem4.default.name.camelCase(), _menuItem4.default);