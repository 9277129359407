'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _date = require('./date.filter');

var _date2 = _interopRequireDefault(_date);

var _datetime = require('./datetime.filter');

var _datetime2 = _interopRequireDefault(_datetime);

var _filesize = require('./filesize.filter');

var _filesize2 = _interopRequireDefault(_filesize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.filters', []).filter('nlcdate', _date2.default).filter('nlcdatetime', _datetime2.default).filter('nlcfilesize', _filesize2.default);