'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(IdentityService) {
        _classCallCheck(this, _class);

        Object.assign(this, { IdentityService: IdentityService });
    }

    _createClass(_class, [{
        key: 'is_new',
        value: function is_new(_ref) {
            var _is_new = _ref.is_new;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            switch (!!_is_new) {
                case true:
                    {
                        return prefix + 'success';
                    }
                default:
                    {
                        return prefix + 'default';
                    }
            }
        }
    }, {
        key: 'shared',
        value: function shared(_ref2) {
            var sale_id = _ref2.sale_id,
                user_id = _ref2.user_id;
            var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'label-';

            var user = this.IdentityService.user;
            if (user.sale_id == sale_id || user.id == user_id) {
                return prefix + 'danger';
            } else {
                return prefix + 'warning';
            }
        }
    }]);

    return _class;
}();

exports.default = _class;