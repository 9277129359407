'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dialog = require('./services/dialog.service');

var _dialog2 = _interopRequireDefault(_dialog);

var _translateDialog = require('./services/translate.dialog.service');

var _translateDialog2 = _interopRequireDefault(_translateDialog);

var _dialog3 = require('./controllers/dialog.controller');

var _dialog4 = _interopRequireDefault(_dialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = angular.module('app.modules.dialog', []).controller('DialogController', _dialog4.default).service('DialogService', _dialog2.default).service('TranslateDialogService', _translateDialog2.default);