'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _event = require('./event.service');

var _event2 = _interopRequireDefault(_event);

var _identity = require('./identity.service');

var _identity2 = _interopRequireDefault(_identity);

var _auth = require('./auth.service');

var _auth2 = _interopRequireDefault(_auth);

var _boot = require('./boot.service');

var _boot2 = _interopRequireDefault(_boot);

var _geo = require('./geo.service');

var _geo2 = _interopRequireDefault(_geo);

var _menu = require('./menu.service');

var _menu2 = _interopRequireDefault(_menu);

var _layout = require('./layout.service');

var _layout2 = _interopRequireDefault(_layout);

var _api = require('./api/api');

var _api2 = _interopRequireDefault(_api);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.services', [_api2.default.name]).service('EventService', _event2.default).service('IdentityService', _identity2.default).service('AuthService', _auth2.default).service('BootService', _boot2.default).service('GeoService', _geo2.default).service('MenuService', _menu2.default).service('LayoutService', _layout2.default);