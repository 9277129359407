'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($scope) {
        'ngInject';

        var _this = this;

        _classCallCheck(this, _class);

        this.types = [
        // {id: 'cmyk', name: 'CMYK', hasCmyk: true, hasPantones: false, cmykDisabled: true},
        // {id: 'cmyk+pantone', name: 'CMYK + Pantone', hasCmyk: true, hasPantones: true, cmykDisabled: true},
        { id: 'triada+pantone', name: 'Триада + Pantone', hasCmyk: {
                get: function get() {
                    return _this.cmyk.cyan || _this.cmyk.magenta || _this.cmyk.yellow || _this.cmyk.key;
                }
            }, hasPantones: {
                get: function get() {
                    return _this.pantones.length !== 0;
                }
            }, cmykDisabled: false }];
        $scope.$watch('$ctrl.request.cmyk', function () {
            _this.request.cmykColorsCount = 0;
            if (!_this.request.cmyk) {
                return;
            }
            if (_this.request.cmyk.cyan) {
                _this.request.cmykColorsCount += 1;
            }
            if (_this.request.cmyk.magenta) {
                _this.request.cmykColorsCount += 1;
            }
            if (_this.request.cmyk.yellow) {
                _this.request.cmykColorsCount += 1;
            }
            if (_this.request.cmyk.key) {
                _this.request.cmykColorsCount += 1;
            }
        }, true);
        $scope.$watch('$ctrl.request.pantones', function () {
            _this.request.pantonesCount = _lodash2.default.values(_this.request.pantones).length;
        }, true);
        $scope.$watch('$ctrl.request.type', function () {
            if (_this.request) {
                switch (_this.request.type.id) {
                    case 'cmyk+pantone':
                        {
                            _this.request.cmyk = {
                                cyan: true,
                                magenta: true,
                                yellow: true,
                                key: true
                            };
                        }
                    case 'cmyk':
                        {
                            _this.request.pantones = [];
                            break;
                        }
                    case 'pantone':
                        {
                            _this.request.cmyk = {
                                cyan: false,
                                magenta: false,
                                yellow: false,
                                key: false
                            };
                            break;
                        }
                }
            }
        }, true);
    }

    _createClass(_class, [{
        key: '$onInit',
        value: function $onInit() {
            var cmyk = {
                cyan: true,
                magenta: true,
                yellow: true,
                key: true
            };
            var defaultRequest = { cmyk: cmyk, hasLak: false, pantones: [], pantonesCount: 0, cmykColorsCount: 0, type: this.types[0] };
            this.request = _lodash2.default.merge(defaultRequest, this.request);
            this.request.cmyk = _lodash2.default.merge(this.request.cmyk, cmyk);
        }
    }, {
        key: 'addPantone',
        value: function addPantone() {
            this.request.pantones = _lodash2.default.values(this.request.pantones);
            if (this.request.pantones.length >= 4) {
                return;
            }
            this.request.pantones.push({ value: '' });
        }
    }, {
        key: 'removePantone',
        value: function removePantone(index) {
            this.request.pantones.splice(index, 1);
        }
    }]);

    return _class;
}();

exports.default = _class;