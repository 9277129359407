'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _valueAssociations = require('./value-associations.pug');

var _valueAssociations2 = _interopRequireDefault(_valueAssociations);

var _valueAssociations3 = require('./value-associations.controller');

var _valueAssociations4 = _interopRequireDefault(_valueAssociations3);

require('./value-associations.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _valueAssociations2.default)({}),
    controller: _valueAssociations4.default,
    name: 'nlc-value-associations',
    bindings: {
        controller: '=',
        value: '<'
    },
    bindToController: true
};