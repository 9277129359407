'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _value = require('./value.pug');

var _value2 = _interopRequireDefault(_value);

require('./value.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _value2.default)({}),
    name: 'nlc-values-page',
    bindings: {
        dictionary: '<'
    },
    bindToController: true
};