'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _deliveryType = require('./delivery-type.state');

var _deliveryType2 = _interopRequireDefault(_deliveryType);

var _deliveryType3 = require('./delivery-type.component');

var _deliveryType4 = _interopRequireDefault(_deliveryType3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _angular2.default.module('app.pages.delivery-type', []).config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state(_deliveryType2.default.name, _deliveryType2.default);
}).component(_deliveryType4.default.name.camelCase(), _deliveryType4.default);