'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class(CacheDictionaryService, CacheValueService, CacheProductService, CacheProductCalcService, CacheStatusService) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { CacheDictionaryService: CacheDictionaryService, CacheValueService: CacheValueService, CacheProductService: CacheProductService, CacheProductCalcService: CacheProductCalcService, CacheStatusService: CacheStatusService, filters: [] });
    }

    _createClass(_class, [{
        key: 'addFilter',
        value: function addFilter() {
            this.filters.push({
                dictionary_id: null,
                dictionary_value_id: null,
                operand: 'and'
            });
        }
    }, {
        key: 'removeFilter',
        value: function removeFilter(index) {
            this.filters.splice(index, 1);
            this.onFilterChange();
        }
    }, {
        key: 'onFilterChange',
        value: function onFilterChange() {
            this.items.request.value_ids = this.filters;
            this.items.refresh();
        }
    }]);

    return _class;
}();

exports.default = _class;