'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calcConstructor = require('./calc-constructor.pug');

var _calcConstructor2 = _interopRequireDefault(_calcConstructor);

var _calcConstructor3 = require('./calc-constructor.controller');

var _calcConstructor4 = _interopRequireDefault(_calcConstructor3);

require('./calc-constructor.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calcConstructor2.default)({}),
    controller: _calcConstructor4.default,
    name: 'nlc-calc-constructor',
    bindings: {
        data: '='
    },
    bindToController: true
};