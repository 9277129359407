'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _dataArrayValues = require('./data.array.values.pug');

var _dataArrayValues2 = _interopRequireDefault(_dataArrayValues);

var _dataArrayValues3 = require('./data.array.values.controller');

var _dataArrayValues4 = _interopRequireDefault(_dataArrayValues3);

require('./data.array.values.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _dataArrayValues2.default)({}),
    controller: _dataArrayValues4.default,
    name: 'nlc-data-array-values',
    bindings: {
        controller: '=',
        request: '=?',
        dArray: '<'
    },
    bindToController: true
};