'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _statistic = require('./statistic.pug');

var _statistic2 = _interopRequireDefault(_statistic);

var _statistic3 = require('./statistic.controller');

var _statistic4 = _interopRequireDefault(_statistic3);

require('./statistic.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _statistic2.default)({}),
    controller: _statistic4.default,
    name: 'nlc-statistic',
    bindings: {
        controller: '='
    },
    bindToController: true
};