'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _authentication = require('./authentication.pug');

var _authentication2 = _interopRequireDefault(_authentication);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    abstract: true,
    template: (0, _authentication2.default)({}),
    name: 'authentication',
    layout: {
        class: 'background-white'
    }
};