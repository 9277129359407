'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function () {
    function _class($rootScope) {
        'ngInject';

        _classCallCheck(this, _class);

        Object.assign(this, { $rootScope: $rootScope });
    }

    _createClass(_class, [{
        key: 'init',
        value: function init() {
            var _this = this;

            //init template settings
            this.$rootScope.layout = {
                contentWhite: false,
                bodySolid: false,
                sideBarState: false
            };
            return this.$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                if (angular.isDefined(toState.title)) {
                    _this.$rootScope.title = toState.title;
                }
                //let find parent layout settings
                var state = toState;
                while (angular.isUndefined(state.layout) && angular.isDefined(state.root)) {
                    state = state.root;
                }_this.$rootScope.layout = angular.merge(_this.$rootScope.layout, state.layout);
            });
        }
    }]);

    return _class;
}();

exports.default = _class;