'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _calcDebugDialog = require('./calc-debug.dialog.pug');

var _calcDebugDialog2 = _interopRequireDefault(_calcDebugDialog);

var _calcDebugDialog3 = require('./calc-debug.dialog.controller');

var _calcDebugDialog4 = _interopRequireDefault(_calcDebugDialog3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    template: (0, _calcDebugDialog2.default)({}),
    controller: _calcDebugDialog4.default,
    name: 'nlc-calc-debug-dialog'
};