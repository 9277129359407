'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _dialog = require('modules/dialog/controllers/dialog.controller');

var _dialog2 = _interopRequireDefault(_dialog);

require('printthis');

var _jquery = require('jquery');

var _jquery2 = _interopRequireDefault(_jquery);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _handlebars = require('handlebars');

var _handlebars2 = _interopRequireDefault(_handlebars);

var _math = require('handlebars-helpers/lib/math');

var _math2 = _interopRequireDefault(_math);

var _array = require('handlebars-helpers/lib/array');

var _array2 = _interopRequireDefault(_array);

var _code = require('handlebars-helpers/lib/code');

var _code2 = _interopRequireDefault(_code);

var _collection = require('handlebars-helpers/lib/collection');

var _collection2 = _interopRequireDefault(_collection);

var _comparison = require('handlebars-helpers/lib/comparison');

var _comparison2 = _interopRequireDefault(_comparison);

var _html = require('handlebars-helpers/lib/html');

var _html2 = _interopRequireDefault(_html);

var _inflection = require('handlebars-helpers/lib/inflection');

var _inflection2 = _interopRequireDefault(_inflection);

var _match = require('handlebars-helpers/lib/match');

var _match2 = _interopRequireDefault(_match);

var _misc = require('handlebars-helpers/lib/misc');

var _misc2 = _interopRequireDefault(_misc);

var _number = require('handlebars-helpers/lib/number');

var _number2 = _interopRequireDefault(_number);

var _object = require('handlebars-helpers/lib/object');

var _object2 = _interopRequireDefault(_object);

var _path = require('handlebars-helpers/lib/path');

var _path2 = _interopRequireDefault(_path);

var _regex = require('handlebars-helpers/lib/regex');

var _regex2 = _interopRequireDefault(_regex);

var _string = require('handlebars-helpers/lib/string');

var _string2 = _interopRequireDefault(_string);

var _url = require('handlebars-helpers/lib/url');

var _url2 = _interopRequireDefault(_url);

var _utils = require('handlebars-helpers/lib/utils');

var _utils2 = _interopRequireDefault(_utils);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _rubles = require('rubles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

_handlebars2.default.registerHelper('date', function (date, format) {
    if (date == null) {
        return '';
    }
    return new _handlebars2.default.SafeString((0, _moment2.default)(_handlebars2.default.Utils.escapeExpression(date)).format(_handlebars2.default.Utils.escapeExpression(format)));
});
_handlebars2.default.registerHelper('hasInArray', function (data) {
    for (var _len = arguments.length, paths = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        paths[_key - 1] = arguments[_key];
    }

    var options = paths.pop();
    var fnTrue = options.fn;
    var fnFalse = options.inverse;
    return _lodash2.default.values(data).some(function (item) {
        return paths.some(function (path) {
            return _lodash2.default.has(item, path);
        });
    }) ? fnTrue(undefined) : fnFalse(undefined);
});
_handlebars2.default.registerHelper('every', function (context) {
    for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
    }

    var options = args.pop();
    return _lodash2.default.keys(context).filter(function (key) {
        return args.includes(key);
    }).map(function (key) {
        return options.fn(context[key]);
    }).join('');
});
_handlebars2.default.registerHelper('repeat', function (n, block) {
    var accum = '';
    for (var i = 0; i < n; ++i) {
        block.data.index = i;
        block.data.first = i === 0;
        block.data.last = i === n - 1;
        accum += block.fn(this);
    }
    return accum;
});
var isNumber = function isNumber(value) {
    return typeof value == 'number';
};
var isArray = function isArray(value) {
    return typeof value == 'array';
};
var deepPick = function deepPick(object, path, def) {

    if (_lodash2.default.has(object, path)) {
        return _lodash2.default.get(object, path, def);
    }
    for (var id in object) {
        if (!_lodash2.default.isObject(object[id])) continue;
        var childGroup = object[id];
        var value = deepPick(childGroup, path, false);
        if (value !== false) {
            return value;
        }
    }
    return def;
};
_handlebars2.default.registerHelper('dpick', deepPick);
_handlebars2.default.registerHelper('cost', function (total_price, group, path, def) {
    if (!isNumber(total_price)) {
        return '';
    }
    var value = deepPick(group, path, def);
    if (!isNumber(value)) {
        return '';
    }
    return (Number(total_price) / Number(value)).toFixed(2);
});
_handlebars2.default.registerHelper('rubles', function (value) {
    if (!isNumber(value)) {
        return '';
    }
    return (0, _rubles.rubles)(value);
});
_handlebars2.default.registerHelper('assign', function (name, value, options) {
    options.data.root[name] = value;
});
_handlebars2.default.registerHelper('inc', function (name, value, options) {
    options.data.root[name] += value;
});
_handlebars2.default.registerHelper('length', function (array) {
    if (!isArray(array)) {
        return '';
    }
    return array.length;
});
[_math2.default, _array2.default, _code2.default, _collection2.default, _comparison2.default, _html2.default, _inflection2.default, _match2.default, _misc2.default, _object2.default, _path2.default, _regex2.default, _string2.default, _url2.default, _utils2.default, _number2.default].forEach(function (helpers) {
    return _lodash2.default.each(helpers, function (helperDelegate, key) {
        return _handlebars2.default.registerHelper(key, helperDelegate);
    });
});

var _class = function (_DialogController) {
    _inherits(_class, _DialogController);

    function _class($scope, $sce, report, data) {
        'ngInject';

        _classCallCheck(this, _class);

        var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this, $scope));

        try {
            Object.assign(_this, { report: report, data: data, template: _handlebars2.default.compile(report.template)(data) });
            _this.html = $sce.trustAsHtml(_this.template);
        } catch (e) {
            console.error("error", e);
        }
        return _this;
    }

    _createClass(_class, [{
        key: 'submit',
        value: function submit() {
            return this.resolve((0, _jquery2.default)('#print').printThis());
        }
    }]);

    return _class;
}(_dialog2.default);

exports.default = _class;